import { defineStore } from "pinia";
import { ref } from "vue";

export const useUIProgrammesStore = defineStore("uiProgrammes", () => {
  const programmeForDisplay = ref<number | null>(null);
  const openProgrammeSidebar = (programmeId: number) => {
    programmeForDisplay.value = programmeId;
  };
  const closeProgrammeSidebar = () => {
    programmeForDisplay.value = null;
  };

  return {
    programmeForDisplay,
    openProgrammeSidebar,
    closeProgrammeSidebar
  };
});
