<template>
  <b-modal
    :aria-modal="true"
    :has-modal-card="true"
    :model-value="uiFilesStore.isRenameAttachmentPopupOpen"
    :trap-focus="true"
    aria-role="dialog"
    @cancel="uiFilesStore.closeRenameAttachmentModal"
  >
    <div class="rename-file modal-card" data-testing="rename-file-card">
      <div class="modal-card-header">
        <h3> Rename attachment </h3>
      </div>
      <form
        id="rename-attachment"
        class="modal-card-content"
        @submit.prevent="rename"
      >
        <label class="margin-b-standard">
          Enter a new attachment name
          <input
            v-model.trim="newName"
            :disabled="filesStore.areFilesUpdating"
            data-testing="rename-file-input"
            type="text"
          />
        </label>
      </form>
      <div class="modal-card-footer">
        <button
          :disabled="!isSubmitBtnEnabled"
          class="btn-1 full-width"
          data-testing="rename-file-btn"
          form="rename-attachment"
          type="submit"
        >
          Rename
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script lang="ts" setup>
import { computed, onMounted, PropType, ref, watch } from "vue";
import { LibraryFile } from "@/models/files.model";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFileManager } from "@/composables/files-manager";
import { useFilesStore } from "@/stores/files.store";

const uiFilesStore = useUIFilesStore();
const filesStore = useFilesStore();
const { renameAttachment } = useFileManager();

const props = defineProps({
  file: {
    type: Object as PropType<LibraryFile>,
    required: true
  }
});

const newName = ref<string>("");
onMounted(() => {
  if (props.file) {
    newName.value = props.file.name;
  }
});
watch(
  () => props.file,
  newValue => {
    if (newValue) {
      newName.value = newValue.name;
    }
  }
);
const isSubmitBtnEnabled = computed<boolean>(() => {
  return (
    (!!props.file &&
      newName.value !== props.file.name &&
      newName.value !== "") ||
    filesStore.areFilesUpdating
  );
});
const rename = () => {
  renameAttachment(
    {
      id: props.file.id,
      name: newName.value
    },
    props.file
  );
  uiFilesStore.closeRenameAttachmentModal();
};
</script>
<style lang="scss" scoped>
.rename-file {
  width: auto;
}
form {
  min-width: 23rem;
}
</style>
