export default {
  setCookie: setCookie,
  getCookie: getCookie,
  deleteCookie: deleteCookie
};

function setCookie(cname: string, cvalue: string, exmins?: number): void {
  const d = new Date();
  if (!exmins) {
    // cookie valid for 1 year if no other value set
    exmins = 60 * 24 * 365;
  }
  d.setTime(d.getTime() + exmins * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname: string): string {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1);
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
  }
  return "";
}

function deleteCookie(name: string): void {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
