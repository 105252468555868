<template>
  <div
    class="modal-card"
    v-if="uiContentTemplatesStore.contentTemplateForAddingMetadata"
  >
    <div class="modal-card-header">
      <h3>{{ modalTitle }}</h3>
    </div>
    <div class="modal-card-content">
      <div class="margin-b-standard metadata-type">
        <p class="pad-0-bottom">Type:</p>
        <select
          v-model="type"
          :disabled="
            uiContentTemplatesStore.contentTemplateForAddingMetadata.type
          "
          @change="toggleOptions()"
        >
          <option
            v-for="type in METADATA.TYPES"
            :key="type.id"
            :value="type.name"
          >
            {{ type.title }}
          </option>
        </select>
      </div>

      <div class="margin-b-standard metadata-name">
        <p class="pad-0-bottom">Name:</p>
        <input
          v-model.trim="name"
          type="text"
          :class="{ warning: nameError }"
          @input="removeNameError()"
        />
        <ErrorMessage v-if="nameError" :message="nameError" />
      </div>

      <div
        v-if="uiContentTemplatesStore.contentTemplateForAddingMetadata.isXapi"
        class="margin-b-standard metadata-name-url"
      >
        <p class="pad-0-bottom">URL Friendly Name:</p>
        <input
          v-model="friendlyURL"
          type="text"
          :class="{ warning: friendlyNameError }"
        />
        <ErrorMessage v-if="friendlyNameError" :message="friendlyNameError" />
      </div>

      <div v-if="areOptionsVisible" class="margin-b-standard metadata-options">
        <p class="pad-0-bottom">Option:</p>
        <input
          v-model="option"
          type="text"
          :class="{ warning: optionError }"
          @input="removeOptionError()"
        />
        <ErrorMessage v-if="optionError" :message="optionError" />
        <button class="btn-3 margin-b-standard" @click="createOption()">
          <SVGIcon :path="mdiPlus" />
          Add option
        </button>

        <ul class="pad-0-left" v-if="options.length > 0">
          <li
            v-for="option in options"
            :key="option.id"
            :class="['value', 'margin-b-half', { hidden: option.beingDeleted }]"
            ref="existing-option-item"
          >
            <input
              type="text"
              class="value-input"
              v-model="option.display_name"
              :disabled="!option.editable"
              ref="existing-option-input"
              @input="removeOptionsError()"
            />
            <button
              v-if="!option.editable"
              class="btn-3 margin-r-half"
              ref="existing-option-edit-btn"
              @click="startEditing(option)"
            >
              <SVGIcon :path="mdiPencil" />
            </button>
            <button
              v-if="option.editable"
              class="btn-3 margin-r-half"
              ref="existing-option-cancel-editing-btn"
              @click="cancelEditing(option)"
            >
              <SVGIcon :path="mdiClose" />
            </button>
            <button class="btn-3" @click="removeOption(option)">
              <SVGIcon :path="mdiDelete" />
            </button>
          </li>
        </ul>
        <ErrorMessage v-if="optionsError" :message="optionsError" />
      </div>
    </div>

    <div class="modal-card-footer">
      <button
        :disabled="isDataSubmitting"
        class="btn-2"
        type="button"
        @click="uiContentTemplatesStore.closeAddMetadataModal()"
      >
        Cancel
      </button>
      <button
        :disabled="isDataSubmitting"
        class="btn-1"
        type="button"
        @click="
          uiContentTemplatesStore.contentTemplateForAddingMetadata.modalType ===
          'add'
            ? createMetadata()
            : editMetadata()
        "
      >
        Save
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import { sanitizeFriendlyURL } from "@/utils/utils";
import { v4 as uuidv4 } from "uuid";
import { MetadataAllowedValues } from "@/models/content-templates.model";
import { AxiosResponse } from "axios";
import { DefinitionToCreateResponse } from "@/models/api/metadata.model";
import { mdiDelete, mdiClose, mdiPencil, mdiPlus } from "@mdi/js";
import { useUIContentTemplatesStore } from "@/stores/ui-content-template.store";
import { ContentTemplateForEditingMetadata } from "@/models/store/ui-state/content-template-for-adding-metadata.model";
import { useAssetsMetadataStore } from "@/stores/assets-metadata.store";
import { useMetadataStore } from "@/stores/metadata.store";
import { useRoute } from "vue-router";
import { METADATA } from "@/constants/template.constants";
import ErrorMessage from "@/components/ErrorMessage.vue";

const TEXT_FREE = "Text Free";

interface Option {
  beingDeleted: boolean;
  created_by?: number;
  created_on?: number;
  display_name: string;
  display_order: number;
  editable: boolean;
  id: number | string;
  isNew: boolean;
  metadata_definition_id?: number;
  oldValue?: string;
  updated_by?: number;
  updated_on?: number;
  oldDisplayName: string;
}

const uiContentTemplatesStore = useUIContentTemplatesStore();
const metadataStore = useMetadataStore();
const assetsMetadataStore = useAssetsMetadataStore();
const route = useRoute();

const isDataSubmitting = ref<boolean>(false);

const name = ref<string>("");
const nameError = ref<string>("");
const removeNameError = (): void => {
  nameError.value = "";
};

const friendlyURL = ref<string>("");
const friendlyNameError = ref<string>("");

const areOptionsVisible = ref<boolean>(false);
const option = ref<string>("");
const optionError = ref<string>("");
const removeOptionError = (): void => {
  optionError.value = "";
};
const optionsError = ref<string>("");
const removeOptionsError = (): void => {
  optionsError.value = "";
};
const options = ref<Option[]>([]);
const toggleOptions = (): void => {
  type.value !== TEXT_FREE
    ? (areOptionsVisible.value = true)
    : (areOptionsVisible.value = false);
};
const setOptions = (): void => {
  const template =
    uiContentTemplatesStore.contentTemplateForAddingMetadata as ContentTemplateForEditingMetadata;
  if (template.allowedValues && template.allowedValues.length > 0) {
    options.value = template.allowedValues.map(
      (item: MetadataAllowedValues) => {
        return {
          ...item,
          isNew: false,
          editable: false,
          beingDeleted: false,
          oldDisplayName: item.display_name
        };
      }
    );
  }
};
const getBeingDeletedOptions = (): Option[] => {
  return options.value.filter((option: Option): boolean => {
    return option.beingDeleted;
  });
};
const getEditableOptions = (): Option[] => {
  return options.value.filter((option: Option): boolean => {
    return option.editable;
  });
};
const isOptionExisting = (): boolean => {
  if (options.value.length > 0) {
    return options.value.some((opt: Option): boolean => {
      return opt.display_name === option.value;
    });
  }
  return false;
};
const checkDuplicateOptions = (): boolean => {
  const allNames = options.value
    .filter((opt: Option): boolean => {
      return !opt.beingDeleted;
    })
    .map((option: Option): string => {
      return option.display_name.toLowerCase();
    });
  const hasDuplicates = allNames.some((item: string, index: number) => {
    return allNames.lastIndexOf(item) !== index;
  });

  if (hasDuplicates) {
    optionsError.value = "You have duplicate options";
  } else {
    removeOptionsError();
  }
  return hasDuplicates;
};
const checkOptionsCount = (): boolean => {
  const isEnoughOptions =
    type.value !== TEXT_FREE &&
    options.value.length - getBeingDeletedOptions().length < 2;
  if (isEnoughOptions) {
    optionsError.value = "Please add 2 or more options";
  }
  return isEnoughOptions;
};
const startEditing = (option: Option): void => {
  option.editable = true;
};
const cancelEditing = (option: Option): void => {
  option.editable = false;
  option.display_name = option.oldDisplayName;
  removeOptionsError();
};
const createOption = (): void => {
  if (!option.value) {
    optionError.value = "Option cannot be empty";
    return;
  }
  if (isOptionExisting()) {
    optionError.value = "This option already exists";
    return;
  }
  optionsError.value = "";

  options.value.push({
    beingDeleted: false,
    display_name: option.value,
    display_order: options.value.length + 1,
    editable: false,
    id: uuidv4(),
    isNew: true,
    oldDisplayName: option.value
  });
  option.value = "";
};
const getNewOptions = (): Option[] => {
  return options.value.filter((option: Option): boolean => {
    return option.isNew;
  });
};
const createOptions = (): void => {
  const template = uiContentTemplatesStore.contentTemplateForAddingMetadata;
  if (template) {
    const valuesArray = getNewOptions().map((option: Option) => {
      const metadataDefinitionId = newDefinitionId.value
        ? newDefinitionId.value
        : (template as ContentTemplateForEditingMetadata).id;
      return {
        display_name: option.display_name,
        metadata_definition_id: metadataDefinitionId,
        display_order: option.display_order
      };
    });

    const payload = {
      values: valuesArray,
      productId: template.productId
    };
    metadataStore.createMetadataAllowedValues(payload);
  }
};
const removeOption = (option: Option): void => {
  cancelEditing(option);
  option.beingDeleted = true;
  if (option.isNew) {
    options.value = options.value.filter((opt: Option): boolean => {
      return opt.id !== option.id;
    });
  }
};

const type = ref<string>(TEXT_FREE);

const newDefinitionId = ref<number | undefined>(undefined);

const validateInput = (): boolean => {
  let isValid = true;
  if (!name.value) {
    nameError.value = "Name cannot be empty";
    isValid = false;
  }
  if (friendlyURL.value === "") {
    friendlyURL.value = name.value;
  }
  if (
    friendlyURL.value &&
    !METADATA.FRIENDLY_URL_PATTERN.test(friendlyURL.value)
  ) {
    friendlyURL.value = sanitizeFriendlyURL(friendlyURL.value);
  }
  return isValid;
};
const isValid = (): boolean => {
  return checkDuplicateOptions() || !validateInput() || checkOptionsCount();
};

const createMetadata = (): void => {
  const template = uiContentTemplatesStore.contentTemplateForAddingMetadata;
  if (template) {
    if (isValid()) {
      return;
    }

    isDataSubmitting.value = true;
    const payload = {
      metadata: {
        section_type_id: template.sectionTypeId,
        name: name.value,
        type: type.value,
        url_name: friendlyURL.value
      },
      productId: template.productId
    };
    metadataStore
      .createMetadataDefinition(payload)
      .then((response: AxiosResponse<DefinitionToCreateResponse>) => {
        newDefinitionId.value = response.data.data.id;
        if (type.value !== TEXT_FREE) {
          createOptions();
        }
        if (route.name === "assets") {
          assetsMetadataStore.isAssetsMetadataUpdating = true;
          assetsMetadataStore.populateAssetsMetadata(template.sectionTypeId);
        }
        isDataSubmitting.value = false;
        uiContentTemplatesStore.closeAddMetadataModal();
      });
  }
};
const editMetadata = async (): Promise<unknown> => {
  const template =
    uiContentTemplatesStore.contentTemplateForAddingMetadata as ContentTemplateForEditingMetadata;
  if (template) {
    if (isValid()) {
      return;
    }

    isDataSubmitting.value = true;
    if (
      name.value !== template.name ||
      friendlyURL.value !== template.friendlyURL
    ) {
      const payload = {
        metadata: {
          id: template.id,
          name: name.value,
          url_name: friendlyURL.value
        },
        productId: template.productId
      };
      await metadataStore.updateMetadataDefinition(payload);
    }
    if (type.value !== TEXT_FREE && getNewOptions().length > 0) {
      createOptions();
    }
    if (type.value !== TEXT_FREE && getEditableOptions().length > 0) {
      await Promise.all(
        getEditableOptions()
          .filter((option: Option): boolean => {
            return !option.isNew;
          })
          .map((option: Option): Promise<void> | void => {
            if (option.id) {
              const payload = {
                value: {
                  id: Number(option.id),
                  display_name: option.display_name,
                  display_order: option.display_order
                },
                productId: template.productId
              };
              return metadataStore.updateMetadataAllowedValues(payload);
            }
          })
      );
    }
    if (type.value !== TEXT_FREE && getBeingDeletedOptions().length > 0) {
      await Promise.all(
        getBeingDeletedOptions().map((option: Option): Promise<void> | void => {
          if (option.id) {
            const payload = {
              id: Number(option.id),
              productId: template.productId
            };
            return metadataStore.deleteMetadataAllowedValues(payload);
          }
        })
      );
    }
    if (route.name === "assets") {
      assetsMetadataStore.isAssetsMetadataUpdating = true;
      await assetsMetadataStore.populateAssetsMetadata(template.sectionTypeId);
    }
    isDataSubmitting.value = false;
    uiContentTemplatesStore.closeAddMetadataModal();
  }
};

const modalTitle = computed<string>(() => {
  return uiContentTemplatesStore.contentTemplateForAddingMetadata &&
    uiContentTemplatesStore.contentTemplateForAddingMetadata.modalType ===
      "edit"
    ? "Edit Metadata"
    : "Add Metadata";
});
onMounted(() => {
  const template = uiContentTemplatesStore.contentTemplateForAddingMetadata;
  if (template && template.modalType === "edit") {
    name.value = (template as ContentTemplateForEditingMetadata).name ?? "";
    friendlyURL.value =
      (template as ContentTemplateForEditingMetadata).friendlyURL ?? "";
    type.value =
      (template as ContentTemplateForEditingMetadata).type ?? TEXT_FREE;
    areOptionsVisible.value = type.value !== TEXT_FREE;
    setOptions();
  }
});
</script>
<style lang="scss" scoped>
.modal-card {
  width: 420px;
}
.value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > span {
    margin-right: auto;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
  &.hidden {
    display: none;
  }
}
.value-input {
  margin-right: 1rem;
  &:disabled {
    border-color: transparent !important;
    color: $grey100 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
</style>
