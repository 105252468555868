//Adaptive Learning Systems
export const STRATEGY_URL = "product/adaptive_learning_systems/strategies";
export const LESSONS_URL = "learning_systems/lessons";
export const CONTENT_OBJECTS_URL = "learning_systems/content_objects";
export const COURSE_DETAILS_URL = "learning_systems/courses";
export const CONCEPT_MAP_URL = "/learning_systems/concept-map";
export const PREVIEW_CONTENT_URL = "/learning_systems/content_preview";

//Assets
export const ASSETS_METADATA_URL = "assets_metadata";

//Auth
export const LOGIN_URL = "login";

//Bathes
export const BATCH_URL = "batch";
export const SECTION_TO_BATCH_URL = "section_to_batch";
export const DEADLINE_URL = "deadline";

//Content (=== ContentObject === Section)
export const SECTIONS_URL = "sections";
export const SECTIONS_UPSERT_URL = "sections/upsert";
export const STATE_TRANSITIONS_URL = "state_transitions";
export const DEPLOY_CONTENT_OBJECTS_URL = "deploy_content_objects";
export const MESSAGES_URL = "messages";
export const CONCEPT_SECTIONS_URL = "concept_sections";

//Content Templates (=== Workflows === SectionTypes === Components === Asset Template)
export const CONTENT_TEMPLATES_URL = "section_types";
export const WORKFLOWS_URL = "workflows";

//Curriculums
export const CURRICULUM_URL = "curriculum";
export const CURRICULUM_DEPLOY_URL = "curriculum/deploy";

//Documents
export const DOCUMENT_URL = "document";

//Files
export const FILES_URL = "files";
export const FILES_LINK_URL = "files_to_sections";
export const FILES_PRODUCT_LINK_URL = "files_to_products";

//MCQ
export const PREVIEW_URL = "section/preview";
export const DOWNLOAD_URL = "section/download";

//Metadata
export const METADATA_INSTANCE_URL = "metadata_instance";
export const METADATA_DEFINITION_URL = "metadata_definition";
export const METADATA_ALLOWED_VALUES_URL = "metadata_allowed_values";
export const METADATA_ALLOCATED_VALUES_URL = "metadata_allocated_values";
export const IMPORT_METADATA_URL = "section_types_copy";

//Organization
export const ORGANIZATIONS_URL = "organization";

//Permissions
export const PERMISSIONS_URL = "permissions";

//Products
export const PRODUCTS_URL = "product";
export const PRODUCT_SUMMARIES_URL = "product_summaries";
export const PRODUCT_DETAILS_URL = "product_details";
export const PRODUCT_IMG_URL = "product_image";
export const PRODUCT_FEATURE_URL = "product_feature";

//Programs
export const PROGRAMS_URL = "programmes";

//Users
export const USER_URL = "user";
export const USER_TO_STATE_URL = "user_to_state";
export const CHANGE_PASSWORD_URL = "passwords/change";

//QTI
export const QTI_IMG_URL = "/reference/get";
