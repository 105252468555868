export const X_API = {
  TYPES: [
    "",
    "http://adlnet.gov/expapi/activities/question",
    "http://adlnet.gov/expapi/activities/survey",
    "http://id.tincanapi.com/activitytype/survey",
    "http://adlnet.gov/expapi/activities/assessment",
    "http://adlnet.gov/expapi/activities/media",
    "http://adlnet.gov/expapi/activities/simulation",
    "http://adlnet.gov/expapi/activities/document"
  ],
  ERRORS: {
    xApiTypeNotSet: "xAPI type is not set for",
    invalidExternalLink: "Media content is not valid URL",
    externalLinkNotSet: "Media content is not set",
    metadataFriendlyURlNotSet: "Friendly URl is not set for metadata",
    duplicateMetadataFriendlyUrl:
      'Duplicate metadata "Friendly URL" for metadata',
    metadataValueMissing: "Value missing for metadata",
    noQuestionsAuthored: "No questions authored yet",
    questionsContainErrors: "Questions contain errors",
    noHTMLDocumentAuthored: "No HTML document authored yet",
    noQuestionAuthored: "No question authored yet",
    emptyQuestion: "Value missing for question",
    noInteractions: "No interactions added",
    emptyChoices: "Value missing for choice",
    noCorrectAnswers: "No correct answers added",
    lessThan2Choices: "Less than 2 choices added",
    interactionTypeNotSelected: "Interaction type not selected",
    hintNotAdded: "Hint not added",
    solutionNotAdded: "Solution/feedback not added",
    nothingToDeploy: "Nothing to deploy",
    deployingContentObjects: "Error deploying the content objects"
  },
  EXTENSIONS: {
    content: "/content/",
    contentMetadata: "/contentmetadata/",
    html: "/contentmetadata/display-html",
    pathExternalLink: "/external-link",
    concept: "/concept",
    question: "question",
    questionHtml: "questionHTML",
    questionSolutionHtml: "solutionHTML",
    questionHintHTML: "hintHTML",
    questionAnswersHtml: "correctAnswers",
    componentName: "component-name",
    difficulty: "difficulty",
    questionDetails: "question-details"
  },
  NAMESPACES: {
    adaptemy: "https://api.adaptemy.io",
    sg: "https://api-sg-demo.adaptemy.io"
  },
  SECTIONS_TO_SKIP: ["Folder", "Generic product"]
};
