<template>
  <div class="path">
    <span :title="internalPath">
      <router-link
        v-if="isClickable"
        :to="`/product/${product.id}`"
        class="btn-unstyled"
      >
        {{ product.name }}
      </router-link>
      <span v-else>{{ product.name }}</span>
      &nbsp;/
      <span v-if="path.length && isClickable">
        <button
          v-for="item in path"
          :key="item.id"
          class="btn-unstyled"
          @click="
            uiContentStore.openContentSidebar({
              sectionId: item.id,
              productId: product.id,
              workflowStateId: 0,
              hasCloseButton: true
            })
          "
        >
          <SectionIcon class="mr-1" :icon-id="item.iconId" :size="16" />
          <span v-if="showAsExternal" class="path-btn-text">{{
            item.externalId
          }}</span>
          <span v-else>
            <span v-if="isLongPathNameEnabled" class="path-btn-text">{{
              item.name
            }}</span
            ><span v-else class="path-btn-text"
              >{{ productShortName }}-{{ item.id }}</span
            > </span
          >&nbsp;/&nbsp;
        </button>
      </span>
      <span v-if="path.length && !isClickable">
        <span v-for="item in path" :key="item.id">
          <SectionIcon class="mr-1" :icon-id="item.iconId" :size="16" />
          <span v-if="showAsExternal" class="path-btn-text">{{
            item.externalId
          }}</span>
          <span v-else>
            <span v-if="isLongPathNameEnabled" class="path-btn-text">{{
              item.name
            }}</span
            ><span v-else class="path-btn-text"
              >{{ productShortName }}-{{ item.id }}</span
            > </span
          >&nbsp;/&nbsp;
        </span>
      </span>
      <span class="current-section">
        <SectionIcon class="mr-1" :icon-id="sectionIconId" :size="16" />
        <span v-if="showAsExternal">
          <span>{{ sectionExternalId }}</span>
        </span>
        <span v-else>
          <span v-if="isLongPathNameEnabled">{{ sectionName.trim() }}</span>
          <span v-else>{{ productShortName }}-{{ sectionId }}</span>
        </span>
      </span>
    </span>
  </div>
</template>
<script lang="ts" setup>
import { computed, PropType } from "vue";
import { useUIContentStore } from "@/stores/ui-content.store";
import { ProductItem } from "@/models/product.model";
import { AssetLocation } from "@/models/assets.model";
import {
  getInternalPath,
  getShortProductName
} from "@/utils/content-path-helpers";
import SectionIcon from "@/components/SectionIcon.vue";

const uiContentStore = useUIContentStore();

const props = defineProps({
  product: {
    required: true,
    type: Object as PropType<ProductItem>
  },
  path: {
    required: true,
    type: Array as PropType<AssetLocation[]>
  },
  sectionName: {
    required: true,
    type: String
  },
  sectionId: {
    required: true,
    type: Number
  },
  sectionIconId: {
    required: true,
    type: Number
  },
  sectionExternalId: {
    required: true,
    type: String
  },
  showAsExternal: {
    default: false,
    type: Boolean,
    required: false
  },
  isClickable: {
    default: true,
    type: Boolean,
    required: false
  },
  isLongPathNameEnabled: {
    required: true,
    type: Boolean
  }
});

const productShortName = computed<string>(() => {
  return getShortProductName(props.product.name);
});
const internalPath = computed<string>(() => {
  return getInternalPath(props.path, props.product.name, props.sectionName);
});
</script>
<style scoped lang="scss">
.path {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  font-size: 1rem;
  button {
    white-space: nowrap;
    &:hover,
    &:active {
      .path-btn-text {
        text-decoration: underline;
      }
    }
  }
  .current-section {
    white-space: nowrap;
  }
}
</style>
