<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="24"
    viewBox="0 0 30 24"
    xml:space="preserve"
  >
    <g transform="matrix(1 0 0 1 15 11.89)">
      <path
        style="
          stroke: none;
          stroke-width: 1;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-dashoffset: 0;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          fill-rule: nonzero;
          opacity: 1;
        "
        vector-effect="non-scaling-stroke"
        transform=" translate(-14, -11.35)"
        d="M 6.81086 0.7567 L 28 0.7567 L 28 3.78378 L 6.810860000000002 3.78378 L 6.810860000000002 0.7566999999999999 M 6.81086 12.86489 L 6.81086 9.837810000000001 L 28 9.837810000000001 L 28 12.86489 L 6.810860000000002 12.86489 M 2.27024 0 C 3.5240799999999997 0 4.54048 1.0164 4.54048 2.27024 C 4.54048 3.5240799999999997 3.5240799999999997 4.54048 2.27024 4.54048 C 1.0163999999999997 4.54048 0 3.5240799999999997 0 2.27024 C 0 1.0163999999999997 1.0164 0 2.27024 0 M 2.27024 9.08111 C 3.5240799999999997 9.08111 4.54048 10.09751 4.54048 11.35135 C 4.54048 12.60519 3.5240799999999997 13.62159 2.27024 13.62159 C 1.0163999999999997 13.62159 0 12.60519 0 11.35135 C 0 10.09751 1.0164 9.08111 2.27024 9.08111 M 6.81086 21.946 L 6.81086 18.91892 L 28 18.91892 L 28 21.946 L 6.810860000000002 21.946 M 2.27024 18.16222 C 3.5240799999999997 18.16222 4.54048 19.178620000000002 4.54048 20.432460000000003 C 4.54048 21.686300000000003 3.5240799999999997 22.702700000000004 2.27024 22.702700000000004 C 1.0163999999999997 22.702700000000004 0 21.686300000000003 0 20.432460000000003 C 0 19.178620000000002 1.0164 18.16222 2.27024 18.16222 z"
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>
<script setup></script>
<style lang="scss" scoped></style>
