const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const formatFine = (timestamp: number): string => {
  if (!timestamp) {
    throw new Error("timestamp missing");
  }
  if (timestamp < 100000000000) {
    throw new Error("Invalid time stamp 1000001");
  }
  const date = new Date(timestamp);
  const dayOMonth = date.getDate();
  const month = date.getMonth() + 1;
  const hour = date.getHours();
  const min = ("0" + date.getMinutes()).slice(-2);
  return `${dayOMonth}/${month} ${hour}:${min}`;
};

export const formatCourse = (timestamp: number): string => {
  if (!timestamp) {
    throw new Error("timestamp missing");
  }
  if (timestamp < 100000000000) {
    throw new Error("Invalid time stamp 1000001");
  }
  const date = new Date(timestamp);
  const dayOMonth = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${dayOMonth}-${months[month]}-${year}`;
};

export const formatDateAndMonth = (timestamp: number): string => {
  if (!timestamp) {
    throw new Error("timestamp missing");
  }
  if (timestamp < 100000000000) {
    throw new Error("Invalid time stamp 1000001");
  }
  const date = new Date(timestamp);
  const dayOMonth = date.getDate();
  const month = date.getMonth();
  return `${dayOMonth} ${months[month]}`;
};

export const formatFullDate = (timestamp: number): string => {
  if (!timestamp) {
    throw new Error("timestamp missing");
  }
  if (timestamp < 100000000000) {
    throw new Error("Invalid time stamp 1000001");
  }
  const date = new Date(timestamp);
  const dayOMonth = date.getDate();
  const dayOMonthFull = dayOMonth.toString().padStart(2, "0");
  const month = date.getMonth() + 1;
  const monthFull = month.toString().padStart(2, "0");
  const year = date.getFullYear();
  const shortYear = year.toString().slice(2);
  const hour = date.getHours();
  const min = ("0" + date.getMinutes()).slice(-2);
  return `${dayOMonthFull}/${monthFull}/${shortYear} ${hour}:${min}`;
};
