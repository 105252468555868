<template>
  <div
    :class="[
      'main-layout',
      {
        'right-sidebar-padding': uiGeneralStore.isRightSidebarOpen,
        'left-sidebar-padding': uiGeneralStore.isSidebarOpen,
        'has-subheader': hasSubHeader,
        'has-2-subheaders': has2SubHeaders
      }
    ]"
    data-testing="main-container"
  >
    <teleport to="body">
      <b-modal
        :model-value="uiFilesStore.isAttachFileModalOpen"
        :has-modal-card="true"
        :trap-focus="true"
        aria-role="dialog"
        :aria-modal="true"
        @cancel="uiFilesStore.closeAttachFileModal"
        class="full-screen-modal"
      >
        <AttachFile />
      </b-modal>

      <b-modal
        :model-value="uiContentTemplatesStore.isAddMetadataModalOpen"
        :has-modal-card="true"
        :trap-focus="true"
        aria-role="dialog"
        :aria-modal="true"
        @cancel="uiContentTemplatesStore.closeAddMetadataModal"
        class="full-screen-modal"
      >
        <AddMetadata />
      </b-modal>

      <b-modal
        :model-value="uiProductsStore.isProductImageModalVisible"
        :has-modal-card="true"
        :trap-focus="true"
        aria-role="dialog"
        :aria-modal="true"
        @cancel="uiProductsStore.closeProductImageModal"
        class="full-screen-modal"
      >
        <AddProductImage />
      </b-modal>

      <ContentSidebar />
      <FileSidebar />
      <CreateSection />

      <ToggleEditMode />
    </teleport>

    <Sidebar />
    <div>
      <Header />
      <div class="main-view">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Sidebar from "@/components/sidebar/Sidebar.vue";
import Header from "@/components/header/Header.vue";
import ContentSidebar from "@/components/right-sidebars/content-sidebar/ContentSidebar.vue";
import CreateSection from "@/components/right-sidebars/CreateSection.vue";
import FileSidebar from "@/components/right-sidebars/FileSB.vue";
import AttachFile from "@/components/popups/attachments/attach-file/AttachFile.vue";
import AddMetadata from "@/components/popups/AddMetadata.vue";
import AddProductImage from "@/components/popups/AddProductImage.vue";
import ToggleEditMode from "@/components/header/ToggleEditMode.vue";
import { useUIProductsStore } from "@/stores/ui-products.store";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useUIContentTemplatesStore } from "@/stores/ui-content-template.store";
import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useRoute } from "vue-router";
import { computed } from "vue";

const uiProductsStore = useUIProductsStore();
const uiFilesStore = useUIFilesStore();
const uiContentTemplatesStore = useUIContentTemplatesStore();
const uiGeneralStore = useUIGeneralStore();
const route = useRoute();

const hasSubheader = [
  "product",
  "adaptive-learning-systems-list",
  "adaptive-learning-system",
  "progress",
  "productTasks",
  "planning",
  "mcq",
  "qti-preview",
  "qti-editor",
  "html-editor",
  "html-preview",
  "question-editor",
  "question-preview",
  "create-batch"
];
const has2Subheaders = [
  "product-permissions",
  "feature-management",
  "content-templates",
  "assets",
  "content"
];
const hasSubHeader = computed<boolean>(() => {
  return hasSubheader.includes(route.name as string);
});
const has2SubHeaders = computed<boolean>(() => {
  return has2Subheaders.includes(route.name as string);
});
</script>
<style lang="scss" scoped>
.main-layout {
  // We need padding because we use position: fixed for the main header
  padding-top: $header-height;
  padding-left: $sidebar-width-sm;
  transition: padding-right 0.3s ease-out, padding-left 0.3s ease-out;
  &.has-subheader {
    padding-top: $product-page-header-height;
    .main-view {
      min-height: calc(100vh - #{$product-page-header-height});
      & > * {
        min-height: calc(100vh - #{$product-page-header-height});
      }
    }
  }
  &.has-2-subheaders {
    padding-top: calc(
      #{$product-page-header-height} + #{$bottom-subheader-height}
    );
    .main-view {
      min-height: calc(
        100vh - (#{$product-page-header-height} + #{$bottom-subheader-height})
      );
      & > * {
        min-height: calc(
          100vh - (#{$product-page-header-height} + #{$bottom-subheader-height})
        );
      }
    }
  }
  &.left-sidebar-padding {
    padding-left: $sidebar-width;
    @media (max-width: $breakpoint-md) {
      padding-left: $sidebar-width-md;
    }
  }
}
#app .full-screen-modal,
.dialog.modal.is-active {
  z-index: 902; // Must be greater than the z-index of the sidebar
}
</style>
