import { useUsersStore } from "@/stores/users.store";
import {
  ReportTableComparison,
  ReportTableFilterExtended,
  ReportTableState
} from "@/models/report-table.model";
import {
  generateFilterQuery,
  generateMultipleFilter
} from "@/utils/report-table-helpers";
import { TaskStatus } from "@/models/task.model";

export const useTasksQueryManager = () => {
  const usersStore = useUsersStore();

  const getTaskStatusesQuery = (): ReportTableFilterExtended[] => {
    const statuses = Object.values(TaskStatus).filter(status => {
      return (
        status !== TaskStatus.FINAL_STATE && status !== TaskStatus.COMPLETE
      );
    });
    return generateMultipleFilter(
      "statusWithIcon",
      statuses,
      ReportTableComparison.MATCHES
    );
  };
  const getTasksQuery = (): ReportTableState => {
    const queryRow = generateFilterQuery([
      {
        field: "responsible",
        value: usersStore.currentUser?.displayName ?? "",
        comparison: ReportTableComparison.DEFAULT
      },
      ...getTaskStatusesQuery()
    ]);
    return { filters: queryRow };
  };

  return {
    getTaskStatusesQuery,
    getTasksQuery
  };
};
