import axios, { AxiosResponse } from "axios";
import ls from "@/services/local-storage";
import axiosErrorHandler from "@/utils/axios-error-handler";

const DEPLOY_URL = "/deploy/qa";

const DAO = axios.create({
  baseURL: process.env.VUE_APP_CONTENT_API,
  withCredentials: false
});

DAO.interceptors.response.use(
  response => response,
  error => axiosErrorHandler(error)
);

const that = { deployToQA };

function deployToQA(
  documentId: string,
  sectionName: string
): Promise<AxiosResponse> {
  return DAO.put(DEPLOY_URL, {
    documentId,
    token: ls.getToken(),
    name: sectionName
  });
}

export default that;
