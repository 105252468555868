import { showToast } from "@/services/notification-manager";

export const copyToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text).then(
    () => {
      showToast({
        message: "Copied!",
        type: "is-success"
      });
    },
    () => {
      showToast({
        message: "Couldn't copy text!",
        type: "is-danger"
      });
    }
  );
};
