<template>
  <component :is="route.meta.layout === 'auth' ? AuthLayout : MainLayout" />
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue";
import ls from "@/services/local-storage";
import API from "@/api/index";
import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import { TrackJS } from "trackjs";
import { useUsersStore } from "@/stores/users.store";
import { useAuthStore } from "@/stores/auth.store";
import { useStoreManager } from "@/composables/store-manager";
import { useRoute, useRouter } from "vue-router";

const usersStore = useUsersStore();
const authStore = useAuthStore();

const route = useRoute();
const router = useRouter();

const { populateOrganizationData, populateGeneralData } = useStoreManager();

const isAuthPage = computed<boolean>(() => {
  return route.name === "authStart" || route.name === "authEnd";
});
const validateAPIToken = async (): Promise<boolean> => {
  try {
    await API.product.getAll();
    return true;
  } catch (error: any) {
    return error.response.status !== 401;
  }
};
const handleValidToken = async () => {
  await populateOrganizationData();
  populateGeneralData();
  const currentUser = usersStore.currentUser;
  if (currentUser) {
    TrackJS.configure({
      userId: String(currentUser.id)
    });
    TrackJS.addMetadata("logged-in-via", "browser");
  }
};
const handleInvalidToken = async () => {
  ls.clearToken();
  authStore.clearLoggedIn();
  await router.push({ name: "login" });
};
onMounted(async () => {
  if (router.currentRoute && !isAuthPage.value && ls.isTokenSet()) {
    authStore.setLoggedIn();

    const isValid = await validateAPIToken();
    isValid ? await handleValidToken() : await handleInvalidToken();
  }
});
</script>

<style lang="scss"></style>
