<template>
  <Collapse
    :is-content-empty="activity.length === 0 && !addingMessage"
    :class="{ updating: isUpdating }"
  >
    <template v-slot:title>Activity</template>
    <template v-slot:content>
      <ul
        class="activity-log list-unstyled"
        data-testing="activity-log-container"
      >
        <li
          v-if="addingMessage && usersStore.currentUser"
          :class="{ updating: savingMessage }"
        >
          <small class="date" data-testing="blackout">
            {{ getDateString() }}
          </small>
          <small>{{ usersStore.currentUser.displayName }}:</small>
          <span
            data-testing="new-message-text"
            id="new-message"
            ref="newMessageInput"
            class="new-message event-text"
            v-editable="true"
            @edited="saveMessage"
          ></span>
        </li>
        <li
          tabindex="0"
          class="message-container"
          :class="{
            updating: false
          }"
          data-testing="event"
          v-for="event in activity"
          :key="event.timeStamp"
        >
          <small class="date" data-testing="blackout">
            {{ event.displayDateTime }}
          </small>
          <div>
            <small v-if="event.userDisplayName"
              >{{ event.userDisplayName }}:
            </small>
            <span class="event-text">{{ event.text }}</span>
            <span v-if="event.type === 'file-attached'">
              <SVGIcon :path="mdiPaperclip" />
              <a :href="event.fileLocation" class="btn-3" target="_blank">
                {{ event.filename }}
              </a>
            </span>
            <button
              data-testing="delete-message"
              v-if="
                event.type === 'message' &&
                usersStore.currentUser.id === event.userId
              "
              @click="removeMessage(event.id)"
              v-on:blur="resetMessageDeletion()"
              :class="{ warning: messageBeingDeleted === event.id }"
              class="delete-message btn-3 float-right"
            >
              <small v-if="messageBeingDeleted === event.id">
                <SVGIcon :path="mdiCheck" :type="'is-danger'" />
                Confirm
              </small>
              <small v-else>
                <SVGIcon :path="mdiDelete" />
                Delete
              </small>
            </button>
          </div>
        </li>
      </ul>
      <div class="has-text-right">
        <button
          class="btn-3 float-right"
          v-if="enableShowMore"
          @click="toggleActivityLogShowMore()"
        >
          <span v-if="activityLogShowMore">
            <SVGIcon :path="mdiChevronUp" />
            Show fewer
          </span>
          <span v-else>
            <SVGIcon :path="mdiChevronDown" />
            Show more
          </span>
        </button>
      </div>
    </template>
    <template v-slot:footer>
      <footer class="card-footer">
        <div class="card-footer-item">
          <button
            class="btn-3"
            type="button"
            data-testing="add-message"
            @click="allowMessage"
          >
            <SVGIcon :path="mdiPlus" />
            Add message
          </button>
        </div>
      </footer>
    </template>
  </Collapse>
</template>
<script lang="ts" setup>
import { computed, nextTick, PropType, ref } from "vue";
import { formatFullDate } from "@/utils/formatTimestamp";
import Collapse from "../ui/Collapse.vue";
import { WorkflowState } from "@/models/workflow.model";
import { OrganizationUser } from "@/models/user.model";
import {
  Message,
  SectionFile,
  StateTransition
} from "@/models/product-section.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import {
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiDelete,
  mdiPaperclip,
  mdiPlus
} from "@mdi/js";
import { useUsersStore } from "@/stores/users.store";
import { useContentStore } from "@/stores/content.store";

interface Activity {
  displayDateTime: string;
  id: number;
  text: string;
  timestamp: number;
  type: string;
  userDisplayName: string;
  userId?: number;
  filename?: string;
  fileLocation?: string;
}

const activityLogLimit = 5;

const usersStore = useUsersStore();
const contentStore = useContentStore();

const props = defineProps({
  isUpdating: {
    required: false,
    type: Boolean
  },
  section: {
    required: true,
    type: Object as PropType<ContentStructureSection>
  },
  sectionId: {
    required: true,
    type: Number
  },
  productId: {
    required: true,
    type: Number
  },
  workflowStates: {
    required: true,
    type: Array as PropType<WorkflowState[]>
  }
});

const addingMessage = ref<boolean>(false);
const savingMessage = ref<boolean>(false);
const messageBeingDeleted = ref<number | undefined>(undefined);
const removeMessage = (messageId: number): void => {
  if (!messageBeingDeleted.value) {
    messageBeingDeleted.value = messageId;
  } else if (messageBeingDeleted.value === messageId) {
    contentStore.deleteMessage({
      messageId: messageId,
      productId: props.productId
    });
  }
};
const resetMessageDeletion = (): void => {
  messageBeingDeleted.value = undefined;
};
const saveMessage = (event: CustomEvent<{ textContent: string }>): void => {
  const message = event.detail.textContent;
  savingMessage.value = true;
  contentStore
    .submitMessage({
      productId: props.productId,
      sectionId: props.sectionId,
      message: message
    })
    .then((): void => {
      addingMessage.value = false;
      savingMessage.value = false;
    });
};
const newMessageInput = ref<HTMLElement | null>(null);
const allowMessage = (): void => {
  addingMessage.value = true;
  nextTick(() => {
    if (newMessageInput.value) {
      newMessageInput.value.focus();
    }
  });
};

const activityLogShowMore = ref<boolean>(false);
const toggleActivityLogShowMore = (): void => {
  activityLogShowMore.value = !activityLogShowMore.value;
};

const enableShowMore = ref<boolean>(false);
const messages = computed<Activity[]>(() => {
  return props.section.messages.map((message: Message): Activity => {
    const user = usersStore.users?.find(
      (e: OrganizationUser): boolean => e.id === message.user_id
    );
    return {
      displayDateTime: formatFullDate(message.updated_on_millis),
      timestamp: message.updated_on_millis,
      userDisplayName: user ? user.displayName : "",
      userId: message.user_id,
      text: message.message,
      type: "message",
      id: message.id
    };
  });
});
const transitionActivities = computed<Activity[]>(() => {
  const transitions: StateTransition[] = props.section.state_transitions.filter(
    (transition: StateTransition): boolean => {
      const newState: WorkflowState | undefined = props.workflowStates.find(
        (e: WorkflowState): boolean => e.id === transition.state_id
      );
      return !!newState;
    }
  );
  return transitions.map((tEvent: StateTransition): Activity => {
    const user: OrganizationUser | undefined = usersStore.users?.find(
      (e: OrganizationUser): boolean => e.id === tEvent.actor
    );
    const currentWorkflowState: WorkflowState | undefined =
      props.workflowStates.find(
        (e: WorkflowState): boolean => e.id === tEvent.state_id
      );
    const newStateName: string =
      currentWorkflowState && currentWorkflowState.name
        ? currentWorkflowState.name
        : "";
    return {
      displayDateTime: formatFullDate(tEvent.created_on_millis),
      timestamp: tEvent.created_on_millis,
      userDisplayName: user ? user.displayName : "",
      text: "Moved to " + newStateName,
      type: "transition",
      id: tEvent.id
    };
  });
});
const fileAttachments = computed<Activity[]>(() => {
  return props.section.files.map((file: SectionFile): Activity => {
    const user: OrganizationUser | undefined = usersStore.users?.find(
      (e: OrganizationUser): boolean => e.id === file.link_created_by
    );
    return {
      displayDateTime: formatFullDate(file.link_created_on),
      timestamp: file.link_created_on,
      userDisplayName: user ? user.displayName : "",
      userId: file.link_created_by,
      text: "",
      filename: file.name,
      fileLocation: file.location,
      type: "file-attached",
      id: file.file_to_section_id
    };
  });
});
const fileRemovals = computed<Activity[]>(() => {
  return props.section.files
    .filter((e: SectionFile): boolean => e.link_deleted_on)
    .map((file: SectionFile) => {
      const user = usersStore.users?.find(
        (e: OrganizationUser): boolean => e.id === file.link_created_by
      );
      return {
        displayDateTime: formatFullDate(
          file.link_deleted_on as unknown as number
        ),
        timestamp: file.link_deleted_on
          ? (file.link_deleted_on as unknown as number)
          : 0,
        userDisplayName: user ? user.displayName : "",
        userId: file.link_deleted_by
          ? (file.link_deleted_by as unknown as number)
          : 0,
        text: "Removed file: " + file.name,
        filename: file.name,
        fileLocation: file.location,
        type: "file-deleted",
        id: file.file_to_section_id
      };
    });
});
const componentAdditions = computed<Activity[]>(() => {
  return props.section.sections.map(
    (section: ContentStructureSection): Activity => {
      const user = usersStore.users?.find(
        (e: OrganizationUser): boolean => e.id === section.created_by
      );
      return {
        displayDateTime: formatFullDate(section.created_on_millis),
        timestamp: section.created_on_millis,
        userDisplayName: user ? user.displayName : "",
        userId: section.created_by,
        text: "Added " + section.type.name + ": " + section.name,
        type: "component-added",
        id: section.id
      };
    }
  );
});
const enableActivityLogShowMore = (arr: Activity[]): void => {
  enableShowMore.value = arr.length > activityLogLimit;
};
const activity = computed<Activity[]>(() => {
  const activityLogArr = [
    ...messages.value,
    ...transitionActivities.value,
    ...fileAttachments.value,
    ...fileRemovals.value,
    ...componentAdditions.value
  ].sort((a: Activity, b: Activity): number => {
    return b.timestamp - a.timestamp;
  });

  enableActivityLogShowMore(activityLogArr);
  return !activityLogShowMore.value
    ? activityLogArr.slice(0, activityLogLimit)
    : activityLogArr;
});

const getDateString = (): string => {
  return formatFullDate(Date.now());
};
</script>
<style lang="scss" scoped>
li {
  list-style: none;
  padding-bottom: 0.1rem;
  font-size: 0.875rem;
}
button {
  .plus-icon {
    color: $primary-color;
  }
  &:focus .plus-icon,
  &:hover .plus-icon {
    color: $primary-color-light;
  }
  &:hover[title]::after {
    white-space: pre;
  }
}
.activity-log {
  overflow-y: auto;
  max-height: calc(100vh - 25rem);
  margin: 0;
}
.delete-message {
  display: none;
  background-color: $background-highlight-color;
  padding: 0 0.25rem;
}
.new-message.event-text {
  display: inline-block;
  min-width: 1rem;
}
.message-container {
  display: flex;
  position: relative;
  &:hover,
  &:focus,
  &:focus-within {
    background-color: $background-highlight-color;
    outline: 0;
    .delete-message {
      display: initial;
      position: absolute;
      top: 0;
      right: 0.25rem;
    }
  }
}
.date {
  display: inline-block;
  min-width: 5.25rem; //fixed width for visual test blackout
  padding-right: 0.25rem;
}
</style>
