import { defineStore } from "pinia";
import API from "@/api";
import { computed, ref } from "vue";
import { Curriculum } from "@/models/curriculum.model";
import {
  CurriculumToUpdate,
  UpdatedCurriculumResponse
} from "@/models/api/curriculums.model";
import { AxiosResponse } from "axios";

export const useCurriculumsStore = defineStore("curriculums", () => {
  const areCurriculumsLoading = ref<boolean>(true);
  const areCurriculumsUpdating = ref<boolean>(false);
  const curriculums = ref<Curriculum[] | null>(null);
  const availableCurriculums = computed<Curriculum[]>(() => {
    if (!curriculums.value) {
      return [];
    }
    return curriculums.value.filter((curriculum: Curriculum) => {
      return curriculum.deleted_on === null && curriculum.deleted_by === null;
    });
  });

  const populateCurriculums = (): Promise<void> => {
    return API.curriculum.getAll().then((response: Curriculum[]) => {
      areCurriculumsLoading.value = false;
      areCurriculumsUpdating.value = false;
      curriculums.value = response;
    });
  };

  const upsertCurriculum = (
    payload: CurriculumToUpdate
  ): Promise<AxiosResponse<UpdatedCurriculumResponse>> => {
    areCurriculumsUpdating.value = true;
    return API.curriculum
      .update(payload)
      .then(async (response: AxiosResponse<UpdatedCurriculumResponse>) => {
        await populateCurriculums();
        return response;
      });
  };
  const uploadCurriculum = (
    payload: CurriculumToUpdate
  ): Promise<AxiosResponse<UpdatedCurriculumResponse>> => {
    areCurriculumsUpdating.value = true;
    return API.curriculum
      .upload(payload)
      .then(async (response: AxiosResponse<UpdatedCurriculumResponse>) => {
        await populateCurriculums();
        return response;
      });
  };
  const deleteCurriculum = (cId: number): Promise<AxiosResponse> => {
    areCurriculumsUpdating.value = true;
    return API.curriculum.remove(cId).then(async (response: AxiosResponse) => {
      await populateCurriculums();
      return response;
    });
  };

  return {
    areCurriculumsLoading,
    areCurriculumsUpdating,
    curriculums,
    availableCurriculums,
    populateCurriculums,
    upsertCurriculum,
    uploadCurriculum,
    deleteCurriculum
  };
});
