import { WorkflowState } from "@/models/workflow.model";
import { ProductItem } from "@/models/product.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import { SectionFile } from "@/models/product-section.model";

export interface TypeIcon {
  icon: {
    size: number;
    name: string;
    iconId: number;
  };
}

export interface StatusWithIcon {
  icon: {
    path: string;
    type: string;
    title: string;
    dataTesting: string;
    size: number;
  };
  html: string;
}

export interface Attachment {
  icon: {
    path: string;
  };
  html: string;
}

export interface TaskDisplay {
  assignedUsersList: string[];
  batch: any;
  deadline: number | undefined;
  id: number;
  isComplete: boolean;
  key: string;
  name: { html: string };
  nameString: string;
  product: ProductItem;
  productName: string;
  responsible: string;
  responsibleString: string;
  statusString: string;
  statusWithIcon: StatusWithIcon;
  typeIcon: TypeIcon;
  typeName: any;
  workflowStateId: number | undefined;
  state?: string;
  attachments: Attachment;
  attachmentsString: string;
  files: SectionFile[];
}

export class TaskDisplayData {
  constructor(
    public readonly workflowState: WorkflowState,
    public readonly section: ContentStructureSection
  ) {}
}

export enum TaskStatus {
  FINAL_STATE = "",
  COMPLETE = "complete",
  CURRENT = "current",
  CURRENT_LATE = "current late",
  UPCOMING = "upcoming",
  UPCOMING_LATE = "upcoming late"
}

export interface LastUpdate {
  date: number;
  userDisplayName: string;
}
