import { AssetLocation } from "@/models/assets.model";

export const getInternalPath = (
  path: AssetLocation[],
  productName: string,
  sectionName: string
) => {
  if (path.length) {
    const sections = path.map((p: AssetLocation) => {
      return ` / ${p.name}`;
    });
    return `${productName}${sections.join("")} / ${sectionName}`;
  }
  return `${productName} / ${sectionName}`;
};

export const getExternalPath = (
  path: AssetLocation[],
  productName: string,
  sectionExternalId: string
): string => {
  if (path.length) {
    const sections = path.map((p: AssetLocation) => {
      return ` / ${p.externalId}`;
    });
    return `${productName}${sections.join("")} / ${sectionExternalId}`;
  }
  return `${productName} / ${sectionExternalId}`;
};

export const getShortProductName = (productName: string) => {
  const firstLettersArray = productName.toUpperCase().match(/\b\w/g);
  return firstLettersArray ? firstLettersArray.join("") : "";
};
