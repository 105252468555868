<template>
  <div class="modal-card">
    <div class="modal-card-header">
      <h3>
        Move
        <em>{{ section.name }}</em> to
        <em>{{ pendingTransition.name }}</em>
      </h3>
    </div>
    <div class="modal-card-content">
      <form
        class="padding-standard"
        @submit.prevent="$emit('confirmTransition')"
      >
        <label class="margin-b-half">
          Enter a message <small>(optional)</small>
          <textarea
            :value="props.modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
          ></textarea>
        </label>

        <button
          class="btn-1 float-right"
          data-testing="confirm-transition"
          type="submit"
        >
          Confirm
        </button>
        <button
          class="btn-2 float-right margin-r-standard"
          @click.prevent="$emit('closeTransitionConfirmation')"
        >
          Cancel
        </button>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { PendingTransition } from "@/models/transition-state.model";

const props = defineProps({
  section: {
    required: true,
    type: Object as PropType<ContentStructureSection>
  },
  pendingTransition: {
    required: true,
    type: Object as PropType<PendingTransition>
  },
  modelValue: {
    required: true,
    type: String
  }
});
defineEmits([
  "confirmTransition",
  "closeTransitionConfirmation",
  "update:modelValue"
]);
</script>

<style lang="scss" scoped></style>
