import { DirectiveBinding } from "vue";
import { VNode } from "vue";

let originalContent = "";

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding<any>, vnode: VNode) {
    originalContent = el.textContent ? el.textContent : "";
    el.contentEditable = binding.value ? "true" : "false";
    el.addEventListener("keydown", function (event: KeyboardEvent) {
      if (event.key === "Escape") {
        el.innerHTML = originalContent;
        el.blur();
      } else if (event.key === "Enter") {
        el.blur();
        event.preventDefault();
      }
    });
    el.addEventListener("blur", function () {
      if (el.textContent === originalContent) {
        return;
      }
      if (binding.modifiers.integer) {
        forceInteger(el);
      }
      if (vnode.component) {
        vnode.component.emit("edited", formatEventObject(el));
      } else if (el) {
        el.dispatchEvent(new CustomEvent("edited", formatEventObject(el)));
      }
    });
    el.addEventListener("focus", function () {
      originalContent = el.textContent ? el.textContent : "";
    });
  },
  updated(el: HTMLElement, binding: DirectiveBinding<any>) {
    el.contentEditable = binding.value ? "true" : "false";
  }
};

function formatEventObject(el: HTMLElement) {
  return {
    detail: {
      textContent: el.textContent
    }
  };
}

function forceInteger(el: HTMLElement) {
  const value = parseInt(el.textContent ? el.textContent : "");
  if (isNaN(value)) {
    el.innerHTML = originalContent;
  } else {
    el.innerHTML = String(value);
  }
}
