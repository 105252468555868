import { defineStore } from "pinia";
import API from "@/api";
import { ref } from "vue";
import { Programme } from "@/models/programme.model";
import {
  ProgramToCreate,
  ProgramToCreateResponse,
  ProgramToUpdate
} from "@/models/api/program.model";
import { AxiosResponse } from "axios";

export const useProgrammesStore = defineStore("programmes", () => {
  const areProgrammesLoading = ref<boolean>(true);
  const areProgrammesUpdating = ref<boolean>(false);
  const programmes = ref<Programme[] | null>(null);

  const populateProgrammes = () => {
    return API.program.getAll().then((response: Programme[]) => {
      areProgrammesLoading.value = false;
      areProgrammesUpdating.value = false;
      programmes.value = response;
    });
  };
  const createProgramme = (payload: ProgramToCreate) => {
    areProgrammesUpdating.value = true;
    return API.program
      .create(payload)
      .then(async (response: AxiosResponse<ProgramToCreateResponse>) => {
        await populateProgrammes();
        return response;
      });
  };
  const updateProgramme = (payload: ProgramToUpdate) => {
    areProgrammesUpdating.value = true;
    return API.program.update(payload).then(async (response: AxiosResponse) => {
      await populateProgrammes();
      return response;
    });
  };
  const deleteProgramme = (payload: number) => {
    areProgrammesUpdating.value = true;
    return API.program.remove(payload).then(async (response: AxiosResponse) => {
      await populateProgrammes();
      return response;
    });
  };

  return {
    areProgrammesLoading,
    areProgrammesUpdating,
    programmes,
    populateProgrammes,
    createProgramme,
    updateProgramme,
    deleteProgramme
  };
});
