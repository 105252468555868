<template>
  <b-modal
    :aria-modal="true"
    :has-modal-card="true"
    :model-value="uiFilesStore.isFileVersionModalOpen"
    :trap-focus="true"
    aria-role="dialog"
    @cancel="uiFilesStore.closeAttachmentVersionModal"
  >
    <div class="modal-card" data-testing="upload-file-card">
      <div class="modal-card-header">
        <h3>
          Upload a new version of <i>{{ file.name }}</i>
        </h3>
      </div>
      <form
        id="upload-file-version"
        class="modal-card-content"
        @submit.prevent="handleFileUpload"
      >
        <label class="margin-b-half">
          Select an Attachment
          <input
            id="file-version-input"
            ref="fileVersionInput"
            :disabled="filesStore.areFilesUpdating"
            data-testing="file-version-input"
            type="file"
            @change="fetchLocalFile"
          />
        </label>
      </form>
      <div class="modal-card-footer">
        <button
          :disabled="!fileToUpload || filesStore.areFilesUpdating"
          class="btn-1 full-width"
          data-testing="upload-file-version-btn"
          form="upload-file-version"
        >
          Upload new version
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script lang="ts" setup>
import { PropType, ref } from "vue";
import { SectionFile } from "@/models/product-section.model";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFileManager } from "@/composables/files-manager";
import { useFilesStore } from "@/stores/files.store";

const { uploadFileVersion } = useFileManager();
const uiFilesStore = useUIFilesStore();
const filesStore = useFilesStore();

const props = defineProps({
  file: {
    required: true,
    type: Object as PropType<SectionFile>
  },
  productId: {
    required: true,
    type: [Number, String]
  }
});

const fileVersionInput = ref<HTMLInputElement | null>(null);
const fileToUpload = ref<File | undefined>(undefined);

const fetchLocalFile = (): void => {
  if (fileVersionInput.value && fileVersionInput.value.files) {
    fileToUpload.value = fileVersionInput.value.files[0];
  }
};
const handleFileUpload = (): void => {
  if (!props.file.is_external && fileToUpload.value) {
    const formData = new FormData();
    formData.append("file", fileToUpload.value);
    formData.append("id", String(props.file.id));
    uploadFileVersion({
      formData: formData,
      productId: Number(props.productId)
    }).then(() => {
      uiFilesStore.closeAttachmentVersionModal();
    });
  } else {
    console.log("update path for external file");
  }
};
</script>

<style lang="scss" scoped>
.modal-card {
  width: auto;
}
form {
  min-width: 23rem;
}
</style>
