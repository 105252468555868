// @ts-nocheck
import { QTI_IMG_URL } from "@/api/URL-constants";
import axios, { AxiosResponse } from "axios";
import ls from "@/services/local-storage";
import { QtiImgResponse } from "@/models/api/qti";

const cachedImageSrcs: Record<
  string,
  Promise<AxiosResponse<QtiImgResponse>>
> = {};
const getImageSrcByPID = (
  pId: string
): Promise<AxiosResponse<QtiImgResponse>> => {
  if (cachedImageSrcs[pId]) {
    return cachedImageSrcs[pId];
  }
  cachedImageSrcs[pId] = axios.post(process.env.VUE_APP_QTI_URL + QTI_IMG_URL, {
    context: {
      editSessionToken: ls.getToken()
    },
    permanentIds: [pId]
  });
  return cachedImageSrcs[pId];
};

export { getImageSrcByPID };
