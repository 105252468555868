<template>
  <Collapse :is-content-empty="isContentEmpty">
    <template v-slot:title>
      <span v-if="!isAuthoringTool">Attachments</span>
      <span v-else>Authoring Tool</span>
      <Hint v-if="XAPIErrors.length > 0" :type="'error'" :position="'top'">
        <ul>
          <li v-for="(error, index) in XAPIErrors" :key="index">{{ error }}</li>
        </ul>
      </Hint>
      <Hint v-if="XAPIWarnings.length > 0" :type="'warning'" :position="'top'">
        <ul>
          <li v-for="(error, index) in XAPIWarnings" :key="index">{{
            error
          }}</li>
        </ul>
      </Hint>
    </template>
    <template v-slot:content>
      <!--Media Content-->
      <MediaContent
        v-if="hasExternalContentAuthoring"
        :section="section"
        @previewAttachment="previewAttachment"
      />

      <!--MCQ-->
      <MCQ
        v-if="hasMCQAuthoring"
        :section="section"
        :is-content-authoring-tier2-feature-enabled="
          isContentAuthoringTier2UserFeatureEnabled
        "
      />

      <!--QTI-->
      <QTI
        v-if="hasQTIAuthoring"
        :section="section"
        :is-content-authoring-tier2-feature-enabled="
          isContentAuthoringTier2UserFeatureEnabled
        "
      />

      <!--HTML Editor-->
      <HTMLEditor
        v-if="hasHTMLAuthoring"
        :section="section"
        :is-content-authoring-tier2-feature-enabled="
          isContentAuthoringTier2UserFeatureEnabled
        "
      />

      <!--Question Editor-->
      <QuestionEditor
        v-if="hasQuestionAuthoring"
        :section="section"
        :is-content-authoring-tier2-feature-enabled="
          isContentAuthoringTier2UserFeatureEnabled
        "
      />

      <!--Attachments-->
      <Attachments :section="section" @previewAttachment="previewAttachment" />
    </template>
    <template v-if="!isAuthoringTool" v-slot:footer>
      <footer class="files-list card-footer" data-testing="files-card-footer">
        <div
          data-testing="files-card-footer-btn-add-file"
          class="card-footer-item"
        >
          <button class="btn-3" type="button" @click="openFileModal">
            <SVGIcon :path="mdiPlus" />
            Add attachment
          </button>
        </div>
      </footer>
    </template>
  </Collapse>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import Collapse from "@/components/right-sidebars/ui/Collapse.vue";
import Attachments from "@/components/right-sidebars/content-sidebar/authoring-tool/Attachments.vue";
import MediaContent from "@/components/right-sidebars/content-sidebar/authoring-tool/MediaContent.vue";
import MCQ from "@/components/right-sidebars/content-sidebar/authoring-tool/MCQ.vue";
import QTI from "@/components/right-sidebars/content-sidebar/authoring-tool/QTI.vue";
import HTMLEditor from "@/components/right-sidebars/content-sidebar/authoring-tool/HTMLEditor.vue";
import Hint from "@/components/Hint.vue";
import { ProductFeature, ProductItem } from "@/models/product.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import { mdiPlus } from "@mdi/js";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { TOOLS } from "@/constants/template.constants";
import { PRODUCT } from "@/constants/product.constants";
import QuestionEditor from "@/components/right-sidebars/content-sidebar/authoring-tool/QuestionEditor.vue";

const uiFilesStore = useUIFilesStore();

const props = defineProps({
  product: {
    type: Object as PropType<ProductItem>,
    required: true
  },
  section: {
    type: Object as PropType<ContentStructureSection>,
    required: true
  },
  isAContentObject: {
    type: Boolean,
    required: true
  },
  XAPIErrors: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => []
  },
  XAPIWarnings: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => []
  }
});

const isContentAuthoringTier2UserFeatureEnabled = computed<boolean>(() => {
  const feature = props.product.features.find(
    (feature: ProductFeature): boolean => {
      return feature.id === PRODUCT.FEATURES.contentAuthoringTier2.id;
    }
  );
  return !!feature && feature.isEnabled;
});

const isAuthoringTool = computed<boolean>(() => {
  return props.section.type.tool_id > 0;
});

const isContentEmpty = computed<boolean>(() => {
  return !isAuthoringTool.value && props.section.enabled_files.length === 0;
});
const hasMCQAuthoring = computed<boolean>(() => {
  return props.section && TOOLS.isMCQTool(props.section.type.tool_id);
});
const hasQTIAuthoring = computed<boolean>(() => {
  return props.section && TOOLS.isQTITool(props.section.type.tool_id);
});
const hasHTMLAuthoring = computed<boolean>(() => {
  return props.section && TOOLS.isHTMLTool(props.section.type.tool_id);
});
const hasExternalContentAuthoring = computed<boolean>(() => {
  return (
    props.section && TOOLS.isExternalContentTool(props.section.type.tool_id)
  );
});
const hasQuestionAuthoring = computed<boolean>(() => {
  return (
    props.section && TOOLS.isQuestionAuthoringTool(props.section.type.tool_id)
  );
});

const openFileModal = (): void => {
  uiFilesStore.openAttachFileModal({ section: props.section });
};
const emit = defineEmits(["previewAttachment"]);
const previewAttachment = (fileLocation: string): void => {
  emit("previewAttachment", fileLocation);
};
</script>
<style lang="scss" scoped></style>
