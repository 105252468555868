import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { ContentTemplateForDisplay } from "@/models/store/ui-state/content-template-for-display.model";
import {
  ContentTemplateForAddingMetadata,
  ContentTemplateForEditingMetadata
} from "@/models/store/ui-state/content-template-for-adding-metadata.model";

export const useUIContentTemplatesStore = defineStore(
  "uiContentTemplates",
  () => {
    const contentTemplateForDisplay = ref<ContentTemplateForDisplay | null>(
      null
    );
    const openContentTemplateSidebar = (
      component: ContentTemplateForDisplay
    ) => {
      contentTemplateForDisplay.value = component;
    };
    const closeContentTemplateSidebar = () => {
      contentTemplateForDisplay.value = null;
    };

    const isIconModalVisible = ref<boolean>(false);
    const toggleIconsModal = () => {
      isIconModalVisible.value = !isIconModalVisible.value;
    };

    const contentTemplateForAddingMetadata = ref<
      | ContentTemplateForAddingMetadata
      | ContentTemplateForEditingMetadata
      | null
    >(null);
    const isAddMetadataModalOpen = computed<boolean>(() => {
      return !!contentTemplateForAddingMetadata.value;
    });
    const openAddMetadataModal = (
      params:
        | ContentTemplateForAddingMetadata
        | ContentTemplateForEditingMetadata
    ) => {
      contentTemplateForAddingMetadata.value = params;
    };
    const closeAddMetadataModal = () => {
      contentTemplateForAddingMetadata.value = null;
    };

    return {
      contentTemplateForDisplay,
      openContentTemplateSidebar,
      closeContentTemplateSidebar,
      isIconModalVisible,
      toggleIconsModal,
      contentTemplateForAddingMetadata,
      isAddMetadataModalOpen,
      openAddMetadataModal,
      closeAddMetadataModal
    };
  }
);
