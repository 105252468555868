<template>
  <Collapse>
    <template v-slot:title>
      <span>Path</span>
    </template>
    <template v-slot:content>
      <div class="margin-b-standard is-relative">
        <RightSidebarTitle :title="'Internal path'" />
        <label class="toggle-check">
          <input
            type="checkbox"
            aria-label="edit"
            :value="isLongPathNameEnabled"
            data-testing="toggle-edit-mode"
            class="editable"
            v-model="isLongPathNameEnabled"
          />
          <span
            data-on-text="Long path name"
            data-off-text="Short path name"
          ></span>
        </label>
        <ContentLocationPath
          :product="product"
          :path="path"
          :section-name="sectionName"
          :section-id="sectionId"
          :section-icon-id="sectionIconId"
          :section-external-id="sectionExternalId"
          :is-long-path-name-enabled="isLongPathNameEnabled"
        />
        <button class="btn-3" @click="copyToClipboard(internalPath)">
          <SVGIcon :path="mdiLinkVariant" />
          Copy
        </button>
        <button
          class="btn-3 margin-l-standard"
          v-if="isEditButtonVisible"
          @click="uiContentStore.openEditContentPathModal"
        >
          <SVGIcon :path="mdiPencil" :size="16" />
          Edit
        </button>
      </div>
      <div>
        <RightSidebarTitle :title="'External path reference'" />
        <ContentLocationPath
          :product="product"
          :path="path"
          :section-name="sectionName"
          :section-id="sectionId"
          :section-icon-id="sectionIconId"
          :section-external-id="sectionExternalId"
          :show-as-external="true"
          :is-long-path-name-enabled="false"
        />
        <button class="btn-3" @click="copyToClipboard(externalPath)">
          <SVGIcon :path="mdiLinkVariant" :size="16" />
          Copy
        </button>
      </div>
    </template>
  </Collapse>
</template>

<script lang="ts" setup>
import Collapse from "@/components/right-sidebars/ui/Collapse.vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import ContentLocationPath from "@/components/right-sidebars/content-sidebar/content-location/ContentLocationPath.vue";
import { mdiLinkVariant, mdiPencil } from "@mdi/js";
import { computed, PropType, ref } from "vue";
import { useUIContentStore } from "@/stores/ui-content.store";
import { ProductItem } from "@/models/product.model";
import { AssetLocation } from "@/models/assets.model";
import { getExternalPath, getInternalPath } from "@/utils/content-path-helpers";
import { copyToClipboard } from "@/services/copy-to-clipboard";

const uiContentStore = useUIContentStore();

const props = defineProps({
  product: {
    required: true,
    type: Object as PropType<ProductItem>
  },
  path: {
    required: true,
    type: Array as PropType<AssetLocation[]>
  },
  sectionName: {
    required: true,
    type: String
  },
  sectionId: {
    required: true,
    type: Number
  },
  sectionIconId: {
    required: true,
    type: Number
  },
  sectionExternalId: {
    required: true,
    type: String
  },
  canEditContent: {
    required: true,
    type: Boolean
  }
});

const isLongPathNameEnabled = ref<boolean>(true);
const isEditButtonVisible = computed<boolean>(() => {
  // user can move section, if product contains not only root section and current section
  return props.canEditContent && props.product.sections.length > 2;
});
const externalPath = computed<string>(() => {
  return getExternalPath(
    props.path,
    props.product.name,
    props.sectionExternalId
  );
});
const internalPath = computed<string>(() => {
  return getInternalPath(props.path, props.product.name, props.sectionName);
});
</script>
<style lang="scss" scoped>
.toggle-check {
  position: absolute;
  right: 0;
  top: -0.125rem;
  z-index: 1;
  transform: scale(0.75);
}
</style>
