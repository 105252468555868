import { defineStore } from "pinia";
import API from "@/api";
import { ref } from "vue";
import { AxiosResponse } from "axios";
import { LoginResponse } from "@/models/api/login.model";
import ls from "@/services/local-storage";

export const useAuthStore = defineStore("auth", () => {
  const isLoggedIn = ref<boolean>(false);

  const login = ({
    username,
    password
  }: {
    username: string;
    password: string;
  }): Promise<AxiosResponse<LoginResponse> | undefined> => {
    return API.auth
      .validateLogin(username, password)
      .then((response: AxiosResponse<LoginResponse>) => {
        if (response.status === 201) {
          isLoggedIn.value = true;
          return response;
        }
      });
  };
  const authorizeByToken = (token: string): boolean => {
    ls.setToken(token);
    isLoggedIn.value = true;
    return true;
  };
  const clearLoggedIn = () => {
    isLoggedIn.value = false;
  };
  const setLoggedIn = () => {
    isLoggedIn.value = true;
  };

  return {
    isLoggedIn,
    login,
    authorizeByToken,
    clearLoggedIn,
    setLoggedIn
  };
});
