<template>
  <input
    id="content-name"
    data-testing="edit-section-name"
    v-model="name"
    :class="[
      'ghost-input',
      { expanded: uiContentStore.isContentSidebarExpanded }
    ]"
    :readonly="!canEditContent"
    @change="saveContentName"
  />
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import { useContentStore } from "@/stores/content.store";
import { useUIContentStore } from "@/stores/ui-content.store";
import { showToast } from "@/services/notification-manager";
import { SECTION } from "@/constants/assets.constant";

const contentStore = useContentStore();
const uiContentStore = useUIContentStore();

const props = defineProps({
  contentName: {
    required: true,
    type: String
  },
  sectionId: {
    required: true,
    type: Number
  },
  productId: {
    required: true,
    type: Number
  },
  canEditContent: {
    required: true,
    type: Boolean
  }
});

const name = ref<string>("");
onMounted(() => {
  name.value = props.contentName;
});
watch(
  () => props.contentName,
  newVal => {
    if (newVal) {
      name.value = newVal;
    }
  }
);
const saveContentName = (event: CustomEvent<{ textContent: string }>): void => {
  let sName = event.detail.textContent.trim();
  if (sName !== "" && sName !== props.contentName) {
    contentStore
      .upsertSections({
        productIds: [props.productId],
        sections: [
          {
            id: props.sectionId,
            name: sName
          }
        ]
      })
      .catch(error => {
        showToast({
          message: `${SECTION.ERRORS.nameNotUpdated}: ${error.message}`,
          type: "is-danger"
        });
      });
  }
};
</script>
<style lang="scss" scoped>
.ghost-input {
  margin-right: 4rem !important;

  &:read-only {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.expanded {
    margin-right: 12rem !important;
  }
}
</style>
