<template>
  <div>
    <RightSidebarTitle :title="'Attachments'" v-if="isAuthoringTool">
      <template v-slot>
        <Hint :type="'info'" :position="'bottom'">
          <p>Any files/links required for authoring this content</p>
        </Hint>
        <button
          class="btn-unstyled ml-auto"
          type="button"
          @click="openFileModal"
          title="Add an attachment"
          data-testing="sb-add-attachment-btn"
        >
          <SVGIcon :path="mdiPlus" />
        </button>
      </template>
    </RightSidebarTitle>
    <FilesList
      v-if="filesToDisplay.length > 0"
      :filesToDisplay="filesToDisplay"
      :section="section"
      @previewAttachment="previewAttachment"
    />
    <div class="has-text-right">
      <button class="btn-3" v-if="enableShowMoreBtn" @click="toggleFilesList()">
        <span v-if="showFullList">
          <SVGIcon :path="mdiChevronUp" />
          Show fewer
        </span>
        <span v-else>
          <SVGIcon :path="mdiChevronDown" />
          Show more
        </span>
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, PropType, ref } from "vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import Hint from "@/components/Hint.vue";
import FilesList from "@/components/right-sidebars/content-sidebar/authoring-tool/FilesList.vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { SectionFile } from "@/models/product-section.model";
import { mdiChevronDown, mdiChevronUp, mdiPlus } from "@mdi/js";
import { useUIFilesStore } from "@/stores/ui-files.store";

const FILE_DISPLAY_LIMIT = 5;

const uiFilesStore = useUIFilesStore();

const emit = defineEmits(["previewAttachment"]);
const props = defineProps({
  section: {
    type: Object as PropType<ContentStructureSection>,
    required: true
  }
});

const filesToDisplay = computed<SectionFile[]>(() => {
  let files = [...props.section.enabled_files];
  if (files.length > FILE_DISPLAY_LIMIT && !showFullList.value) {
    return files.slice(0, FILE_DISPLAY_LIMIT);
  }
  return files;
});
const isAuthoringTool = computed<boolean>(() => {
  return props.section.type.tool_id > 0;
});

const showFullList = ref<boolean>(false);
const enableShowMoreBtn = computed<boolean>(() => {
  return props.section.enabled_files.length > FILE_DISPLAY_LIMIT;
});
const toggleFilesList = (): void => {
  showFullList.value = !showFullList.value;
};

const openFileModal = (): void => {
  uiFilesStore.openAttachFileModal({ section: props.section });
};
const previewAttachment = (fileLocation: string): void => {
  emit("previewAttachment", fileLocation);
};
</script>
<style scoped lang="scss"></style>
