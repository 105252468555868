import { defineStore } from "pinia";
import { ref } from "vue";

export const useUIUsersStore = defineStore("uiUsers", () => {
  const userForDisplay = ref<number | null>(null);
  const openUserSidebar = (userId: number) => {
    userForDisplay.value = userId;
  };
  const closeUserSidebar = () => {
    userForDisplay.value = null;
  };

  return {
    userForDisplay,
    openUserSidebar,
    closeUserSidebar
  };
});
