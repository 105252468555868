import { ref } from "vue";
import {
  ConceptJson,
  CurriculumJson,
  TopicJson
} from "@/models/curriculum.model";
import API from "@/api";
import { getCurriculumJsonFromXml } from "@/utils/curriculum/get-curriculum-json-from-xml";
import { ProductItem } from "@/models/product.model";
import {
  ProductSection,
  SectionConcepts
} from "@/models/product-section.model";
import { uniq } from "lodash";
import { showToast } from "@/services/notification-manager";

export const useCurriculumManager = () => {
  const isCurriculumLoading = ref<boolean>(true);
  const xmlParsedSuccessfully = ref<boolean>(true);

  const curriculumJson = ref<CurriculumJson | null>(null);
  const topicsFromXml = ref<TopicJson[] | null>(null);
  const conceptsFromXml = ref<ConceptJson[] | null>(null);

  const setCurriculumJson = async (curriculumId: number): Promise<void> => {
    const response = await API.curriculum.get(curriculumId);
    const curriculumXml = response.curriculum;
    try {
      curriculumJson.value = getCurriculumJsonFromXml(curriculumXml);
      topicsFromXml.value = curriculumJson.value.topicsJSON.sort(
        (topicA: TopicJson, topicB: TopicJson) =>
          topicA.displayOrder - topicB.displayOrder
      );
      conceptsFromXml.value = curriculumJson.value.conceptsJSON;
      xmlParsedSuccessfully.value = true;
    } catch (error) {
      showToast({
        message: "Curriculum file is invalid",
        type: "is-danger"
      });
    }
    isCurriculumLoading.value = false;
  };
  const resetCurriculumData = (): void => {
    curriculumJson.value = null;
    topicsFromXml.value = null;
    conceptsFromXml.value = null;
    xmlParsedSuccessfully.value = false;
  };
  const curriculumConceptsGuidsFromProductSections = (
    product: ProductItem
  ): string[] => {
    const productSectionsConceptsGuids: string[] = [];
    if (curriculumJson.value && curriculumJson.value.conceptsJSON) {
      const conceptsGuidsFromCurriculum = curriculumJson.value.conceptsJSON.map(
        (conceptJson: ConceptJson) => {
          return conceptJson.guid;
        }
      );
      product.sections.forEach((productSection: ProductSection) => {
        productSection.concepts.forEach((sectionConcept: SectionConcepts) => {
          if (
            conceptsGuidsFromCurriculum.includes(sectionConcept.concept_guid)
          ) {
            productSectionsConceptsGuids.push(sectionConcept.concept_guid);
          }
        });
      });
    }
    return uniq(productSectionsConceptsGuids);
  };

  return {
    isCurriculumLoading,
    xmlParsedSuccessfully,
    curriculumJson,
    topicsFromXml,
    conceptsFromXml,
    setCurriculumJson,
    resetCurriculumData,
    curriculumConceptsGuidsFromProductSections
  };
};
