export const capitalize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const convertFromStringToSnakeCase = (str: string): string => {
  return str.toLowerCase().replaceAll(" ", "_");
};

export const convertFromSnakeCaseToString = (str: string): string => {
  return str.replaceAll("_", " ");
};

export const convertBrToComa = (str: string): string => {
  return str.replaceAll("<br>", ",").replaceAll("<br />", ",");
};

export const convertToStringWithoutSpaces = (str: string): string => {
  return str.toLowerCase().replaceAll(" ", "");
};

export const trimAllSpaces = (str: string): string => {
  return str.replace(/&nbsp;/g, " ").trim();
};

export const getValueFromURL = (url: string, splitParam = "/"): string => {
  return url.substr(url.lastIndexOf(splitParam) + 1);
};

export const convertCamelCaseToString = (camelCase: string): string => {
  const str = camelCase.replace(/([a-z])([A-Z])/g, "$1 $2");
  return str.toLowerCase();
};

export const convertFromStringToCamelCase = (str: string) => {
  const words = str.split(" ");
  const camelCaseWords = [
    words[0].toLowerCase(),
    ...words.slice(1).map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
  ];
  return camelCaseWords.join("");
};

export const replaceSlashByURISymbol = (str: string): string => {
  return str.replaceAll("/", "%2F");
};

export const truncateString = (str: string, length = 25) => {
  if (str.length > length) {
    return str.slice(0, length) + "...";
  }
  return str;
};
