<template>
  <div class="programme-links padding-standard">
    <h3>Programmes</h3>
    <ul
      :class="{
        'not-available': !orgStore.canOrgManageProgrammes,
        updating: programmesStore.areProgrammesUpdating
      }"
    >
      <li
        v-for="programme in displayProducts"
        :key="programme.id"
        class="clearfix"
      >
        <button
          @click="toggleProgramme(programme.id)"
          v-if="programme.products.length > 0"
          class="btn-3 show-programmes-button margin-r-half"
          >{{ openProgrammes.includes(programme.id) ? "-" : "+" }}</button
        >
        <span
          v-if="programme.products.length === 0"
          class="show-programmes-button margin-r-half"
        ></span>
        <router-link
          class="programme-link"
          :to="`/programme/${programme.id}/products`"
          data-testing="sidebar-product-name"
          >{{ programme.name }}</router-link
        >
        <ul
          v-if="
            programme.products.length > 0 &&
            openProgrammes.includes(programme.id)
          "
        >
          <li
            class="product-link"
            v-for="product in programme.products"
            :key="product.id"
          >
            <small>
              <router-link :to="`/product/${product.id}`">
                {{ product.name }}
              </router-link>
            </small>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import { Programme } from "@/models/programme.model";
import { ProductItem } from "@/models/product.model";
import { getUnarchivedProducts } from "@/utils/filter-products-helpers";
import { useOrganizationStore } from "@/stores/organization.store";
import { useProgrammesStore } from "@/stores/programmes.store";
import { useProductsStore } from "@/stores/products.store";

interface DisplayProduct {
  name: string;
  id: number;
  products: ProductItem[];
}

const orgStore = useOrganizationStore();
const programmesStore = useProgrammesStore();
const productsStore = useProductsStore();

const openProgrammes = ref<number[]>([]);

const productDetailsForDisplay = computed<ProductItem[]>(() => {
  if (!productsStore.productDetails) {
    return [];
  }
  return productsStore.productDetails.filter((product: ProductItem) => {
    return product.allocated_permissions.length > 0;
  });
});
const unarchivedProductDetailsForDisplay = computed<ProductItem[]>(() => {
  if (!productDetailsForDisplay.value.length) {
    return [];
  }
  return getUnarchivedProducts(productDetailsForDisplay.value);
});
const displayProducts = computed<DisplayProduct[]>(() => {
  if (!programmesStore.programmes) {
    return [];
  }
  return programmesStore.programmes.map((programme: Programme) => {
    const products: ProductItem[] =
      unarchivedProductDetailsForDisplay.value.filter(
        (e: ProductItem): boolean => e.programme_id === programme.programme_id
      );
    return {
      name: programme.name,
      id: programme.programme_id,
      products: products
    };
  });
});

const toggleProgramme = (id: number): void => {
  if (openProgrammes.value.includes(id)) {
    openProgrammes.value = openProgrammes.value.filter(
      (e: number): boolean => e !== id
    );
  } else {
    openProgrammes.value.push(id);
  }
};
</script>
<style lang="scss" scoped>
h3 {
  color: $blue20;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.programme-link.router-link-active,
.product-link a.router-link-active {
  color: $dark;
  font-weight: bold;
}

.programme-link {
  width: calc(100% - 2.5rem);
  display: block;
  float: left;
  font-size: 0.9rem;
  color: $blue20;
  transition: all 0.2s ease-in-out;
  text-decoration-color: transparent;
  &:not(.router-link-active) {
    &:hover,
    &:active,
    &:focus {
      color: $blue40;
      text-decoration-color: $blue40;
    }
  }
}
.product-link {
  padding-left: 1.75rem;
  font-size: 0.75rem;
  a {
    color: $blue40;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.2s ease-in-out;
    &:not(.router-link-active) {
      &:hover,
      &:active,
      &:focus {
        color: $blue40;
        text-decoration-color: $blue40;
      }
    }
  }
}
.programme-links {
  border-top: 1px solid $primary-color-light;
  ul {
    clear: both;
    list-style: none;
    padding: 0;
  }

  .show-programmes-button {
    float: left;
    color: $blue20;
    width: 0.75rem;
    height: 1.25rem;
    line-height: 1rem;
    clear: both;
    transition: all 0.2s ease-in-out;
    &:hover,
    &:active,
    &:focus,
    &:hover ~ .programme-link:not(.router-link-active) {
      color: $blue40;
    }
  }
}
</style>
