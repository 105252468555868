<template>
  <Collapse :class="{ updating: isUpdating }">
    <template v-slot:title>
      Metadata
      <Hint v-if="XAPIErrors.length > 0" :type="'error'" :position="'top'">
        <ul>
          <li v-for="(error, index) in XAPIErrors" :key="index">{{ error }}</li>
        </ul>
      </Hint>
    </template>
    <template v-if="canEditContent" v-slot:content>
      <div v-for="(item, index) in section.metadata_instances" :key="item.id">
        <TextFree
          v-if="item.metadata_definition.type === 'Text Free'"
          :item="item"
          :product-id="product.id"
        />
        <div v-else>
          <!-- if type is 'Single option' or 'Multiple options' -->
          <strong class="margin-r-half"
            >{{ item.metadata_definition.name }}
            <small
              >(select
              {{
                item.metadata_definition.type === "Single Option"
                  ? "one"
                  : "multiple"
              }})
            </small>
          </strong>
          <ul class="list-unstyled">
            <li
              v-for="value in getMetadataAllowedValues(
                item.metadata_definition.id
              )"
              :key="value.id"
            >
              <label :for="value.id">
                <SingleOption
                  v-if="item.metadata_definition.type === 'Single Option'"
                  :current-instance="item"
                  :instances="section.metadata_instances"
                  :product-id="product.id"
                  :value="value"
                />
                <MultipleOption
                  v-if="item.metadata_definition.type === 'Multiple Options'"
                  :current-instance="item"
                  :instances="section.metadata_instances"
                  :product-id="product.id"
                  :value="value"
                />
                {{ value.display_name }}
              </label>
            </li>
          </ul>
        </div>
        <hr v-if="index < section.metadata_instances.length - 1" />
      </div>
    </template>
    <template v-else v-slot:content>
      <div v-for="(item, index) in displayedMetadata" :key="item.id">
        <div v-if="item.metadata_definition.type === 'Text Free'">
          <strong class="margin-r-half"
            >{{ item.metadata_definition.name }}:</strong
          >
          <span>{{ item.authored_text }}</span>
        </div>
        <div v-else>
          <!-- if type is 'Single option' or 'Multiple options' -->
          <strong class="margin-r-half">{{
            item.metadata_definition.name
          }}</strong>
          <ul class="list-disk">
            <li
              v-for="value in getMetadataCheckedValues(item.id)"
              :key="value.id"
            >
              <span>{{ value.name }}</span>
            </li>
          </ul>
        </div>
        <hr v-if="index < displayedMetadata.length - 1" />
      </div>
    </template>
  </Collapse>
</template>
<script lang="ts" setup>
import { computed, PropType } from "vue";
import Collapse from "../../ui/Collapse.vue";
import {
  ContentTemplate,
  MetadataAllowedValues,
  MetadataDefinitionItem
} from "@/models/content-templates.model";
import Hint from "@/components/Hint.vue";
import { MetadataInstance } from "@/models/product-section.model";
import {
  getMetadataInstance,
  isMetadataValueChecked
} from "@/utils/content-metadata-helpers";
import { ContentStructureSection } from "@/models/content-structure.model";
import TextFree from "@/components/right-sidebars/content-sidebar/content-metadata/TextFree.vue";
import SingleOption from "@/components/right-sidebars/content-sidebar/content-metadata/SingleOption.vue";
import MultipleOption from "@/components/right-sidebars/content-sidebar/content-metadata/MultipleOption.vue";
import { ProductItem } from "@/models/product.model";

interface MetadataCheckedValue {
  id: number;
  name: string;
}

const props = defineProps({
  product: {
    required: true,
    type: Object as PropType<ProductItem>
  },
  section: {
    required: true,
    type: Object as PropType<ContentStructureSection>
  },
  canEditContent: {
    required: true,
    type: Boolean
  },
  displayedMetadata: {
    required: true,
    type: Array as PropType<MetadataInstance[]>
  },
  XAPIErrors: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => []
  },
  isUpdating: {
    required: true,
    type: Boolean
  }
});

const template = computed<ContentTemplate | undefined>(() => {
  if (!props.product) {
    return undefined;
  }
  return props.product.component_definitions.find(
    (e: ContentTemplate): boolean => e.id === props.section.type.id
  );
});

const getMetadataAllowedValues = (id: number): MetadataAllowedValues[] => {
  if (template.value) {
    const metadataDefinition: MetadataDefinitionItem | undefined =
      template.value.metadata_definition.find(
        (e: MetadataDefinitionItem): boolean => e.id === id
      );
    return metadataDefinition ? metadataDefinition.metadata_allowed_values : [];
  }
  return [];
};

const getMetadataCheckedValues = (
  instanceId: number
): MetadataCheckedValue[] => {
  const instance: MetadataInstance | undefined = getMetadataInstance(
    props.section.metadata_instances,
    instanceId
  );
  if (instance) {
    const allowedValues: MetadataAllowedValues[] = getMetadataAllowedValues(
      instance.metadata_definition.id
    );
    return allowedValues
      .filter((value: MetadataAllowedValues): boolean =>
        isMetadataValueChecked(instance, value.id)
      )
      .map((value: MetadataAllowedValues): MetadataCheckedValue => {
        return {
          id: value.id,
          name: value.display_name
        };
      });
  }
  return [];
};
</script>
<style lang="scss" scoped>
hr {
  width: 100%;
  border-bottom: 1px solid $border-color;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
