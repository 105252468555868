<template>
  <span class="icon-container">
    <SVGIcon v-if="isDocument" :path="mdiFileWordBox" />
    <SVGIcon v-else-if="isSpreadsheet" :path="mdiFileExcelBox" />
    <SVGIcon v-else-if="isPresentation" :path="mdiFilePowerpointBox" />
    <SVGIcon v-else-if="isPDF" :path="mdiFilePdfBox" />
    <SVGIcon v-else-if="isImage" :path="mdiImage" />
    <SVGIcon v-else-if="isText" :path="mdiTextBox" />
    <SVGIcon v-else-if="isVideo" :path="mdiVideoOutline" />
    <SVGIcon v-else-if="isYouTubeVideo" :path="mdiYoutube" />
    <SVGIcon v-else-if="isDropbox" :path="mdiDropbox" />
    <SVGIcon v-else :path="mdiPaperclip" />
  </span>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import {
  mdiDropbox,
  mdiFileExcelBox,
  mdiFilePdfBox,
  mdiFilePowerpointBox,
  mdiFileWordBox,
  mdiImage,
  mdiPaperclip,
  mdiTextBox,
  mdiVideoOutline,
  mdiYoutube
} from "@mdi/js";

const props = defineProps({
  fileLocation: {
    type: String,
    required: true
  }
});

const isImage = computed<boolean>(() => {
  return props.fileLocation.match(/\.(jpeg|jpg|gif|png|webp)$/) !== null;
});
const isPDF = computed<boolean>(() => {
  return props.fileLocation.match(/\.(pdf)$/) !== null;
});
const isVideo = computed<boolean>(() => {
  return props.fileLocation.match(/\.(wmv|mpeg|mpg|mov|avi|mp4|m4v)$/) !== null;
});
const isYouTubeVideo = computed<boolean>(() => {
  return (
    props.fileLocation.includes("youtube.com") ||
    props.fileLocation.includes("youtu.be")
  );
});
const isDropbox = computed<boolean>(() => {
  return props.fileLocation.includes("dropbox.com");
});
const isText = computed<boolean>(() => {
  return props.fileLocation.match(/\.(txt)$/) !== null;
});
const isDocument = computed<boolean>(() => {
  return (
    props.fileLocation.match(/\.(doc|docx|rtf)$/) !== null ||
    props.fileLocation.includes("docs.google.com/document/") ||
    props.fileLocation.includes("sharepoint.com/:w:/")
  );
});
const isSpreadsheet = computed<boolean>(() => {
  return (
    props.fileLocation.match(/\.(xls|xlsx|csv)$/) !== null ||
    props.fileLocation.includes("docs.google.com/spreadsheets/") ||
    props.fileLocation.includes("sharepoint.com/:x:/")
  );
});
const isPresentation = computed<boolean>(() => {
  return (
    props.fileLocation.match(/\.(ppt|pptx|ppsx|pps)$/) !== null ||
    props.fileLocation.includes("docs.google.com/presentation/") ||
    props.fileLocation.includes("sharepoint.com/:p:/")
  );
});
</script>
<style scoped lang="scss">
.icon-container {
  margin-right: 0.25rem;
  svg {
    width: 12px !important;
    height: 12px !important;
  }
}
</style>
