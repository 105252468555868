import axios, { AxiosError, AxiosResponse } from "axios";
import { LOGIN_URL } from "@/api/URL-constants";
import ls from "@/services/local-storage";
import { LoginResponse } from "@/models/api/login.model";

let token = ls.getToken();

const validateLogin = (
  username: string,
  password: string
): Promise<AxiosResponse<LoginResponse>> => {
  return axios
    .post(process.env.VUE_APP_API_URL + LOGIN_URL, {
      username: username,
      password: password
    })
    .then((response: AxiosResponse<LoginResponse>) => {
      if (response.status === 201) {
        token = response.data.data.auth.token;
        ls.setToken(token);
      }
      return response;
    })
    .catch((error: AxiosError) => {
      throw error.response;
    });
};

export { validateLogin };
