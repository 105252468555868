import {
  MCQPayloadVarDefinition,
  MCQPayloadMode,
  MCQPayloadModeItem,
  MCQPayloadModes,
  MCQPayloadVarDefinitionTitle
} from "@/models/api/mcq.model";
import { MCQQuestion } from "@/models/mcq.model";
import { ContentStructureSection } from "@/models/content-structure.model";

export default {
  generateDefinition: generateDefinition
};

function generateDefinition(
  section: ContentStructureSection
): MCQPayloadVarDefinition {
  const title: MCQPayloadVarDefinitionTitle = {};
  title[section.name] = null;
  const modes: MCQPayloadModes = {
    "1": generateMode(section)
  };
  return {
    link: {
      "5AT": null
    },
    title: title,
    subtitle: {
      "2nd Class": null
    },
    source: "02_08_B_READER_TEST",
    generated: "29/06/2018 14:03",
    dashboard: "?a=1402",
    modes: modes,
    fonts: {
      "chelsea market":
        "@font-face { font-family: 'chelsea market'; src:  url('REA/REA2/FONT/chelsea_market.woff2') format('woff2'), url('REA/REA2/FONT/chelsea_market.woff') format('woff'); }",
      muli: "@font-face { font-family: 'muli'; src:  url('REA/REA2/FONT/muli.woff2') format('woff2'), url('REA/REA2/FONT/muli.woff') format('woff'); }",
      "muli black":
        "@font-face { font-family: 'muli black'; src:  url('REA/REA2/FONT/muli_black.woff2') format('woff2'), url('REA/REA2/FONT/muli_black.woff') format('woff'); }",
      "muli extrabold":
        "@font-face { font-family: 'muli extrabold'; src:  url('REA/REA2/FONT/muli_extrabold.woff2') format('woff2'), url('REA/REA2/FONT/muli_extrabold.woff') format('woff'); }",
      "sassoon infant rg":
        "@font-face { font-family: 'sassoon infant rg'; src:  url('REA/REA2/FONT/sassoon_infant_rg.woff2') format('woff2'), url('REA/REA2/FONT/sassoon_infant_rg.woff') format('woff'); }"
    }
  };
}

function generateMode(section: ContentStructureSection): MCQPayloadMode {
  let items: MCQPayloadModeItem[] = [];
  let settings = {
    randomiseQuestions: false,
    randomiseAnswers: false,
    multipleAttempts: false
  };
  let title = "No title entered";
  if (section.authored_content && section.authored_content.questions) {
    items = section.authored_content.questions.map(generateItem);
  }
  if (section.authored_content && section.authored_content.settings) {
    settings = section.authored_content.settings;
  }
  if (section.authored_content && section.authored_content.title) {
    title = section.authored_content.title;
  }
  return {
    mode: "MCQ",
    identifier: "MCQ1",
    label: section.name,
    thumbnail: "MCQ/MCQ1/THUMB/02_08_MCQ1_TH_01.png",
    title: title,
    "multiple attempts": settings.multipleAttempts,
    "question count": null,
    "question randomise": settings.randomiseQuestions,
    "answer randomise": settings.randomiseAnswers,
    "question box outline": true,
    "answer box outline": true,
    "question interval delay": null,
    "progress type": "blue",
    "fanfare type": "None",
    "fanfare message": null,
    items: items
  };
}

function generateItem(qn: MCQQuestion, index: number): MCQPayloadModeItem {
  return {
    caption: {
      type: "question",
      text: qn.qn || "No text entered"
    },
    id: "MCQQ" + index,
    answers: [
      {
        caption: {
          type: "answer",
          text: qn.correctAns || "No correct answer entered"
        },
        id: `MCQA${index}_1`,
        correct: true
      },
      {
        caption: {
          type: "answer",
          text: qn.incorrectAns || "No incorrect answer entered"
        },
        id: `MCQA${index}_2`,
        correct: false
      }
    ]
  };
}
