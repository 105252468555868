<template>
  <div>
    <b-collapse class="card" animation="slide" :model-value="transitionIsOpen">
      <template #trigger="props" :class="{ disable: transitionIsEmpty }">
        <div class="card-header" role="button">
          <div class="card-header-title title-flex">
            <span class="title-flex">
              <SVGIcon
                v-if="transitionStatusIsComplete"
                title="complete"
                :path="mdiCheckboxMarkedCircleOutline"
                type="is-success"
                data-testing="complete"
                :size="24"
              />
              <SVGIcon
                v-if="transitionStatusIsCurrent"
                title="on track"
                :path="mdiCircle"
                type="is-warning"
                data-testing="on-track"
                :size="24"
              />
              <SVGIcon
                v-if="transitionStatusIsUpcoming"
                title="on track"
                :path="mdiCircleOutline"
                type="is-dark"
                data-testing="on-track"
                :size="24"
              />
              <span class="margin-l-half">{{ transition.name }}</span>
            </span>
            <b-dropdown
              :disabled="!transition.isTransitionAvailableForThisUser"
              aria-role="list"
              position="is-bottom-left"
            >
              <template #trigger="{ active }">
                <b-button
                  :class="[
                    'btn-transition',
                    {
                      'btn-upcoming': transitionStatusIsUpcoming,
                      'btn-in-progress': transitionStatusIsCurrent,
                      'btn-complete': transitionStatusIsComplete
                    }
                  ]"
                  :icon-right="active ? 'menu-up' : 'menu-down'"
                >
                  {{ displayedStatus }}
                </b-button>
              </template>
              <b-dropdown-item
                aria-role="listitem"
                v-for="availableTransition in transition.availableTransitions"
                :key="availableTransition.id"
                :data-testing="`state-transition-btn-${availableTransition.id}`"
                :disabled="submittingStateTransition"
                @click="
                  $emit('requestTransitionConfirmation', availableTransition)
                "
              >
                {{ availableTransition.availableStatus }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <a class="card-header-icon">
            <SVGIcon
              :path="props.open ? mdiMenuDown : mdiMenuUp"
              :customClass="transitionIsEmpty ? 'hidden' : ''"
              type="is-dark"
              :size="24"
            />
          </a>
        </div>
      </template>
      <div :class="['card-content', { hidden: transitionIsEmpty }]">
        <div class="content">
          <div v-if="transitionHaveDeadline">
            <strong>Deadline: </strong>{{ transition.deadline }}
          </div>
          <div v-if="transitionHaveInstruction">
            <strong>Instructions: </strong>{{ transition.instruction }}
          </div>
          <div v-if="transitionHaveAssignedUsers">
            <strong>Assigned Users: </strong>
            <ul>
              <li v-for="user in transition.assignedUsers" :key="user.id">
                {{ user.firstName }} {{ user.lastName }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import { TaskStatus } from "@/models/task.model";
import { WorkflowState } from "@/models/workflow.model";
import { SidebarTransitionState } from "@/models/transition-state.model";
import {
  mdiCheckboxMarkedCircleOutline,
  mdiCircle,
  mdiCircleOutline,
  mdiMenuDown,
  mdiMenuUp
} from "@mdi/js";
import { TASKS } from "@/constants/assets.constant";

const props = defineProps({
  transition: {
    type: Object as PropType<SidebarTransitionState>,
    required: true
  },
  currentState: {
    type: Object as PropType<WorkflowState>,
    required: true
  },
  submittingStateTransition: {
    type: Boolean,
    required: true
  },
  isPinnedState: {
    type: Boolean,
    required: true
  }
});

const transitionStatusIsComplete = computed<boolean>(() => {
  return (
    props.transition.status === TaskStatus.COMPLETE ||
    props.transition.status === TaskStatus.FINAL_STATE
  );
});
const transitionStatusIsCurrent = computed<boolean>(() => {
  return (
    props.transition.status === TaskStatus.CURRENT ||
    props.transition.status === TaskStatus.CURRENT_LATE
  );
});
const transitionStatusIsUpcoming = computed<boolean>(() => {
  return (
    props.transition.status === TaskStatus.UPCOMING ||
    props.transition.status === TaskStatus.UPCOMING_LATE
  );
});
const displayedStatus = computed<string>(() => {
  if (transitionStatusIsComplete.value) {
    return TASKS.STATUSES_DISPLAY_NAME.complete;
  }
  if (transitionStatusIsCurrent.value) {
    return TASKS.STATUSES_DISPLAY_NAME.current;
  }
  if (transitionStatusIsUpcoming.value) {
    return TASKS.STATUSES_DISPLAY_NAME.upcoming;
  }
  return TASKS.STATUSES_DISPLAY_NAME.upcoming;
});

const transitionIsOpen = computed<boolean>(() => {
  return props.transition.id === props.currentState.id || props.isPinnedState;
});
const transitionHaveDeadline = computed<boolean>(() => {
  return Boolean(props.transition.deadline);
});
const transitionHaveInstruction = computed<boolean>(() => {
  return Boolean(props.transition.instruction);
});
const transitionHaveAssignedUsers = computed<boolean>(() => {
  return Boolean(props.transition.assignedUsers.length);
});
const transitionIsEmpty = computed<boolean>(() => {
  return (
    !transitionHaveDeadline.value &&
    !transitionHaveInstruction.value &&
    !transitionHaveAssignedUsers.value
  );
});
</script>
<style lang="scss" scoped>
.card-header {
  box-shadow: none;
  &-title {
    padding: 0.75rem;
  }
}
.title-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    max-width: 100px;
  }
}
.card-content {
  padding: 0 12px 12px; // to match title
}
.content ul {
  margin: 5px;
  padding: 0 1rem 0;
}

.btn-transition {
  font-size: 14px;
  font-weight: normal;
  border: 0;
}
.btn-upcoming {
  background: $border-color-dark;
}
.btn-in-progress {
  background: $warning-color-transparent;
}
.btn-complete {
  background: $success-color;
}
</style>
