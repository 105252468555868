import { DirectiveBinding } from "vue";

export default {
  beforeMount(el: HTMLElement, binding: DirectiveBinding<any>) {
    el.style.visibility = binding.value ? "visible" : "hidden";
  },
  updated(el: HTMLElement, binding: DirectiveBinding<any>) {
    el.style.visibility = binding.value ? "visible" : "hidden";
  }
};
