import cookies from "@/services/cookies";
/**
 * Note: The curriculum tool shares a login cookie with the main Thoth application.
 *       The teams integration can't use cookies and must use local storage.
 *       That's why both are set and unset here.
 */

export default {
  isTokenSet: isTokenSet,
  setToken: setToken,
  getToken: getToken,
  clearToken: clearToken
};

const KEY = "ada-dolly-token";

function isTokenSet(): boolean {
  return !!getToken();
}

function setToken(token: string): void {
  window.localStorage.setItem(KEY, token);
  cookies.setCookie(KEY, token);
}

function getToken(): string | null {
  return window.localStorage.getItem(KEY);
}

function clearToken(): void {
  window.localStorage.removeItem(KEY);
  cookies.deleteCookie(KEY);
}
