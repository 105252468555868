export const IMPORT_ASSETS = {
  COLUMNS: {
    workflowState: "Workflow State",
    assignedTo: "Assigned To",
    assetsName: "Assets Name",
    thothId: "Thoth Id",
    externalId: "External Id",
    externalPath: "External Path",
    thothPath: "Thoth Path"
  },
  ERRORS: {
    unassignedColumn(columnName: string): string {
      return `Please map <strong class="error">${columnName}</strong> to a column`;
    },
    columnSelectedTwice(columnName: string): string {
      return `The column <strong class="error">${columnName}</strong>  has been assigned to multiple columns, please assign to a single column.`;
    },
    csvFileTypeError: "Please select a csv file.",
    metadataNotFound: "Metadata not found",
    metadataInvalidValue: "Invalid value:",
    userNotFound: "User not found:",
    workflowStateNotFound: "Workflow state not found:",
    assetItNotFound: "Asset not found:",
    duplicateExternalId: "Duplicate external id:",
    invalidProductName: "Invalid product name in path:",
    invalidPath: "Invalid Thoth path to asset:",
    invalidExternalPath: "Invalid External path to asset:",
    externalPathNotSet:
      "Invalid External path to asset: some part of the path are not set",
    mismatchedPaths: "External path is not the same as Thoth path"
  },
  RESOLUTIONS: {
    metadataNotFoundResolution: "Add metadata to asset template.",
    metadataInvalidValueResolution:
      "Add option to metadata or remove from csv.",
    userNotFoundResolution:
      "Ensure user exists and has permission to access product.",
    workflowStateNotFoundResolution:
      "Ensure workflow state exists in template.",
    duplicateExternalIdResolution:
      "If updating an existent asset, ensure both thoth_id and external_id match an existing asset. If adding a new asset, ensure external_id is unique to this product and thoth_id is empty.",
    assetItNotFoundResolution:
      "Ensure id matches an asset in this content template.",
    invalidProductNameResolution: "Update product name in csv",
    invalidExternalPathResolution:
      "Ensure path exists in the product or remove from csv",
    invalidPathResolution:
      "Ensure path exists in the product or remove from csv",
    externalPathNotSetResolution:
      "Ensure all assets in this template have an external id or remove external path from csv",
    mismatchedPathsResolution: "Update external path or Thoth path in csv"
  },
  SUCCESS: "Assets have been successfully imported"
};

export const BULK_EDIT = {
  OPTIONS: {
    workflowState: {
      id: "1",
      name: "Update workflow state"
    }
  },
  ERRORS: {
    errorsOccurred: "Errors occurred when updating",
    loadingMetadata: "error loading assets metadata"
  }
};

export const SECTION = {
  ERRORS: {
    descriptionNotUpdated: "Section description not updated",
    nameNotUpdated: "Section name not updated",
    childNodeContent: "A child node of this content contains an error",
    childNodeReview: "A child node of this content needs review",
    notAdded: "Section is not added",
    nonUniqueExternalId:
      "An external id with that value already exists. External id must be unique.",
    notUpdated: "Section not updated",
    previewContent: "Unable to preview content",
    deployingToQA: "Error deploying to QA"
  },
  SUCCESS: {
    internalPath: "Internal path has been successfully changed"
  }
};

export const MCQ = {
  ERRORS: {
    displaySaveErr_1:
      "There was an error saving your work. This is typically caused by a weak internet connection.",
    displaySaveErr_2: "If the problem persists, you can",
    displaySaveErr_3: "the page to view your saved work."
  }
};

export const TASKS = {
  STATUSES: [
    "complete",
    "current",
    "current late",
    "upcoming",
    "upcoming late"
  ],
  STATUSES_DISPLAY_NAME: {
    current: "In progress",
    complete: "Complete",
    upcoming: "Upcoming",
    def: "not set"
  }
};
