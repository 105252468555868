import { App, Component, ComponentPublicInstance, createApp } from "vue";
import * as microsoftTeams from "@microsoft/teams-js";

import Application from "./App.vue";
import router from "./router";
import editable from "./directives/editable";
import visible from "./directives/visible";
import autofocus from "vue-autofocus-directive";
import { TrackJS } from "trackjs";
import "@/global-scss/global.scss";
import Buefy from "@ntohq/buefy-next/src";
import SVGIcon from "@/components/SVGIcon.vue";
import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";
import { createPinia } from "pinia";

const pinia = createPinia();

const app = createApp(Application as unknown as Component)
  .use(router)
  .use(pinia) as unknown as App;

TrackJS.install({
  token: "b784bd4d4eac4f46a055b4b1771f56a7",
  application: "thoth"
});

app.use(VueTippy);
app.use(Buefy);
app.component("SVGIcon", SVGIcon as unknown as Component);

app.directive("editable", editable);
app.directive("visible", visible);
app.directive("autofocus", autofocus);

app.config.warnHandler = function (
  msg: string,
  vm: ComponentPublicInstance | null,
  trace: string
) {
  console.warn(`${msg}\n${trace}`);
};
app.config.errorHandler = (
  err: unknown,
  vm: ComponentPublicInstance | null,
  info: string
) => {
  if (vm?.$options.propsData) {
    TrackJS.console.log("Props passed to component", vm.$options.propsData);
  }
  let infoMessage = vm ? `Error in component: <${vm.$options.name} />\n` : "";
  if (info) {
    infoMessage += `Error occurred during lifecycle hook: ${info}\n`;
  }
  TrackJS.console.log(infoMessage);
  if ((err as Error).stack) {
    if (window.location.host.includes("localhost")) {
      console.log((err as Error).stack);
    }
    TrackJS.track(`THOTH: ${(err as Error).stack}`);
  } else {
    if (window.location.host.includes("localhost")) {
      console.log(err);
    }
    TrackJS.track(`THOTH: ${err}`);
  }
};

// Initialize the Microsoft Teams SDK
microsoftTeams.initialize();

app.mount("#app");
