<template>
  <tr class="content-files-list__item" data-testing="sb-content-files-list">
    <td class="name">
      <FileListItemIcon :file-location="file.location" />
      <span class="name-text">{{ file.name }}</span>
    </td>
    <td class="size">
      <span v-if="fileSize > 0">{{ fileSize + "kb" }}</span>
    </td>
    <td class="date">{{ dateAdded }}</td>
    <td class="info">
      <SVGIcon
        v-if="isAttachmentTable && isSetAsMediaContent"
        :path="mdiStarOutline"
        title="Set as Media Content"
      />
    </td>
    <td class="open">
      <a
        v-if="isFileLocationRemote"
        :href="file.location"
        target="_blank"
        title="Open attachment in a new tab"
      >
        <SVGIcon :path="mdiOpenInNew" />
      </a>
    </td>
    <td class="actions">
      <b-dropdown position="is-bottom-left" class="file-btn">
        <template #trigger>
          <button class="btn-3" data-testing="sb-open-file-bar">
            <SVGIcon :path="mdiDotsHorizontal" :size="18" />
          </button>
        </template>

        <b-dropdown-item
          v-if="isAttachmentTable"
          aria-role="listitem"
          @click="$emit('previewAttachment', file.location)"
        >
          <SVGIcon :path="mdiPresentationPlay" />
          Preview
        </b-dropdown-item>
        <b-dropdown-item v-if="isFileLocationRemote" aria-role="listitem">
          <a :href="file.location" target="_blank">
            <SVGIcon :path="mdiOpenInNew" />
            Open attachment
          </a>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="isFileLocationLocal"
          aria-role="listitem"
          @click="copyToClipboard(file.location)"
        >
          <SVGIcon :path="mdiContentCopy" />
          Copy path
        </b-dropdown-item>
        <b-dropdown-item
          v-if="showUnsetAsMediaContentOption"
          aria-role="listitem"
          @click="$emit('unsetAsMediaContent')"
        >
          <SVGIcon :path="mdiStarOffOutline" />
          Unset as Media Content
        </b-dropdown-item>
        <b-dropdown-item
          v-if="showSetAsMediaContentOption"
          aria-role="listitem"
          @click="$emit('setAsMediaContent', file.location, file.id)"
        >
          <SVGIcon :path="mdiStarOutline" />
          Set as Media Content
        </b-dropdown-item>
        <b-dropdown-item
          v-if="isFileAvailable"
          aria-role="listitem"
          @click="$emit('launchRenamePopup', file)"
        >
          <SVGIcon :path="mdiPencil" />
          Rename
        </b-dropdown-item>
        <b-dropdown-item v-if="!file.is_external">
          <a
            :href="file.location"
            download
            title="Download this attachment to your computer"
            class="nstyled btn-3 full-width"
          >
            <SVGIcon :path="mdiDownload" />
            Download
          </a>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="isFileAvailable && !file.is_external"
          @click="$emit('openUploadVersionModal', file)"
        >
          <SVGIcon :path="mdiUpload" />
          Upload a new version
        </b-dropdown-item>
        <b-dropdown-item
          v-if="isFileAvailable"
          aria-role="listitem"
          @click="$emit('showFileInLibrary', file)"
        >
          <SVGIcon :path="mdiArrowRight" />
          Open in file library
        </b-dropdown-item>
        <b-dropdown-item
          v-if="isFileAvailable && isAttachedFile"
          aria-role="listitem"
          @click="$emit('launchRemoveAttachmentDialog', file)"
          data-testing="sb-remove-attachment"
        >
          <SVGIcon :path="mdiDelete" />
          Remove attachment
        </b-dropdown-item>
      </b-dropdown>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import { isAValidPathToTheLocalFile } from "@/utils/file-helpers";
import { LibraryFile } from "@/models/files.model";
import FileListItemIcon from "@/components/right-sidebars/content-sidebar/authoring-tool/FileListItemIcon.vue";
import { SectionFile } from "@/models/product-section.model";
import {
  mdiArrowRight,
  mdiContentCopy,
  mdiDelete,
  mdiDotsHorizontal,
  mdiDownload,
  mdiOpenInNew,
  mdiPencil,
  mdiPresentationPlay,
  mdiStarOffOutline,
  mdiStarOutline,
  mdiUpload
} from "@mdi/js";
import { useFilesStore } from "@/stores/files.store";
import { copyToClipboard } from "@/services/copy-to-clipboard";

const filesStore = useFilesStore();

defineEmits([
  "showFileInLibrary",
  "launchRemoveAttachmentDialog",
  "openUploadVersionModal",
  "launchRenamePopup",
  "setAsMediaContent",
  "unsetAsMediaContent",
  "previewAttachment"
]);

const props = defineProps({
  file: {
    type: Object as PropType<SectionFile | LibraryFile>,
    required: true
  },
  hasExternalContentAuthoring: {
    type: Boolean,
    required: true,
    default: false
  },
  isSetAsMediaContent: {
    type: Boolean,
    required: true,
    default: false
  },
  isAttachmentTable: {
    type: Boolean,
    required: true,
    default: true
  }
});

const isAttachedFile = computed<boolean>(() => {
  // This check is needed due to a change in the logic of setting a file as media content
  // Before we could set a file as media content without attaching it to a section
  // The file exists in the file library. Check if the file is attached to the section
  return !!(props.file as SectionFile).file_to_section_id;
});
const isFileAvailable = computed<boolean>(() => {
  // This check is needed due to a change in the logic of setting a file as media content
  // Before we could set a file as media content without uploading it in the file library
  // Check if the file exists in the file library
  return !!props.file.id;
});
const isFileLocationLocal = computed<boolean>(() => {
  return !!props.file && isAValidPathToTheLocalFile(props.file.location);
});
const isFileLocationRemote = computed<boolean>(() => {
  return !!props.file.is_external && !isFileLocationLocal.value;
});

const dateAdded = computed<string>(() => {
  const options = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    timezone: "UTC",
    hour: "2-digit",
    minute: "2-digit"
  };
  // There can be three options:
  // 1. a file is in the library and attached to the section.
  // This file has type RenameThothFile. So, we use property 'file_created_on'
  // 2. a file is not added to the section, but is in the library.
  // This file has type ThothFile. So, we use property 'created_on'
  // 3. a file is not in the library, but it is added as media content
  const fileCreatedOn = (props.file as SectionFile).file_created_on
    ? (props.file as SectionFile).file_created_on
    : (props.file as LibraryFile).created_on
    ? (props.file as LibraryFile).created_on
    : undefined;
  return fileCreatedOn
    ? // @ts-ignore
      new Date(fileCreatedOn).toLocaleString("en", options)
    : "";
});
const fileSize = computed<number>(() => {
  if (filesStore.files) {
    const currentFile = filesStore.files.find((f: LibraryFile): boolean => {
      return f.id === props.file.id;
    });
    if (currentFile) {
      return currentFile.versions[currentFile.versions.length - 1].file_size;
    }
  }
  return 0;
});

const showUnsetAsMediaContentOption = computed<boolean>(() => {
  return (
    (!props.isAttachmentTable && props.hasExternalContentAuthoring) ||
    (props.isAttachmentTable && props.isSetAsMediaContent)
  );
});
const showSetAsMediaContentOption = computed<boolean>(() => {
  return (
    props.isAttachmentTable &&
    props.hasExternalContentAuthoring &&
    !props.isSetAsMediaContent
  );
});
</script>

<style lang="scss" scoped>
.content-files-list__item {
  &:hover {
    background-color: $background-highlight-color;
    .file-btn {
      opacity: 1;
    }
  }
  td {
    &.name {
      width: 40.6%;
      .name-text {
        word-break: break-word;
      }
    }
    &.size,
    &.date {
      font-size: 0.75rem;
      text-transform: lowercase;
      white-space: nowrap;
    }
    &.size {
      width: 15%;
    }
    &.date {
      width: 26.4%;
    }
    &.info,
    &.open {
      width: 5.7%;
    }
    &.actions {
      width: 6.6%;
    }
  }
}
.file-btn {
  cursor: pointer;
}
</style>
<style lang="scss">
td.name {
  .icon-container {
    float: left;
  }
}
</style>
