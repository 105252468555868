import { DAO } from "@/api/DAO";
import {
  USER_URL,
  USER_TO_STATE_URL,
  CHANGE_PASSWORD_URL
} from "@/api/URL-constants";
import { AxiosResponse } from "axios";
import {
  GetAllResponse,
  PasswordToChange,
  UserToAddToState,
  UserToCreateResponse,
  UserToRemoveFromState,
  UserToUpdate
} from "@/models/api/users.model";

const getAll = (): Promise<AxiosResponse<GetAllResponse>> => {
  return DAO().get(USER_URL);
};

const create = (
  username: string,
  password: string,
  firstName: string,
  lastName: string,
  type: number
): Promise<AxiosResponse<UserToCreateResponse>> => {
  const payload = {
    username,
    password,
    firstname: firstName,
    lastname: lastName,
    type
  };
  return DAO().post(USER_URL, payload);
};

const update = (payload: UserToUpdate): Promise<AxiosResponse> => {
  return DAO().put(USER_URL, payload);
};

const disable = (userId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${USER_URL}/${userId}`);
};

const addToState = (payload: UserToAddToState): Promise<AxiosResponse> => {
  return DAO().put(USER_TO_STATE_URL, payload);
};

const removeFromState = (
  payload: UserToRemoveFromState
): Promise<AxiosResponse> => {
  return DAO().delete(
    `${USER_TO_STATE_URL}/${payload.user_id}/${payload.state_id}`
  );
};

const changePassword = (payload: PasswordToChange): Promise<AxiosResponse> => {
  return DAO().put(CHANGE_PASSWORD_URL, payload);
};

export {
  getAll,
  create,
  update,
  disable,
  addToState,
  removeFromState,
  changePassword
};
