import { DAO } from "@/api/DAO";
import { PREVIEW_URL, DOWNLOAD_URL } from "@/api/URL-constants";
import { MCQPayload, MCQResponse } from "@/models/api/mcq.model";
import { AxiosResponse } from "axios";

const preview = (payload: MCQPayload): Promise<AxiosResponse<MCQResponse>> => {
  return DAO().put(PREVIEW_URL, payload);
};

const download = (payload: MCQPayload): Promise<AxiosResponse<MCQResponse>> => {
  return DAO().put(DOWNLOAD_URL, payload);
};

export { preview, download };
