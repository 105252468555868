<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 256 242"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 42.8571H10V190H28V200H10C4.47716 200 0 195.523 0 190V10C0 4.47716 4.47715 0 10 0H190C195.523 0 200 4.47715 200 10V21H107.143V14.2857H21.4286V28.5714H28.2969C28.103 29.3489 28 30.1624 28 31V42.8571Z"
    />
    <path
      d="M28 42.8571H10V190H28V200H10C4.47716 200 0 195.523 0 190V10C0 4.47716 4.47715 0 10 0H190C195.523 0 200 4.47715 200 10V21H107.143V14.2857H21.4286V28.5714H28.2969C28.103 29.3489 28 30.1624 28 31V42.8571Z"
    />
    <path d="M28 71.4286H21.4286V92.8571H28V71.4286Z" />
    <path d="M28 71.4286H21.4286V92.8571H28V71.4286Z" />
    <path d="M28 107.143H21.4286V128.571H28V107.143Z" />
    <path d="M28 107.143H21.4286V128.571H28V107.143Z" />
    <path d="M28 171.429H21.4286V178.571H28V171.429Z" />
    <path d="M28 171.429H21.4286V178.571H28V171.429Z" />
    <path
      d="M56 63.8571H38V211H56V221H38C32.4772 221 28 216.523 28 211V31C28 25.4772 32.4772 21 38 21H218C223.523 21 228 25.4772 228 31V42H135.143V35.2857H49.4286V49.5714H56.2969C56.103 50.3489 56 51.1624 56 52V63.8571Z"
    />
    <path
      d="M56 63.8571H38V211H56V221H38C32.4772 221 28 216.523 28 211V31C28 25.4772 32.4772 21 38 21H218C223.523 21 228 25.4772 228 31V42H135.143V35.2857H49.4286V49.5714H56.2969C56.103 50.3489 56 51.1624 56 52V63.8571Z"
    />
    <path d="M56 92.4286H49.4286V113.857H56V92.4286Z" />
    <path d="M56 92.4286H49.4286V113.857H56V92.4286Z" />
    <path d="M56 128.143H49.4286V149.571H56V128.143Z" />
    <path d="M56 128.143H49.4286V149.571H56V128.143Z" />
    <path d="M56 192.429H49.4286V199.571H56V192.429Z" />
    <path d="M56 192.429H49.4286V199.571H56V192.429Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M246 52H66V232H246V52ZM66 42C60.4772 42 56 46.4772 56 52V232C56 237.523 60.4772 242 66 242H246C251.523 242 256 237.523 256 232V52C256 46.4772 251.523 42 246 42H66Z"
    />
    <path d="M77.4286 113.429H234.571V134.857H77.4286V113.429Z" />
    <path d="M77.4286 149.143H163.143V170.571H77.4286V149.143Z" />
    <path d="M77.4286 149.143H163.143V170.571H77.4286V149.143Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M66 42C60.4772 42 56 46.4772 56 52V84.8571H256V52C256 46.4772 251.523 42 246 42H66ZM163.143 56.2857H77.4286V70.5714H163.143V56.2857Z"
    />
    <path d="M77.4286 213.429H134.571V220.571H77.4286V213.429Z" />
    <path d="M77.4286 213.429H134.571V220.571H77.4286V213.429Z" />
    <path d="M141.714 213.429H198.857V220.571H141.714V213.429Z" />
    <path d="M141.714 213.429H198.857V220.571H141.714V213.429Z" />
  </svg>
</template>
<script setup></script>
<style lang="scss" scoped></style>
