import { DAO } from "@/api/DAO";
import {
  BATCH_URL,
  SECTION_TO_BATCH_URL,
  DEADLINE_URL
} from "@/api/URL-constants";
import { AxiosResponse } from "axios";
import {
  BatchToCreate,
  BatchToUpdate,
  DeadlineToUpdate,
  SectionToAddToBatch
} from "@/models/api/batches.model";

const create = (payload: BatchToCreate): Promise<AxiosResponse> => {
  return DAO().post(BATCH_URL, payload);
};

const update = (payload: BatchToUpdate): Promise<AxiosResponse> => {
  return DAO().put(BATCH_URL, payload);
};

const remove = (batchId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${BATCH_URL}/${batchId}`);
};

const addSectionToBatch = (
  payload: SectionToAddToBatch
): Promise<AxiosResponse> => {
  return DAO().put(SECTION_TO_BATCH_URL, payload);
};

const removeSectionFromBatch = (sectionId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${SECTION_TO_BATCH_URL}/${sectionId}`);
};

const updateDeadline = (payload: DeadlineToUpdate): Promise<AxiosResponse> => {
  return DAO().put(DEADLINE_URL, payload);
};

export {
  create,
  update,
  remove,
  addSectionToBatch,
  removeSectionFromBatch,
  updateDeadline
};
