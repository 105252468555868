<template>
  <RightSidebar
    @closeSidebar="uiFilesStore.closeFileSidebar"
    :is-sidebar-open="!!displayFile"
    :data-testing="'display-file-sidebar'"
    :close-btn-data-testing="'close-file-sb'"
    :is-close-btn-visible="true"
    :is-updating="false"
    :css-classes="['display-file-sidebar']"
  >
    <template v-slot:title>
      <h3
        data-testing="edit-file-name"
        v-editable="uiGeneralStore.isEditModeEnabled"
        @edited="updateName"
        v-text="displayFile.name"
      ></h3>
    </template>
    <template v-slot:body>
      <b-dropdown
        class="is-block sidebar-dropdown-menu"
        aria-role="menu"
        position="is-bottom-left"
        hoverable
        :mobile-modal="false"
        v-if="uiGeneralStore.isEditModeEnabled && canRemoveFile"
      >
        <template #trigger>
          <button
            class="btn-3 right-icon dropdown-btn"
            data-testing="file-menu"
          >
            <SVGIcon :path="mdiDotsHorizontal" :size="24" />
          </button>
        </template>
        <span>
          <b-dropdown-item
            aria-role="menu-item"
            :focusable="false"
            :custom="true"
            ><button
              class="btn-unstyled btn-3 full-width"
              type="button"
              data-testing="delete-file-btn"
              @click="confirmRemoval"
            >
              <SVGIcon :path="mdiDelete" />
              Delete Attachment
            </button>
          </b-dropdown-item>
        </span>
      </b-dropdown>

      <div
        class="margin-b-half"
        data-testing="file-path-sb"
        v-if="displayFile.is_external"
      >
        <RightSidebarTitle :title="fileLocationTitle + ':'" />
        <a
          v-if="!fileLocationIsLocal"
          class="location-link"
          data-testing="location-link"
          :href="displayFile.location"
          target="_blank"
        >
          {{ displayFile.location }}
        </a>
        <a
          v-if="fileLocationIsLocal"
          title="Copy path"
          @click="copyToClipboard(displayFile.location)"
        >
          <SVGIcon :path="mdiContentCopy" />
          {{ displayFile.location }}
        </a>
      </div>
      <div class="margin-b-half" data-testing="files-attached-sb">
        <RightSidebarTitle :title="'Attached to:'" />
        <ul class="pl-0">
          <li v-if="attachments.length === 0"> Not attached to any content </li>
          <li
            v-for="attachment in attachments"
            :key="attachment.index"
            data-testing="attached-to"
          >
            {{ attachment.name }}
          </li>
        </ul>
      </div>
      <div class="margin-b-half" v-if="!displayFile.is_external">
        <RightSidebarTitle :title="'Versions:'" />
        <ul class="versions margin-b-half" data-testing="versions-container">
          <li
            class="version"
            data-testing="version"
            v-for="version in versions"
            :key="version.id"
          >
            <small class="date" data-testing="blackout"
              >{{ version.displayDateTime }}
            </small>
            <small v-if="version.userDisplayName">
              {{ version.userDisplayName }}:
            </small>
            <a :href="version.location" target="_blank">view</a>
          </li>
        </ul>
      </div>
      <div class="margin-b-half" v-if="!displayFile.is_external">
        <RightSidebarTitle :title="'Upload a new Version:'" />
        <form @submit.prevent="uploadNewVersion">
          <input
            class="margin-b-quarter"
            type="file"
            ref="newVersionInput"
            @change="prepareNewVersion"
            :disabled="filesStore.areFilesUpdating"
          />
          <button
            class="btn-1 full-width"
            ref="uploadNewVersionBtn"
            v-if="newVersion"
            @click="uploadNewVersion"
          >
            Submit
          </button>
        </form>
      </div>
    </template>
  </RightSidebar>
</template>

<script lang="ts" setup>
import { isAValidPathToTheLocalFile } from "@/utils/file-helpers";
import RightSidebar from "@/components/right-sidebars/ui/RightSidebar.vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import {
  LibraryFile,
  LibraryFileProduct,
  LibraryFileSection,
  LibraryFileVersion
} from "@/models/files.model";
import { OrganizationUser } from "@/models/user.model";
import { ProductItem, ProductPermission } from "@/models/product.model";
import { computed, onBeforeUnmount, ref } from "vue";
import { formatFine } from "@/utils/formatTimestamp";
import { mdiContentCopy, mdiDelete, mdiDotsHorizontal } from "@mdi/js";
import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useUsersStore } from "@/stores/users.store";
import { useProductsStore } from "@/stores/products.store";
import { useFilesStore } from "@/stores/files.store";
import { useFileManager } from "@/composables/files-manager";
import { showDialog } from "@/services/notification-manager";
import { copyToClipboard } from "@/services/copy-to-clipboard";
import { PRODUCT } from "@/constants/product.constants";

interface Version {
  displayDateTime: string;
  userDisplayName: string;
  location: string;
}
interface Attachment {
  name: string;
  index: number;
}

const uiGeneralStore = useUIGeneralStore();
const uiFilesStore = useUIFilesStore();
const usersStore = useUsersStore();
const productsStore = useProductsStore();
const filesStore = useFilesStore();

const displayFile = computed<LibraryFile | undefined>(() => {
  if (filesStore.files) {
    return filesStore.files.find((e: LibraryFile) => {
      return e.id === uiFilesStore.fileForDisplay;
    });
  }
  return undefined;
});
const fileLocationIsLocal = computed<boolean>(() => {
  return displayFile.value
    ? isAValidPathToTheLocalFile(displayFile.value.location)
    : false;
});
const fileLocationTitle = computed<string>(() => {
  return fileLocationIsLocal.value ? "Path" : "Location";
});

const section = computed<LibraryFileSection | undefined>(() => {
  if (!displayFile.value) {
    return undefined;
  }
  return displayFile.value.sections.find((e: LibraryFileSection) => {
    return e.file_id === uiFilesStore.fileForDisplay;
  });
});
const productIds = computed<number[]>(() => {
  let ids: Set<number> = new Set([]);
  if (displayFile.value) {
    const products = displayFile.value.products.map(
      (product: LibraryFileProduct) => {
        return product.product_id;
      }
    );
    const productsFromSections = displayFile.value.sections.map(
      (section: LibraryFileSection) => {
        return section.product_id;
      }
    );
    ids = new Set([...products, ...productsFromSections]);
  }
  return Array.from(ids);
});

const getProductName = (productId: number): string => {
  const product = productsStore.productDetails?.find((e: ProductItem) => {
    return e.id === productId;
  });
  return product ? product.name : "";
};
const attachments = computed<Attachment[]>(() => {
  if (!displayFile.value) {
    return [];
  }
  const sections = displayFile.value.sections
    .map((section: LibraryFileSection) => {
      const productName = getProductName(section.product_id);
      return {
        name: productName ? `${productName}/${section.name}` : "",
        index: 0
      };
    })
    .filter((section: { name: string }) => {
      return section.name !== "";
    });
  const products = displayFile.value.products
    .map((product: LibraryFileProduct) => {
      return {
        name: getProductName(product.product_id) || "",
        index: 0
      };
    })
    .filter((product: { name: string }) => {
      return product.name !== "";
    });
  const attachments: Attachment[] = sections.concat(products);
  attachments.forEach((attachment: Attachment, index: number) => {
    attachment.index = index;
  });
  return attachments;
});

const getUserDisplayName = (userId: number): string => {
  const user = usersStore.users?.find(
    (e: OrganizationUser): boolean => e.id == userId
  );
  return user ? user.displayName : "";
};
const versions = computed<Version[]>(() => {
  if (!displayFile.value) {
    return [];
  }
  const file = displayFile.value;
  const versionsWithFileUpdate: LibraryFileVersion[] = file.versions.filter(
    (v: LibraryFileVersion, i: number, a: LibraryFileVersion[]) => {
      if (i === 0) {
        return true;
      }
      return a[i].location !== a[i - 1].location;
    }
  );

  return versionsWithFileUpdate.map((version: LibraryFileVersion) => {
    return {
      displayDateTime: formatFine(version.created_on),
      userDisplayName: getUserDisplayName(version.created_by),
      location: version.location
    };
  });
});

const canRemoveFile = computed<boolean>(() => {
  if (productsStore.productDetails) {
    const products = productsStore.productDetails.filter(
      (product: ProductItem) => {
        return productIds.value.some((prodId: number) => {
          return prodId === product.id;
        });
      }
    );
    return products.every((product: ProductItem) => {
      return product.allocated_permissions.some((perm: ProductPermission) => {
        return perm.id === PRODUCT.PERMISSIONS.editContentStructure;
      });
    });
  }
  return false;
});

const { renameAttachment, uploadFileVersion, removeFile } = useFileManager();
const updateName = (event: CustomEvent<{ textContent: string }>): void => {
  if (
    displayFile.value &&
    displayFile.value.name !== event.detail.textContent
  ) {
    renameAttachment(
      {
        id: displayFile.value.id,
        name: event.detail.textContent
      },
      displayFile.value
    );
  }
};

let timeoutId = ref<number | null>(null);
const clearExistingTimeout = () => {
  if (timeoutId.value !== null) {
    clearTimeout(timeoutId.value);
    timeoutId.value = null;
  }
};
onBeforeUnmount(() => {
  clearExistingTimeout();
});
const newVersion = ref<boolean>(false);
const fileToUpload = ref<File | undefined>(undefined);
const newVersionInput = ref<HTMLInputElement | null>(null);
const uploadNewVersionBtn = ref<HTMLButtonElement | null>(null);
const prepareNewVersion = (): void => {
  newVersion.value = true;
  if (newVersionInput.value && newVersionInput.value.files) {
    fileToUpload.value = newVersionInput.value.files[0];
    timeoutId.value = window.setTimeout(() => {
      if (uploadNewVersionBtn.value) {
        uploadNewVersionBtn.value.focus();
      }
    }, 100);
  }
};
const uploadNewVersion = (): void => {
  newVersion.value = false;
  if (
    displayFile.value &&
    !displayFile.value.is_external &&
    fileToUpload.value
  ) {
    const formData = new FormData();
    formData.append("file", fileToUpload.value);
    formData.append("id", `${uiFilesStore.fileForDisplay}`);
    if (section.value) {
      uploadFileVersion({
        formData: formData,
        productId: section.value.product_id
      });
    } else {
      uploadFileVersion({
        formData: formData
      });
    }
    if (newVersionInput.value) {
      newVersionInput.value.value = "";
    }
  } else {
    console.log("update path for external file");
  }
};

const confirmRemoval = (): void => {
  showDialog({
    message: "Are you sure you want to continue?",
    cancelText: "No",
    confirmText: "Yes",
    onConfirm: () => {
      if (displayFile.value) {
        removeFile(displayFile.value).then(() => {
          uiFilesStore.closeFileSidebar();
        });
      }
    }
  });
};
</script>

<style lang="scss" scoped>
span.editable {
  height: 23px;
  width: 100%;
  line-height: 23px;
  display: inline-block;
}
select.editable {
  padding-top: 0;
  padding-bottom: 0;
}
input.editable {
  padding-top: 0;
  padding-bottom: 0;
}
.versions {
  padding-left: 0;
}
.location-link {
  overflow-wrap: break-word;
}
</style>
