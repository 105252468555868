<template>
  <RightSidebar
    @closeSidebar="uiContentStore.closeNewSectionSidebar"
    :is-sidebar-open="!!uiContentStore.newSectionDetails"
    :close-btn-data-testing="'close-curriculum-sidebar'"
    :is-updating="false"
    :css-classes="['curriculum-sidebar']"
    :is-close-btn-visible="true"
    :data-testing="'curriculum-sidebar'"
  >
    <template v-slot:title>
      <input
        id="add-cs-name"
        class="ghost-input"
        ref="sectionNameInput"
        aria-label="Enter name"
        v-model.trim="newSectionName"
        data-testing="add-content-section"
        placeholder="Enter name ..."
      />
    </template>
    <template v-slot:subtitle>
      <div class="info-cards">
        <span class="inline-info"> {{ type.name }} </span>
      </div>
    </template>
    <template v-slot:body>
      <div class="margin-b-standard">
        <h3 class="inline-block">Location:&nbsp;</h3>
        <span>{{ product.name }}</span>
        <span v-for="section in path" :key="section"> / {{ section }} </span>
      </div>
      <div class="margin-b-standard">
        <h3>Instructions</h3>
        <textarea
          data-testing="add-instruction"
          v-model="instruction"
        ></textarea>
      </div>
      <div>
        <button
          class="full-width btn-1 create-cs"
          type="button"
          :disabled="!newSectionName || isSubmitting"
          data-testing="add-content-section-btn"
          @click="addContentSection"
        >
          {{ "Add " + type.name }}
        </button>
      </div>
    </template>
  </RightSidebar>
</template>

<script lang="ts" setup>
import RightSidebar from "@/components/right-sidebars/ui/RightSidebar.vue";
import csHelper from "@/utils/product/content-structure-helpers";
import { ContentTemplate } from "@/models/content-templates.model";
import { computed, nextTick, onMounted, ref } from "vue";
import { useUIContentStore } from "@/stores/ui-content.store";
import { useContentStore } from "@/stores/content.store";
import { useProductsManager } from "@/composables/products-manager";
import { useProductFeaturesManager } from "@/composables/product-features-manager";
import { useFeatureFlags } from "@/composables/feature-flags";
import { showToast } from "@/services/notification-manager";
import { SECTION } from "@/constants/assets.constant";

const uiContentStore = useUIContentStore();
const contentStore = useContentStore();
const { product, structure } = useProductsManager();
const { isWorkflowManagementFeatureEnabled } = useProductFeaturesManager();
const { ffShouldTaskNotificationsBeSent } = useFeatureFlags();

const NEW_CONTENT_NAME = "Placeholder";

const newSectionName = ref<string>(NEW_CONTENT_NAME);
const instruction = ref<string>("");
const isSubmitting = ref<boolean>(false);

const path = computed<string[]>(() => {
  if (
    structure.value &&
    structure.value.rootSection &&
    uiContentStore.newSectionDetails
  ) {
    const base =
      csHelper.getPathToSection(
        structure.value.rootSection,
        uiContentStore.newSectionDetails.parentId
      ) || [];
    const parent = csHelper.findDescendentSection(
      structure.value.rootSection,
      uiContentStore.newSectionDetails.parentId
    );
    return parent && parent.isRoot
      ? base
      : base.concat(parent ? parent.name : "");
  }
  return [];
});
const type = computed<ContentTemplate>(() => {
  return (
    product.value?.component_definitions.find((e: ContentTemplate): boolean => {
      return (
        !!uiContentStore.newSectionDetails &&
        e.id === uiContentStore.newSectionDetails.componentId
      );
    }) || ({} as ContentTemplate)
  );
});

const addContentSection = (): void => {
  if (uiContentStore.newSectionDetails) {
    isSubmitting.value = true;
    contentStore
      .upsertSections({
        productIds: [uiContentStore.newSectionDetails.productId],
        sections: [
          {
            type: uiContentStore.newSectionDetails.componentId,
            name: newSectionName.value,
            parent_section_id: uiContentStore.newSectionDetails.parentId,
            instruction: instruction.value ? instruction.value : undefined,
            users_to_notify:
              ffShouldTaskNotificationsBeSent.value &&
              isWorkflowManagementFeatureEnabled.value &&
              type.value.workflow_states.length > 0
                ? type.value.workflow_states[0].users
                : []
          }
        ]
      })
      .catch((error): void => {
        showToast({
          message: `${SECTION.ERRORS.notAdded}: ${error.message}`,
          type: "is-danger"
        });
      })
      .then((): void => {
        isSubmitting.value = false;
        newSectionName.value = NEW_CONTENT_NAME;
        instruction.value = "";
        uiContentStore.closeNewSectionSidebar();
      });
  }
};

const sectionNameInput = ref<HTMLInputElement | null>(null);
onMounted(async () => {
  await nextTick(() => {
    if (sectionNameInput.value) {
      sectionNameInput.value.focus();
    }
  });
});
uiContentStore.$subscribe((mutation, state) => {
  const newVal = state.newSectionDetails;
  nextTick(() => {
    if (newVal && sectionNameInput.value) {
      sectionNameInput.value.focus();
    }
  });
});
</script>

<style lang="scss" scoped></style>
