export default function QuestionValidator(questionString, variables) {
  var that = {
    getErrors: getErrors,
    getSuggestions: getSuggestions,
    getVariablesMissingValues: getVariablesMissingValues,
    hasAnswerInputElement: hasAnswerInputElement,
    hasAnyError: hasAnyError,
    hasAnySuggestions: hasAnySuggestions,
    hasCorrectAnswerBeenEntered: hasCorrectAnswerBeenEntered,
    hasDifficultyValue: hasDifficultyValue,
    hasNoFontoComment: hasNoFontoComment,
    hasHintText: hasHintText,
    hasQuestionText: hasQuestionText,
    hasSolutionText: hasSolutionText,
    hasVariables: hasVariables,
    hasNoMissingVariables: hasNoMissingVariables,
    hasMissingVariables: hasMissingVariables
  };

  var errors = {
    answerField: hasAnswerInputElement,
    answerValue: hasCorrectAnswerBeenEntered,
    difficulty: hasDifficultyValue,
    question: hasQuestionText,
    solution: hasSolutionText,
    variableValues: hasNoMissingVariables
  };

  var suggestions = {
    //hint: hasHintText,

    comment: hasNoFontoComment
    //variablised: hasVariables
  };

  // Common Regular Expressions
  var placeholderRegEx = /<\?fontoxml.*placeholder.+\?>/,
    questionBodyRegEx = /<item(?:Interaction)?Body>((?:\s|.)*?)<\/item(?:Interaction)?Body>/,
    variableRegEx = /\${2}(\w)/g;

  // Common matches
  var questionBody = questionBodyRegEx.exec(questionString)[1];

  function getErrors() {
    var thrownErrors = [];
    for (var error in errors) {
      if (!errors[error]()) {
        thrownErrors.push(error);
      }
    }
    return thrownErrors;
  }

  function getSuggestions() {
    var thrownSuggestions = [];
    for (var suggestion in suggestions) {
      if (!suggestions[suggestion]()) {
        thrownSuggestions.push(suggestion);
      }
    }
    return thrownSuggestions;
  }

  function getVariablesInQuestion() {
    var variablesArray = [],
      results;

    while ((results = variableRegEx.exec(questionString))) {
      if (!variablesArray.includes(results[1])) {
        variablesArray.push(results[1]);
      }
    }

    return variablesArray;
  }

  function getVariablesMissingValues() {
    var questionVariables = getVariablesInQuestion();
    if (!questionVariables.length) {
      return [];
    } else if (!variables || !variables.values || !variables.values.length) {
      return questionVariables;
    } else {
      return questionVariables.filter(function (variable) {
        return !variables.values.every(function (values) {
          return values["$$" + variable];
        });
      });
    }
  }

  function hasAnswerInputElement() {
    var checkAnswerFieldQuestionText = /<\w+(?:Interaction|Choice)[^>]*>/;
    return checkAnswerFieldQuestionText.test(questionBody);
  }

  function hasAnyError() {
    return !!getErrors().length;
  }

  function hasAnySuggestions() {
    return !!getSuggestions().length;
  }

  function hasCorrectAnswerBeenEntered() {
    var responseDeclarationRegEx = /<responseDeclaration[^>]*identifier="([-a-f\d]{36})"[^>]*>((?:.|\n)+)<\/responseDeclaration>/g;
    var textResults, //1: identifier, 2: body
      mcqResults; //1: body
    var simpleChoiceRegEx = /<simpleChoice[^>]*?>([^<]*)<\/simpleChoice>/g;

    if (/<(?:inlineC|c)hoiceInteraction/.test(questionString)) {
      while ((mcqResults = simpleChoiceRegEx.exec(questionString))) {
        if (!mcqResults[1] || placeholderRegEx.test(mcqResults[1])) {
          return false;
        }
      }
    } else {
      while ((textResults = responseDeclarationRegEx.exec(questionString))) {
        var checkParentedRegEx = new RegExp(
            '<\\w+(?:Choice|Interaction)[^>]*responseIdentifier="' +
              textResults[1] +
              '"[^>]*>'
          ),
          answerValueRegEx = /<value>[^<]+<\/value>/;

        if (checkParentedRegEx.test(questionBody)) {
          if (
            !answerValueRegEx.test(textResults[2]) ||
            placeholderRegEx.test(textResults[2])
          ) {
            return false;
          }
        }
      }
    }

    return true;
  }

  function hasDifficultyValue() {
    var difficultyRegEx = /<difficulty>((?:.|\n)+)<\/difficulty>/,
      difficultyValueRegEx = /easy|medium|hard|[0-3]{2}/i;
    var results = difficultyRegEx.exec(questionString);

    return (
      results &&
      !placeholderRegEx.test(results[1]) &&
      difficultyValueRegEx.test(results[1])
    );
  }

  function hasNoFontoComment() {
    var checkFontoCommentRegEx = /<\?fontoxml-annotation-(start|end)/;
    return !questionString.match(checkFontoCommentRegEx);
  }

  function hasHintText() {
    var checkHintTextRegEx = /identifier="HINT">(.|\n)+<\/p>/;
    var results = checkHintTextRegEx.exec(questionString);
    return results && !placeholderRegEx.test(results[1]);
  }

  function hasQuestionText() {
    var questionTextRegEx = /<p>(?:.|\n)+<\/p>/;
    return (
      !placeholderRegEx.test(questionBody) &&
      questionTextRegEx.test(questionBody)
    );
  }

  function hasSolutionText() {
    var solutionTextRegEx = /identifier="SOLUTION">(.|\n)+<\/p>/;
    var solutionText = solutionTextRegEx.exec(questionString)[0];
    return !placeholderRegEx.test(solutionText);
  }

  function hasVariables() {
    return /\${2}(\w)/.test(questionString);
  }

  function hasNoMissingVariables() {
    return getVariablesMissingValues().length < 1;
  }

  function hasMissingVariables() {
    return getVariablesMissingValues().length >= 1;
  }

  return that;
}
