<template>
  <div class="margin-b-standard">
    <RightSidebarTitle :title="'Question Authoring'" />

    <div v-if="isStartMessageVisible" class="start-message">
      <p
        >Click the <strong>Open editor</strong> button to start authoring
        question.</p
      >
    </div>
    <div class="tools-buttons">
      <!--Editor button-->
      <span
        v-if="!isContentAuthoringTier2FeatureEnabled"
        class="btn-3 disabled margin-r-standard"
      >
        <SVGIcon :path="mdiPencil" :size="16" />
        Open editor
      </span>
      <router-link
        v-else
        class="btn-3 margin-r-standard"
        :to="{
          name: 'question-editor',
          params: {
            productId: uiContentStore.sectionToDisplay.productId,
            sectionId: uiContentStore.sectionToDisplay.sectionId
          }
        }"
      >
        <SVGIcon :path="mdiPencil" :size="16" />
        Open editor
      </router-link>
    </div>
  </div>
</template>
<script setup lang="ts">
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import { computed, PropType } from "vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { mdiPencil } from "@mdi/js";
import { useUIContentStore } from "@/stores/ui-content.store";

const uiContentStore = useUIContentStore();

const props = defineProps({
  section: {
    type: Object as PropType<ContentStructureSection>,
    required: true
  },
  isContentAuthoringTier2FeatureEnabled: {
    required: true,
    type: Boolean
  }
});
const isStartMessageVisible = computed<boolean>(() => {
  return !!props.section && !props.section.document;
});
</script>

<style scoped lang="scss">
.start-message {
  p {
    padding-bottom: 0;
    font-size: 0.875rem;
  }
}
.tools-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
  padding: 0;
}
</style>
