import {
  mdiAlertCircleOutline,
  mdiCheckCircleOutline,
  mdiInformationOutline,
  mdiHelpCircleOutline
} from "@mdi/js";

const genIconHtml = (icon: string, color: string) => {
  return `<svg width="36" height="36" viewBox="0 0 24 24">
              <path d="${icon}" fill="${color}" />
            </svg>`;
};

export const getIcon = (type: string): string => {
  if (type === "is-success") {
    return genIconHtml(mdiCheckCircleOutline, "#36816f");
  } else if (type === "is-danger") {
    return genIconHtml(mdiAlertCircleOutline, "#e44e4e");
  } else if (type === "is-warning") {
    return genIconHtml(mdiAlertCircleOutline, "#ffa500");
  } else if (type === "is-question") {
    return genIconHtml(mdiHelpCircleOutline, "#508ebd");
  }
  return genIconHtml(mdiInformationOutline, "#2e536e");
};
