import { DAO } from "@/api/DAO";
import { CURRICULUM_DEPLOY_URL, CURRICULUM_URL } from "@/api/URL-constants";
import { AxiosResponse } from "axios";
import {
  CurriculumToDeploy,
  CurriculumToDeployResponse,
  CurriculumToUpdate,
  GetCurriculumResponse,
  GetCurriculumsResponse,
  UpdatedCurriculumResponse
} from "@/models/api/curriculums.model";
import { Curriculum } from "@/models/curriculum.model";
import { CURRICULUM } from "@/constants/curriculum.constants";

const getAll = (): Promise<Curriculum[]> => {
  return DAO()
    .get(CURRICULUM_URL)
    .then(function (response: AxiosResponse<GetCurriculumsResponse>) {
      if (response.status === 200) {
        return response.data.data;
      } else if (response.status === 204) {
        return [];
      } else {
        throw new Error(CURRICULUM.ERRORS.loadingCurriculum);
      }
    });
};

const cacheCurriculums: Record<number, Curriculum> = {};
const get = async (curriculumId: number): Promise<Curriculum> => {
  if (cacheCurriculums[curriculumId]) {
    return cacheCurriculums[curriculumId];
  }
  return DAO()
    .get(`${CURRICULUM_URL}/${curriculumId}`)
    .then((response: AxiosResponse<GetCurriculumResponse>) => {
      if (response.status === 200) {
        cacheCurriculums[curriculumId] = response.data.data;
        return cacheCurriculums[curriculumId];
      } else {
        throw new Error(CURRICULUM.ERRORS.loadingCurriculum);
      }
    });
};

const populate = async (curriculumId: number): Promise<void> => {
  return DAO()
    .get(`${CURRICULUM_URL}/${curriculumId}`)
    .then((response: AxiosResponse<GetCurriculumResponse>) => {
      if (response.status === 200) {
        cacheCurriculums[curriculumId] = response.data.data;
      } else {
        throw new Error(CURRICULUM.ERRORS.loadingCurriculum);
      }
    });
};

const update = (
  payload: CurriculumToUpdate
): Promise<AxiosResponse<UpdatedCurriculumResponse>> => {
  return DAO().put(CURRICULUM_URL, payload);
};

const upload = (payload: CurriculumToUpdate): Promise<AxiosResponse> => {
  // uploading a new curriculum file
  return DAO().post(CURRICULUM_URL, payload.formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const remove = (curriculumId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${CURRICULUM_URL}/${curriculumId}`);
};

const deploy = (
  payload: CurriculumToDeploy
): Promise<AxiosResponse<CurriculumToDeployResponse>> => {
  return DAO().post(CURRICULUM_DEPLOY_URL, payload);
};

export { getAll, get, populate, update, remove, deploy, upload };
