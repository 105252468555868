// @ts-nocheck
import { TrackJS } from "trackjs";
interface AuthHeader {
  Authorization: string;
}
interface AuthError {
  headers: AuthHeader;
  config?: {
    headers: AuthHeader;
  };
  response?: {
    headers: AuthHeader;
    config: {
      headers: AuthHeader;
    };
  };
}
const removeAuthorizationHeader = (headers: AuthHeader): void => {
  if (headers.hasOwnProperty("Authorization")) {
    delete headers.Authorization;
  }
};

const getErrorInfo = (error: AuthError): AuthError => {
  const errorCopy = { ...error };
  if (
    errorCopy.config &&
    errorCopy.config.headers.hasOwnProperty("Authorization")
  ) {
    removeAuthorizationHeader(errorCopy.config.headers);
  }
  if (errorCopy.response) {
    removeAuthorizationHeader(errorCopy.response.headers);
    removeAuthorizationHeader(errorCopy.response.config.headers);
    return errorCopy.response;
  }
  return errorCopy.config ? errorCopy.config : errorCopy;
};

export default (error: AuthError): Promise<AuthError> => {
  const errorInfo = getErrorInfo(error);
  TrackJS.console.log(errorInfo);
  return Promise.reject(error);
};
