import { ProductBatch, ProductItem } from "@/models/product.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import {
  Attachment,
  LastUpdate,
  StatusWithIcon,
  TaskDisplay,
  TaskDisplayData,
  TaskStatus
} from "@/models/task.model";
import csHelper from "@/utils/product/content-structure-helpers";
import { WorkflowState } from "@/models/workflow.model";
import { ProductSection, SectionFile } from "@/models/product-section.model";
import { OrganizationUser } from "@/models/user.model";
import { BatchDeadline } from "@/models/content-structure-batch.model";
import { isAValidPathToTheLocalFile } from "@/utils/file-helpers";
import {
  mdiPresentationPlay,
  mdiDockRight,
  mdiOpenInNew,
  mdiCheckboxMarkedCircleOutline,
  mdiCircle,
  mdiCircleOutline
} from "@mdi/js";

export const addItemForEachWorkflowState = (
  product: ProductItem,
  section: ContentStructureSection,
  arrWorkflows: TaskDisplayData[] = []
): TaskDisplayData[] => {
  const workflowStates = section.workflow_id
    ? csHelper.getWorkflowStatesForSection(product, section.workflow_id)
    : [];

  if (!workflowStates.length) {
    return arrWorkflows;
  }

  for (let i = 0; i < workflowStates.length; i++) {
    arrWorkflows.push(new TaskDisplayData(workflowStates[i], section));
  }
  return arrWorkflows;
};
export const generateTaskForTable = (
  task: TaskDisplayData,
  product: ProductItem,
  users: OrganizationUser[]
): TaskDisplay => {
  const { section, workflowState } = task;

  const assignedUsersArray = getAssignedUsersList(workflowState, users);
  const assignedUsers = getAssignedUsersTemplate(workflowState, users);
  const taskStatus = getTaskStatus(section, workflowState, product);

  return {
    name: generateTaskName(task, product),
    nameString: generateTaskNameAsString(task, product),
    id: section.id,
    batch: "",
    deadline: getDeadlineByWorkflowState(section, workflowState, product),
    statusWithIcon: getStatusWithIcon(section, workflowState, product),
    statusString: taskStatus,
    typeIcon: {
      icon: {
        size: 16,
        name: section.type.name,
        iconId: section.type.icon_id
      }
    },
    typeName: section.type.name,
    key: `${workflowState.id}-${Math.floor(Math.random() * Date.now())}`,
    responsible: assignedUsers || "<i>Unassigned</i>",
    responsibleString: assignedUsers || "Unassigned",
    assignedUsersList: assignedUsersArray,
    product: product,
    productName: product ? product.name : "",
    isComplete: taskStatus === TaskStatus.COMPLETE,
    workflowStateId: getWorkflowStateId(task, product),
    attachments: getAttachmentsLink(section.enabled_files),
    attachmentsString: getAttachmentsString(section.enabled_files),
    files: section.enabled_files
  };
};
export const getAttachmentsLink = (files: SectionFile[]): Attachment => {
  if (!files.length) {
    return {
      icon: {
        path: ""
      },
      html: ""
    };
  } else if (files.length === 1) {
    if (
      !!files[0].is_external &&
      !isAValidPathToTheLocalFile(files[0].location)
    ) {
      return {
        icon: {
          path: mdiOpenInNew
        },
        html: "Open attachment"
      };
    } else if (
      !files[0].is_external ||
      (!!files[0].is_external && isAValidPathToTheLocalFile(files[0].location))
    ) {
      return {
        icon: {
          path: mdiPresentationPlay
        },
        html: "Preview attachment"
      };
    }
    return {
      icon: {
        path: mdiDockRight
      },
      html: "Open attachments"
    };
  } else {
    return {
      icon: {
        path: mdiDockRight
      },
      html: "Open attachments"
    };
  }
};
export const getAttachmentsString = (files: SectionFile[]): string => {
  return getAttachmentsLink(files).html;
};
export const getStatusWithIcon = (
  section: ContentStructureSection,
  workflowState: WorkflowState,
  product: ProductItem
): StatusWithIcon => {
  const status = getTaskStatus(section, workflowState, product);

  switch (status) {
    case TaskStatus.FINAL_STATE:
      return {} as StatusWithIcon;
    case TaskStatus.COMPLETE:
      return {
        icon: {
          path: mdiCheckboxMarkedCircleOutline,
          type: "is-success",
          title: "complete",
          dataTesting: "complete",
          size: 24
        },
        html: "<span>complete</span>"
      };
    case TaskStatus.CURRENT:
      return {
        icon: {
          path: mdiCircle,
          type: "is-warning",
          title: "on track",
          dataTesting: "on-track",
          size: 24
        },
        html: "<span>current</span>"
      };
    case TaskStatus.CURRENT_LATE:
      return {
        icon: {
          path: mdiCircle,
          type: "is-warning",
          title: "on track",
          dataTesting: "on-track",
          size: 24
        },
        html: "<span>current</span> <small style='color: #ff3860'>(late)</small>"
      };
    case TaskStatus.UPCOMING:
      return {
        icon: {
          path: mdiCircleOutline,
          type: "is-dark",
          title: "on track",
          dataTesting: "on-track",
          size: 24
        },
        html: "<span>upcoming</span>"
      };
    case TaskStatus.UPCOMING_LATE:
      return {
        icon: {
          path: mdiCircleOutline,
          type: "is-dark",
          title: "on track",
          dataTesting: "on-track",
          size: 24
        },
        html: "<span>upcoming</span> <small style='color: #ff3860'>(late)</small>"
      };
  }
};
export const getTaskStatus = (
  section: ContentStructureSection,
  workflowState: WorkflowState,
  product: ProductItem
): TaskStatus => {
  const activeTaskInSection: WorkflowState | undefined = section.workflow_id
    ? csHelper.getCurrentState(
        product,
        section.state_transitions,
        section.workflow_id
      )
    : undefined;
  const deadlineForThisWorkflow: number | undefined =
    getDeadlineByWorkflowState(section, workflowState, product);

  if (
    workflowState.isFinalState &&
    activeTaskInSection &&
    !activeTaskInSection.isFinalState
  ) {
    return TaskStatus.FINAL_STATE;
  }

  if (
    activeTaskInSection &&
    (activeTaskInSection.isFinalState ||
      (workflowState.rank &&
        activeTaskInSection.rank &&
        workflowState.rank < activeTaskInSection.rank))
  ) {
    return TaskStatus.COMPLETE;
  }

  if (
    deadlineForThisWorkflow &&
    activeTaskInSection &&
    deadlineForThisWorkflow < Date.now()
  ) {
    return workflowState.rank === activeTaskInSection.rank
      ? TaskStatus.CURRENT_LATE
      : TaskStatus.UPCOMING_LATE;
  }

  return activeTaskInSection && workflowState.rank === activeTaskInSection.rank
    ? TaskStatus.CURRENT
    : TaskStatus.UPCOMING;
};
export const getLastUpdate = (
  section: ProductSection,
  users: OrganizationUser[]
): LastUpdate => {
  const mostRecentTransition = csHelper.getMostRecentStateTransition(
    section.state_transitions
  );
  const user = users.find(
    (e: OrganizationUser): boolean =>
      e.id ===
      (mostRecentTransition ? mostRecentTransition.actor : section.created_by)
  );
  const userDisplayName: string = user ? user.displayName : "";
  return {
    date: mostRecentTransition
      ? mostRecentTransition.created_on_millis
      : section.created_on_millis,
    userDisplayName: userDisplayName
  };
};
export const getWorkflowStates = (
  task: TaskDisplayData,
  product: ProductItem
): WorkflowState[] => {
  return task.section.workflow_id
    ? csHelper.getWorkflowStatesForSection(product, task.section.workflow_id)
    : [];
};
export const getWorkflowState = (
  task: TaskDisplayData,
  workflowStates: WorkflowState[]
): WorkflowState | undefined => {
  return workflowStates.find(
    (e: WorkflowState) => e.id === task.workflowState.id
  );
};
export const getWorkflowStateId = (
  task: TaskDisplayData,
  product: ProductItem
): number | undefined => {
  const workflowStates: WorkflowState[] = getWorkflowStates(task, product);
  const state: WorkflowState | undefined = getWorkflowState(
    task,
    workflowStates
  );
  return state ? state.id : undefined;
};
export const generateTaskName = (
  task: TaskDisplayData,
  product: ProductItem
): { html: string } => {
  const workflowStates: WorkflowState[] = getWorkflowStates(task, product);
  const state: WorkflowState | undefined = getWorkflowState(
    task,
    workflowStates
  );
  if (!(state && workflowStates.length)) {
    return { html: "" };
  }
  return {
    html: `<strong>${task.section.name}</strong> - ${state.name} (${state.rank}/${workflowStates.length})`
  };
};
export const generateTaskNameAsString = (
  task: TaskDisplayData,
  product: ProductItem
): string => {
  return generateTaskName(task, product)
    .html.replace("<strong>", "")
    .replace("</strong>", "");
};
export const getAssignedUsers = (
  state: WorkflowState,
  users: OrganizationUser[]
): OrganizationUser[] => {
  if (!state) {
    return [];
  }
  return users.filter((userToCheck: OrganizationUser) => {
    return state.users.some((assignedUserId: number) => {
      return assignedUserId === userToCheck.id;
    });
  });
};
export const getAssignedUsersList = (
  state: WorkflowState,
  users: OrganizationUser[]
): string[] => {
  return getAssignedUsers(state, users).reduce(
    (acc: string[], cur: OrganizationUser) => {
      return acc.concat(cur.displayName);
    },
    []
  );
};
export const getAssignedUsersTemplate = (
  state: WorkflowState,
  users: OrganizationUser[]
): string => {
  return getAssignedUsers(state, users).reduce(
    (
      acc: string,
      cur: OrganizationUser,
      index: number,
      users: OrganizationUser[]
    ) => {
      acc += cur.displayName;
      acc += index < users.length - 1 ? "<br />" : "";
      return acc;
    },
    ""
  );
};
export const getAssignedUsersString = (
  state: WorkflowState,
  users: OrganizationUser[]
): string => {
  return getAssignedUsers(state, users).reduce(
    (
      acc: string,
      cur: OrganizationUser,
      index: number,
      users: OrganizationUser[]
    ) => {
      acc += cur.displayName;
      acc += index < users.length - 1 ? ", " : "";
      return acc;
    },
    ""
  );
};
export const getDeadlineByWorkflowState = (
  section: ContentStructureSection,
  workflowState: WorkflowState,
  product: ProductItem
): number | undefined => {
  const batches: ProductBatch[] = product ? product.batches : [];

  const deadline: BatchDeadline | undefined =
    csHelper.getBatchDeadlineForWorkflow(section, batches, workflowState);

  return deadline ? deadline.date : undefined;
};
export const orderDisplaySectionByDeadline = (
  a: TaskDisplay,
  b: TaskDisplay
): number => {
  if (a.deadline && b.deadline) {
    return a.deadline - b.deadline;
  } else if (a.deadline && !b.deadline) {
    return -1;
  } else if (!a.deadline && b.deadline) {
    return 1;
  } else {
    return a.id - b.id;
  }
};
