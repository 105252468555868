<template>
  <input
    :id="value.id"
    :checked="isMetadataValueChecked(currentInstance, value.id)"
    :name="currentInstance.metadata_definition.name"
    type="checkbox"
    @change="toggleMetadataAllocatedValue($event)"
  />
</template>

<script lang="ts" setup>
import { useMetadataStore } from "@/stores/metadata.store";
import { PropType } from "vue";
import {
  MetadataAllocatedValuesItem,
  MetadataInstance
} from "@/models/product-section.model";
import { MetadataAllowedValues } from "@/models/content-templates.model";
import { isMetadataValueChecked } from "@/utils/content-metadata-helpers";

const metadataStore = useMetadataStore();

const props = defineProps({
  instances: {
    required: true,
    type: Array as PropType<MetadataInstance[]>
  },
  value: {
    required: true,
    type: Object as PropType<MetadataAllowedValues>
  },
  currentInstance: {
    required: true,
    type: Object as PropType<MetadataInstance>
  },
  productId: {
    required: true,
    type: Number
  }
});
const getMetadataMultipleAllocatedValueId = (): number | undefined => {
  if (
    props.currentInstance.metadata_definition.metadata_allocated_values.length >
    0
  ) {
    const currentValue =
      props.currentInstance.metadata_definition.metadata_allocated_values.find(
        (e: MetadataAllocatedValuesItem) => {
          return e.metadata_allowed_value_id === props.value.id;
        }
      );
    return currentValue ? currentValue.id : undefined;
  }
  return undefined;
};
const toggleMetadataAllocatedValue = async (event: {
  currentTarget: HTMLInputElement;
}) => {
  if (!event.currentTarget.checked) {
    const id = getMetadataMultipleAllocatedValueId();
    if (id) {
      const payload = {
        id,
        productId: props.productId
      };
      await metadataStore.deleteMetadataAllocatedValues(payload);
    }
  } else {
    const payload = {
      value: {
        metadata_allowed_value_id: props.value.id,
        metadata_instance_id: props.currentInstance.id
      },
      productId: props.productId
    };
    await metadataStore.createMetadataAllocatedValues(payload);
  }
};
</script>

<style lang="scss" scoped></style>
