import {
  ReportTableColumn,
  ReportTableComparison,
  ReportTableFilterItem,
  ReportTableFilterExtended,
  ReportTableSort
} from "@/models/report-table.model";
import { cloneDeep } from "lodash";

/**
 * Generates a query for the report table from a set of filters.
 * @param filters An array of filters
 * @param filters.field
 * @param filters.value
 * @param filters.comparison
 * @returns The URL query matching the filters
 */
export const generateFilterQuery = (
  filters: ReportTableFilterExtended[]
): string => {
  return filters.reduce((acc, cur, index) => {
    const separator = index === 0 ? "" : "__";
    return acc + separator + generateSingleFilterQuery(cur);
  }, "");
};

export const generateSingleFilterQuery = (
  filter: ReportTableFilterExtended
): string => {
  return `${filter.field}--${filter.value}--${filter.comparison}`;
};

export const generateMultipleFilter = (
  field: string,
  values: string[],
  comparison: ReportTableComparison
): ReportTableFilterExtended[] => {
  const filters: ReportTableFilterExtended[] = [];
  values.forEach(item => {
    filters.push({
      field,
      value: item,
      comparison
    });
  });
  return filters;
};

// filter data
const matchesDate = (d1: string | number, d2: string | number): boolean => {
  if (!d1 || !d2) {
    return false;
  }
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const filterData = (
  data: Record<string, any>[],
  columns: ReportTableColumn<Record<string, any>>[],
  filters: ReportTableFilterItem
): Record<string, any>[] => {
  return data.filter(item => {
    return Object.entries(filters).every(([key, filter]) => {
      const fieldToFilter = item[key];
      const filterComparison = filter.comparison;
      const filterValue = filter.value;
      const column = columns.find(col => {
        const field = col.altText ? col.altText : col.field;
        return field === key;
      });
      if (
        !column ||
        !filterValue ||
        (Array.isArray(filterValue) && !filterValue.length)
      )
        return true;

      if (column.dropdownMultipleFilter && Array.isArray(filterValue)) {
        return filterValue.some((val: string) => {
          return val.toLowerCase() === String(fieldToFilter).toLowerCase();
        });
      } else if (column.numeric) {
        const newFieldToFilter =
          typeof fieldToFilter === "string"
            ? fieldToFilter.replaceAll(",", "")
            : fieldToFilter;
        if (filterComparison === ReportTableComparison.DEFAULT) {
          return String(newFieldToFilter).includes(String(filterValue));
        } else if (filterComparison === ReportTableComparison.MATCHES) {
          return Number(newFieldToFilter) === Number(filterValue);
        } else if (filterComparison === ReportTableComparison.GREATER) {
          return Number(newFieldToFilter) > Number(filterValue);
        } else if (filterComparison === ReportTableComparison.LESS) {
          return Number(newFieldToFilter) < Number(filterValue);
        }
      } else if (column.date && !Array.isArray(filterValue)) {
        if (filterComparison === ReportTableComparison.GREATER) {
          return new Date(filterValue).getTime() < fieldToFilter;
        } else if (filterComparison === ReportTableComparison.LESS) {
          return new Date(filterValue).getTime() > fieldToFilter;
        } else {
          return matchesDate(filterValue, fieldToFilter);
        }
      } else {
        if (filterComparison === ReportTableComparison.MATCHES) {
          return (
            fieldToFilter.toLowerCase() === String(filterValue).toLowerCase()
          );
        } else {
          return fieldToFilter
            .toLowerCase()
            .includes(String(filterValue).toLowerCase());
        }
      }
    });
  });
};

// sort data
const stringCompare = (isReversed: boolean, a: string, b: string): number => {
  const modifier: number = isReversed ? -1 : 1;
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return modifier;
  } else if (!b) {
    return -1 * modifier;
  }
  return modifier * (a as string).localeCompare(b as string);
};

const numericCompare = (
  isReversed: boolean,
  key: string,
  a: Record<string, unknown>,
  b: Record<string, unknown>
): number => {
  // fix bug with market size values
  const newA =
    typeof a[key] === "string"
      ? (a[key] as string).replaceAll(",", "")
      : a[key];
  const newB =
    typeof b[key] === "string"
      ? (b[key] as string).replaceAll(",", "")
      : b[key];
  const modifier: number = isReversed ? -1 : 1;
  if (!newA && !newB) {
    return 0;
  } else if (!newA) {
    return modifier;
  } else if (!newB) {
    return -1 * modifier;
  }
  return modifier * ((newA as number) - (newB as number));
};

export const sortData = (
  sourceData: Record<string, unknown>[],
  sortOrder: ReportTableSort[],
  columns: ReportTableColumn<Record<string, unknown>>[]
): Record<string, unknown>[] => {
  let data = cloneDeep(sourceData); // avoid sorting input array
  if (sortOrder[0]) {
    const isReversed: boolean = sortOrder[0].direction === "des";
    const key: string = sortOrder[0].field;
    const column = columns.find(
      (e: ReportTableColumn<Record<string, unknown>>) => {
        return e.field === sortOrder[0].field;
      }
    );
    if (column && (column.numeric || column.date)) {
      data = data.sort((a, b) => numericCompare(isReversed, key, a, b));
    } else if (column && column.altText) {
      const newKey = column ? column.altText : "";
      data = data.sort((a, b) =>
        stringCompare(isReversed, a[newKey] as string, b[newKey] as string)
      );
    } else {
      data = data.sort((a, b) =>
        stringCompare(isReversed, a[key] as string, b[key] as string)
      );
    }
  }
  return data;
};

// dates
export const convertDateToURLFriendlyFormat = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  let dt: string | number = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return year + "." + month + "." + dt;
};

export const convertURLFriendlyDateToStandardFormat = (
  dateString: string
): string => {
  return dateString.replace(/\./g, "-");
};
