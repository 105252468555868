import {
  mdiBookOpenVariant,
  mdiCheckboxMultipleBlank,
  mdiDrawing,
  mdiFileImage,
  mdiFilePdfBox,
  mdiFileWord,
  mdiFolder,
  mdiFormatListCheckbox,
  mdiFormatListNumbered,
  mdiImageOutline,
  mdiMonitor,
  mdiPencilRuler
} from "@mdi/js";

export const SECTION_ICONS = {
  icon0: mdiImageOutline,
  icon2: mdiFolder,
  icon3: mdiCheckboxMultipleBlank,
  icon8: mdiMonitor,
  icon9: mdiBookOpenVariant,
  icon10: mdiFileWord,
  icon11: mdiDrawing,
  icon12: mdiFileImage,
  icon13: mdiPencilRuler,
  icon14: mdiFilePdfBox,
  icon15: mdiFormatListCheckbox,
  icon16: mdiFormatListNumbered
};
