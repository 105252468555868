import {
  MetadataAllocatedValuesItem,
  MetadataInstance
} from "@/models/product-section.model";

export const getMetadataInstance = (
  instances: MetadataInstance[],
  instanceId: number
): MetadataInstance | undefined => {
  return instances.find((e: MetadataInstance) => {
    return e.id === instanceId;
  });
};

export const isMetadataValueChecked = (
  currentInstance: MetadataInstance,
  allowedValueId: number
): boolean => {
  return currentInstance.metadata_definition.metadata_allocated_values.some(
    (e: MetadataAllocatedValuesItem) => {
      return e.metadata_allowed_value_id === allowedValueId;
    }
  );
};
