import { DAO } from "@/api/DAO";
import {
  SECTIONS_URL,
  SECTIONS_UPSERT_URL,
  DEPLOY_CONTENT_OBJECTS_URL,
  STATE_TRANSITIONS_URL,
  MESSAGES_URL,
  CONCEPT_SECTIONS_URL
} from "@/api/URL-constants";
import {
  ContentToAddConcept,
  MessageToCreate,
  StateTransitionToUpdate,
  ContentObjectsToDeploy,
  ContentToUpsert,
  ContentToUpdate
} from "@/models/api/content.model";
import { AxiosResponse } from "axios";

const update = (payload: ContentToUpdate): Promise<AxiosResponse> => {
  return DAO().put(SECTIONS_URL, payload);
};

const upsert = (payload: ContentToUpsert[]): Promise<AxiosResponse> => {
  return DAO().put(SECTIONS_UPSERT_URL, payload);
};

const remove = (contentId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${SECTIONS_URL}/${contentId}`);
};

const deployContentObjects = (
  payload: ContentObjectsToDeploy
): Promise<AxiosResponse> => {
  return DAO().post(DEPLOY_CONTENT_OBJECTS_URL, payload);
};

const updateStateTransition = (
  payload: StateTransitionToUpdate
): Promise<AxiosResponse> => {
  return DAO().put(STATE_TRANSITIONS_URL, payload);
};

const createMessage = (payload: MessageToCreate): Promise<AxiosResponse> => {
  return DAO().post(MESSAGES_URL, payload);
};

const removeMessage = (messageId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${MESSAGES_URL}/${messageId}`);
};

const addConceptToContent = (
  payload: ContentToAddConcept
): Promise<AxiosResponse> => {
  return DAO().post(CONCEPT_SECTIONS_URL, payload);
};

const removeConceptFromContent = (
  conceptSectionId: number
): Promise<AxiosResponse> => {
  return DAO().delete(`${CONCEPT_SECTIONS_URL}/${conceptSectionId}`);
};

export {
  update,
  upsert,
  remove,
  deployContentObjects,
  updateStateTransition,
  createMessage,
  removeMessage,
  addConceptToContent,
  removeConceptFromContent
};
