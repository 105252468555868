export const MESSAGES = {
  ERRORS: {
    failure: "Failure",
    unhandled: "Unhandled fetch error:",
    consentFailed: "Consent failed:",
    navigation: "NavigationDuplicated",
    unexpected: "Unexpected error",
    login: "error logging in"
  },
  SUCCESS: {
    deployed: "Successfully deployed",
    uploaded: "Successfully uploaded"
  }
};
