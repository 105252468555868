import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { Organization } from "@/models/organization.model";
import API from "@/api";
import { AxiosResponse } from "axios";
import { OrganizationResponse } from "@/models/api/organization.model";
import { AvailableFeature } from "@/models/available-features.model";
import { USER } from "@/constants/user.constants";

export const useOrganizationStore = defineStore("organization", () => {
  const isOrganizationLoading = ref<boolean>(true);
  const organization = ref<Organization | null>(null);

  const availableOrgFeatures = ref<AvailableFeature[] | null>(null);
  const canOrgManageProgrammes = computed<boolean>(() => {
    return (
      !!availableOrgFeatures.value &&
      availableOrgFeatures.value.some((feature: AvailableFeature) => {
        return feature.id === USER.FEATURES.manageProgrammes && feature.enabled;
      })
    );
  });
  const canOrgManageCurriculums = computed<boolean>(() => {
    return (
      !!availableOrgFeatures.value &&
      availableOrgFeatures.value.some((feature: AvailableFeature) => {
        return (
          feature.id === USER.FEATURES.manageCurriculums && feature.enabled
        );
      })
    );
  });

  const populateOrganization = () => {
    return API.organization
      .get()
      .then((response: AxiosResponse<OrganizationResponse>) => {
        isOrganizationLoading.value = false;
        organization.value = response.data.data;
        availableOrgFeatures.value = response.data.data.features;
      });
  };

  return {
    isOrganizationLoading,
    organization,
    availableOrgFeatures,
    canOrgManageProgrammes,
    canOrgManageCurriculums,
    populateOrganization
  };
});
