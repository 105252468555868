<template>
  <div
    :class="['margin-b-standard', { updating: isReloading }]"
    data-testing="qti-card"
  >
    <RightSidebarTitle :title="'QTI Question Bank'" />

    <table class="simple-table mb-0" v-if="!isStartMessageVisible">
      <thead>
        <tr>
          <th>#</th>
          <th>id</th>
          <th>Difficulty</th>
          <th>Validation</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(question, index) in questions" :key="question.questionId">
          <td class="index-col">{{ index + 1 }}</td>
          <td class="id-col">{{ question.questionId }}</td>
          <td class="difficulty-col">
            <span
              v-if="isDifficultyVisible(question.difficulty)"
              class="question-difficulty"
              >{{ question.difficulty }}</span
            >
            <span v-else>Not set</span>
          </td>
          <td class="validation-col">
            <Hint
              v-if="question.errors.length > 0"
              :type="'warning'"
              :position="'left'"
              :iconSize="16"
            >
              <ul>
                <li v-for="(error, index) in question.errors" :key="index"
                  >{{ error }} required</li
                >
              </ul>
            </Hint>
            <SVGIcon v-else :path="mdiCheck" type="is-success" />
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="start-message">
      <p v-if="!isQTIEditorPage"
        >Click the <strong>Open editor</strong> button to start authoring
        questions.</p
      >
      <p v-else>No questions authored yet.</p>
    </div>

    <div class="tools-buttons">
      <!--Open editor button-->
      <span
        v-if="!isQTIEditorPage && !isContentAuthoringTier2FeatureEnabled"
        class="btn-3 disabled margin-r-standard"
        data-testing="qti-card-btn-editor"
      >
        <SVGIcon :path="mdiPencil" />
        Open editor
      </span>
      <router-link
        v-if="!isQTIEditorPage && isContentAuthoringTier2FeatureEnabled"
        class="btn-3 margin-r-standard"
        data-testing="qti-card-btn-editor"
        :to="{
          name: 'qti-editor',
          params: {
            productId: uiContentStore.sectionToDisplay.productId,
            sectionId: uiContentStore.sectionToDisplay.sectionId
          }
        }"
      >
        <SVGIcon :path="mdiPencil" />
        Open editor
      </router-link>
      <!--Reload button-->
      <button
        v-if="isQTIEditorPage"
        :disabled="isReloading || !isContentAuthoringTier2FeatureEnabled"
        @click="reloadQTI"
        class="btn-3 margin-r-standard"
        type="button"
        data-testing="qti-card-btn-reload"
      >
        <SVGIcon :path="mdiRefresh" />
        Reload QTI
      </button>
      <!--Preview button-->
      <span
        v-if="!isStartMessageVisible && !isContentAuthoringTier2FeatureEnabled"
        class="btn-3 disabled margin-r-standard"
        data-testing="qti-card-btn-preview"
      >
        <SVGIcon :path="mdiPresentationPlay" />
        Preview
      </span>
      <router-link
        v-if="!isStartMessageVisible && isContentAuthoringTier2FeatureEnabled"
        class="btn-3"
        data-testing="qti-card-btn-preview"
        :disabled="!isContentAuthoringTier2FeatureEnabled"
        :event="isContentAuthoringTier2FeatureEnabled ? 'click' : ''"
        :to="{
          name: 'qti-preview',
          params: {
            productId: uiContentStore.sectionToDisplay.productId,
            sectionId: uiContentStore.sectionToDisplay.sectionId
          }
        }"
      >
        <SVGIcon :path="mdiPresentationPlay" />
        Preview
      </router-link>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, PropType, ref, watch } from "vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import {
  buildQuestionsHTMLArray,
  isDifficultyVisible
} from "@/utils/product/qti-to-html-helper";
import { QTIQuestionHTML } from "@/models/qti.model";
import Hint from "@/components/Hint.vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { mdiCheck, mdiPencil, mdiPresentationPlay, mdiRefresh } from "@mdi/js";
import { useUIContentStore } from "@/stores/ui-content.store";
import { useProductsStore } from "@/stores/products.store";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const uiContentStore = useUIContentStore();
const productsStore = useProductsStore();

const props = defineProps({
  section: {
    type: Object as PropType<ContentStructureSection>,
    required: true
  },
  isContentAuthoringTier2FeatureEnabled: {
    required: true,
    type: Boolean
  }
});

const isQTIEditorPage = ref<boolean>(false);

const isReloading = ref<boolean>(false);
const reloadQTI = () => {
  isReloading.value = true;
  productsStore
    .populateProductDetails(Number(route.params.productId))
    .then(() => (isReloading.value = false));
};

const isQuestionsLoading = ref<boolean>(true);
const questions = ref<QTIQuestionHTML[]>([]);
const getQuestions = async (): Promise<void> => {
  isQuestionsLoading.value = true;
  const variables = props.section.document_variables
    ? props.section.document_variables
    : [];
  if (props.section.document) {
    const doc = props.section.document ? props.section.document : "";
    questions.value = await buildQuestionsHTMLArray(doc, variables, false);
  }
  isQuestionsLoading.value = false;
};
onMounted(() => {
  getQuestions();
  isQTIEditorPage.value = route.name === "qti-editor";
});
uiContentStore.$subscribe((mutation, state) => {
  const newVal = state.sectionToDisplay;
  if (newVal) {
    getQuestions();
  }
});
watch(
  () => router.currentRoute.value,
  newVal => {
    isQTIEditorPage.value = newVal.name === "qti-editor";
  },
  { immediate: true }
);
watch(
  () => props.section.document,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      getQuestions();
    }
  }
);

const isStartMessageVisible = computed<boolean>(() => {
  return (
    !isQuestionsLoading.value &&
    (!questions.value || questions.value.length === 0)
  );
});
</script>
<style lang="scss" scoped>
td {
  &.index-col {
    width: 5.6%;
  }
  &.difficulty-col {
    width: 15.6%;
    white-space: nowrap;
  }
  &.validation-col {
    width: 16.5%;
    text-align: center;
  }
}
.tools-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
  padding: 0;
}
.start-message {
  p {
    padding-bottom: 0;
    font-size: 0.875rem;
  }
}
.question-difficulty {
  text-transform: capitalize;
}
</style>
<style lang="scss">
.validation-col {
  .hint {
    margin-left: 0;
  }
}
</style>
