<template>
  <Collapse>
    <template v-slot:title>
      <span>Identifiers</span>
    </template>
    <template v-slot:content>
      <div v-if="canEditContent">
        <div class="margin-b-standard">
          <RightSidebarTitle :title="'Thoth id'" />
          <input type="text" readonly disabled :value="internalId" />
        </div>
        <div class="margin-b-standard">
          <RightSidebarTitle :title="'External id'" />
          <input type="text" v-model.trim="newExternalId" />
        </div>
        <button
          class="btn-3"
          @click="updateExternalId"
          :disabled="isSaveButtonDisabled"
        >
          <SVGIcon :path="mdiContentSave" />
          Save
        </button>
        <ErrorMessage v-if="externalIdError" :message="externalIdError" />
      </div>
      <div v-else>
        <p class="pb-2"><strong>Thoth id: </strong>{{ internalId }}</p>
        <p class="pb-0 externalId"
          ><strong>External id: </strong> {{ externalId }}</p
        >
      </div>
    </template>
  </Collapse>
</template>
<script lang="ts" setup>
import { computed, onMounted, onUnmounted, PropType, ref, watch } from "vue";
import Collapse from "@/components/right-sidebars/ui/Collapse.vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import { ProductSection } from "@/models/product-section.model";
import { mdiContentSave } from "@mdi/js";
import { useContentStore } from "@/stores/content.store";
import { SECTION } from "@/constants/assets.constant";
import ErrorMessage from "@/components/ErrorMessage.vue";

const contentStore = useContentStore();

const props = defineProps({
  productId: {
    required: true
  },
  internalId: {
    required: true,
    type: Number
  },
  externalId: {
    required: true,
    type: String
  },
  sections: {
    required: true,
    type: Array as PropType<ProductSection[]>
  },
  canEditContent: {
    required: true,
    type: Boolean
  }
});

const newExternalId = ref<string>("");
onMounted(() => {
  newExternalId.value = props.externalId;
});
onUnmounted(() => {
  newExternalId.value = "";
});
watch(
  () => props.internalId,
  () => {
    newExternalId.value = props.externalId;
  }
);
watch(
  () => newExternalId.value,
  () => {
    externalIdError.value = "";
  }
);

const externalIdError = ref<string>("");
const isExternalIdValid = (): boolean => {
  externalIdError.value = "";
  return !props.sections.some((section: ProductSection) => {
    return (
      section.client_external_id === newExternalId.value &&
      section.id !== props.internalId
    );
  });
};
const updateExternalId = (): void => {
  let isValid;
  if (newExternalId.value) {
    // if a user entered the external id, this id must be unique
    isValid = isExternalIdValid();
  } else {
    // user can send empty string if he wants to remove the old external id and doesn't set a new one
    isValid = newExternalId.value !== props.externalId;
  }
  if (isValid) {
    contentStore
      .upsertSections({
        productIds: [Number(props.productId)],
        sections: [
          {
            id: props.internalId,
            client_external_id: newExternalId.value
          }
        ]
      })
      .catch((error: Error) => {
        externalIdError.value = error.message;
      });
  } else {
    externalIdError.value = SECTION.ERRORS.nonUniqueExternalId;
  }
};

const isSaveButtonDisabled = computed<boolean>(() => {
  return newExternalId.value === props.externalId || !!externalIdError.value;
});
</script>
<style lang="scss" scoped>
.externalId {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
