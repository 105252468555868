import { DAO } from "@/api/DAO";
import {
  METADATA_INSTANCE_URL,
  METADATA_DEFINITION_URL,
  METADATA_ALLOWED_VALUES_URL,
  METADATA_ALLOCATED_VALUES_URL,
  IMPORT_METADATA_URL
} from "@/api/URL-constants";
import { AxiosResponse } from "axios";
import {
  AllocatedValueToCreate,
  AllocatedValueToCreateResponse,
  AllowedValueToCreate,
  AllowedValueToCreateResponse,
  AllowedValueToUpdate,
  DefinitionToCreate,
  DefinitionToCreateResponse,
  DefinitionToImport,
  DefinitionToUpdate,
  InstanceToUpdate
} from "@/models/api/metadata.model";

const createDefinition = (
  payload: DefinitionToCreate
): Promise<AxiosResponse<DefinitionToCreateResponse>> => {
  return DAO().post(METADATA_DEFINITION_URL, payload);
};

const updateDefinition = (
  payload: DefinitionToUpdate
): Promise<AxiosResponse> => {
  return DAO().put(METADATA_DEFINITION_URL, payload);
};

const removeDefinition = (definitionId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${METADATA_DEFINITION_URL}/${definitionId}`);
};

const importDefinitions = (
  payload: DefinitionToImport
): Promise<AxiosResponse> => {
  return DAO().put(IMPORT_METADATA_URL, payload);
};

const createAllowedValues = (
  payload: AllowedValueToCreate[]
): Promise<AxiosResponse<AllowedValueToCreateResponse>> => {
  return DAO().post(METADATA_ALLOWED_VALUES_URL, payload);
};

const updateAllowedValues = (
  payload: AllowedValueToUpdate
): Promise<AxiosResponse> => {
  return DAO().put(METADATA_ALLOWED_VALUES_URL, payload);
};

const removeAllowedValues = (valueId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${METADATA_ALLOWED_VALUES_URL}/${valueId}`);
};

const updateInstance = (payload: InstanceToUpdate): Promise<AxiosResponse> => {
  return DAO().put(METADATA_INSTANCE_URL, payload);
};

const createAllocatedValues = (
  payload: AllocatedValueToCreate
): Promise<AxiosResponse<AllocatedValueToCreateResponse>> => {
  return DAO().post(METADATA_ALLOCATED_VALUES_URL, payload);
};

const removeAllocatedValues = (valueId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${METADATA_ALLOCATED_VALUES_URL}/${valueId}`);
};

export {
  createDefinition,
  updateDefinition,
  removeDefinition,
  importDefinitions,
  createAllowedValues,
  updateAllowedValues,
  removeAllowedValues,
  updateInstance,
  createAllocatedValues,
  removeAllocatedValues
};
