export interface Config {
  toolbar: string;
  editorResizeMode: string;
  showFloatTextToolBar: boolean;
  showFloatLinkToolBar: boolean;
  showFloatImageToolBbar: boolean;
  showFloatTableToolBar: boolean;
  showFloatParagraph: boolean;
  toggleBorder: boolean;
  focusOnLoad: boolean;
  urlType: string;
  contentCssUrl: string;
}
export interface QuestionConfig extends Config {
  toolbarfactory_Interaction?: (
    cmd: string,
    suffix: null | unknown
  ) => HTMLElement;
  toolbar_questionToolbar?: string;
  toolbar_mobile?: string;
}
export interface HintConfig extends Config {
  toolbar_hintToolbar?: string;
  toolbar_mobile?: string;
}
export interface AnswerConfig extends Config {
  toolbar_answerToolbar?: string;
  toolbar_mobile?: string;
}
export interface EditorConfig extends Config {
  toolbar_editorToolbar?: string;
  toolbar_mobile?: string;
}
export interface Editor {
  CONFIG: {
    general: Config;
  };
  STYLES: {
    tables: string;
    interaction: string;
  };
  TOOLBARS: {
    html: string;
    question: string;
    hint: string;
    answer: string;
  };
}

export const EDITOR: Editor = {
  CONFIG: {
    general: {
      toolbar: "full",
      editorResizeMode: "none",
      showFloatTextToolBar: false,
      showFloatLinkToolBar: false,
      showFloatImageToolBbar: false,
      showFloatTableToolBar: false,
      showFloatParagraph: false,
      toggleBorder: false,
      focusOnLoad: true,
      urlType: "absolute",
      contentCssUrl: ""
    }
  },
  STYLES: {
    tables: `
    <style>
      table{border-collapse: collapse;border-spacing: 0;}
      td {border-width: 1px 0 1px 1px; border-color: #dddddd; border-style: solid; padding: 0.25rem;} td:last-child {border-right: 1px solid #0847c2;}
    </style>
    `,
    interaction: `
    <style>
    span.fieldset{display: inline-block;padding-inline: 2px;padding-block:1px;border-color:#3366ff;color:#3366ff;border-style:solid;}
    </style>
    `
  },
  TOOLBARS: {
    html:
      "{bold,italic,underline}|{insertorderedlist,insertunorderedlist}|{superscript,subscript}|{insertlink,unlink,insertblockquote,insertchars,inserttable,insertimage,insertvideo,insertcode}|{paragraphs:dropdown}" +
      "#{removeformat,cut,copy,paste,delete,find,selectall,undo,redo,code}",
    question:
      "{bold,italic,underline}|{insertorderedlist,insertunorderedlist}|{superscript,subscript}#{insertlink,unlink,insertchars,inserttable,insertimage,insertcode}" +
      " /{removeformat,cut,copy,paste,delete,find,selectall,code,undo,redo}#{Interaction}",
    hint:
      "{bold,italic,underline}|{insertorderedlist,insertunorderedlist}|{superscript,subscript}|{insertchars,inserttable,insertimage,insertcode}" +
      " #{removeformat,cut,copy,paste,delete,find,selectall,code,undo,redo}",
    answer:
      "{bold,italic,underline}|{superscript,subscript}|{insertchars,inserttable,insertimage,insertcode}" +
      "#{removeformat,cut,copy,paste,delete,find,selectall,code,undo,redo}"
  }
};

export interface Interactions {
  HTML: (id: string, index: number) => string;
  TYPES: {
    names: {
      single: string;
      multiple: string;
    };
    items: {
      value: () => string;
      title: string;
    }[];
    isChoiceType: (type: string) => boolean;
  };
  MIN_CHOICES_NUMBER: number;
}
export const INTERACTIONS: Interactions = {
  HTML(id: string, index: number) {
    return `&nbsp;<span class="fieldset" id="${id}">i.${index}</span>&nbsp;`;
  },
  TYPES: {
    names: {
      single: "mcq-single-answer",
      multiple: "mcq-multiple-answer"
    },
    items: [
      {
        value() {
          return "";
        },
        title: "Not selected"
      },
      {
        value() {
          return INTERACTIONS.TYPES.names.single;
        },
        title: "Single answer"
      },
      {
        value() {
          return INTERACTIONS.TYPES.names.multiple;
        },
        title: "Multiple answers"
      }
    ],
    isChoiceType(type: string) {
      return [
        INTERACTIONS.TYPES.names.single,
        INTERACTIONS.TYPES.names.multiple
      ].includes(type);
    }
  },
  MIN_CHOICES_NUMBER: 2
};
