<template>
  <div class="modal-card">
    <div class="modal-card-header">
      <h3>Edit path</h3>
    </div>
    <div class="modal-card-content">
      <div class="toggle-check-container margin-b-standard">
        <label class="toggle-check">
          <input
            type="checkbox"
            aria-label="edit"
            :value="isLongPathNameEnabled"
            data-testing="toggle-edit-mode"
            class="editable"
            v-model="isLongPathNameEnabled"
          />
          <span
            data-on-text="Long path name"
            data-off-text="Short path name"
          ></span>
        </label>
      </div>
      <div class="margin-b-standard">
        <div>
          <RightSidebarTitle :title="'Select path'" />
          <div class="path-item">
            <select v-model="newParentId">
              <option
                :selected="newParentId === structure.rootSection.id"
                :value="structure.rootSection.id"
                >{{ product.name }}</option
              >
              <option
                v-for="item in sortedPathsToDisplay"
                :key="item.lastChildSectionId"
                :value="item.lastChildSectionId"
                :selected="item.lastChildSectionId === newParentId"
                >{{ item.pathString }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <div class="margin-b-standard">
        <RightSidebarTitle :title="'Old path'" />
        <ContentLocationPath
          :product="product"
          :path="path"
          :section-name="sectionName"
          :section-id="sectionId"
          :section-icon-id="sectionIconId"
          :section-external-id="sectionExternalId"
          :is-clickable="false"
          :is-long-path-name-enabled="isLongPathNameEnabled"
        />
      </div>
      <div>
        <RightSidebarTitle :title="'New path'" />
        <ContentLocationPath
          :product="product"
          :path="newPath"
          :section-name="sectionName"
          :section-id="sectionId"
          :section-icon-id="sectionIconId"
          :section-external-id="sectionExternalId"
          :is-clickable="false"
          :is-long-path-name-enabled="isLongPathNameEnabled"
        />
      </div>
      <ErrorMessage v-if="serverError" :message="serverError" />
    </div>
    <div class="modal-card-footer">
      <button
        class="btn-2"
        type="button"
        @click="uiContentStore.closeEditContentPathModal"
      >
        Cancel
      </button>
      <button
        class="btn-1"
        type="button"
        @click="updatePath"
        :disabled="isSaveBtnDisabled"
      >
        Save
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import ContentLocationPath from "@/components/right-sidebars/content-sidebar/content-location/ContentLocationPath.vue";
import { computed, onMounted, PropType, ref } from "vue";
import csHelper from "@/utils/product/content-structure-helpers";
import { GeneratedContentStructure } from "@/models/content-structure.model";
import { AssetLocation } from "@/models/assets.model";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import { ProductSection } from "@/models/product-section.model";
import { useUIContentStore } from "@/stores/ui-content.store";
import { useContentStore } from "@/stores/content.store";
import { ProductItem } from "@/models/product.model";
import { cloneDeep } from "lodash";
import { truncateString } from "@/utils/text-helpers";
import { showToast } from "@/services/notification-manager";
import { SECTION } from "@/constants/assets.constant";
import ErrorMessage from "@/components/ErrorMessage.vue";

interface PathToDisplay {
  lastChildSectionId: number;
  pathString: string;
}

const uiContentStore = useUIContentStore();
const contentStore = useContentStore();

const props = defineProps({
  product: {
    required: true,
    type: Object as PropType<ProductItem>
  },
  structure: {
    required: true,
    type: Object as PropType<GeneratedContentStructure>
  },
  path: {
    required: true,
    type: Array as PropType<AssetLocation[]>
  },
  sectionName: {
    required: true,
    type: String
  },
  sectionId: {
    required: true,
    type: Number
  },
  sectionIconId: {
    required: true,
    type: Number
  },
  sectionExternalId: {
    required: true,
    type: String
  }
});

const oldParentId = ref<number | null>(null);
const newParentId = ref<number | null>(null);
const currentPath = computed<AssetLocation[]>(() => {
  return !!props.structure.rootSection
    ? csHelper.getPathToSectionWithIds(
        props.structure.rootSection,
        props.sectionId
      )
    : [];
});
const setParentIds = (): void => {
  if (props.structure.rootSection) {
    if (currentPath.value.length > 0) {
      oldParentId.value = currentPath.value[currentPath.value.length - 1].id;
      newParentId.value = oldParentId.value;
    } else {
      oldParentId.value = props.structure.rootSection.id;
      newParentId.value = oldParentId.value;
    }
  }
};
onMounted(() => {
  setParentIds();
});

const isLongPathNameEnabled = ref<boolean>(true);
const isSelectVisible = computed<boolean>(() => {
  return (
    !!newParentId.value &&
    !!props.structure.rootSection &&
    newParentId.value !== props.structure.rootSection.id
  );
});
const isSaveBtnDisabled = computed<boolean>(() => {
  return (
    newParentId.value === oldParentId.value ||
    newParentId.value === 0 ||
    newParentId.value === props.sectionId
  );
});

const possiblePaths = computed<AssetLocation[][]>(() => {
  return props.product.sections
    .filter(
      // remove root section, current section, child sections of current section from possible paths
      (section: ProductSection): boolean => {
        return !!section.parent_section_id;
      }
    )
    .map((section: ProductSection): AssetLocation[] => {
      const path = props.structure.rootSection
        ? csHelper.getPathToSectionWithIds(
            props.structure.rootSection,
            section.id
          )
        : [];
      if (path) {
        path.push({
          name: section.name,
          id: section.id,
          iconId: section.type.icon_id,
          externalId: section.client_external_id
            ? section.client_external_id
            : ""
        });
      }
      return path;
    })
    .filter((path: AssetLocation[]): boolean => {
      // remove all paths to child sections of the current section
      return !path.some((pathItem: AssetLocation): boolean => {
        return pathItem.id === props.sectionId;
      });
    });
});
const pathsToDisplay = computed<PathToDisplay[]>(() => {
  return possiblePaths.value.map((path: AssetLocation[]) => {
    const pathString = path.map((pathItem: AssetLocation) => {
      return truncateString(pathItem.name, 45);
    });
    return {
      lastChildSectionId: path[path.length - 1].id,
      pathString: `${props.product.name}/${pathString.join("/")}`
    };
  });
});
const filteredPathsToDisplay = computed<PathToDisplay[]>(() => {
  // remove duplicate paths
  return pathsToDisplay.value.reduce(
    (acc: PathToDisplay[], item: PathToDisplay): PathToDisplay[] => {
      const prevItem = acc.find((prevItem: PathToDisplay): boolean => {
        return prevItem.pathString === item.pathString;
      });
      if (!prevItem) {
        acc.push(item);
      }
      return acc;
    },
    []
  );
});
const sortedPathsToDisplay = computed<PathToDisplay[]>(() => {
  // sort in alphabetical order
  const paths = cloneDeep(filteredPathsToDisplay.value);
  return paths.sort((a: PathToDisplay, b: PathToDisplay) => {
    return ("" + a.pathString).localeCompare(b.pathString);
  });
});

const newPath = computed<AssetLocation[] | undefined>(() => {
  if (newParentId.value !== oldParentId.value) {
    if (
      props.structure.rootSection &&
      newParentId.value === props.structure.rootSection.id
    ) {
      return [];
    }
    return possiblePaths.value.find(
      (possiblePath: AssetLocation[]): boolean => {
        return (
          !!newParentId.value &&
          possiblePath[possiblePath.length - 1].id === newParentId.value
        );
      }
    );
  }
  return props.path;
});

const serverError = ref<string>("");
const updatePath = () => {
  if (newParentId.value) {
    const payload = {
      productIds: [props.product.id],
      sections: [
        {
          id: props.sectionId,
          new_parent_section_id: newParentId.value
        }
      ]
    };
    contentStore
      .upsertSections(payload)
      .then(response => {
        if (response.status === 200) {
          showToast({
            message: SECTION.SUCCESS.internalPath,
            type: "is-success"
          });
          uiContentStore.closeEditContentPathModal();
        }
      })
      .catch(error => {
        serverError.value = error.message;
      });
  }
};
</script>
<style scoped lang="scss">
.modal-card {
  @media screen and (min-width: 769px) {
    width: 1300px;
  }
}
.toggle-check-container {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid $border-color;
  padding-bottom: 0.5rem;
  .toggle-check {
    transform: scale(0.75);
  }
}
.path-item {
  display: flex;
  align-items: center;
}
</style>
