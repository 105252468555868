<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24.9 24.9"
    width="25"
    height="25"
  >
    <g>
      <line
        x1="24"
        y1="12.5"
        x2="13"
        y2="12.5"
        fill="none"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="12.4" cy="12.5" r="1.6" />
      <circle cx="23.3" cy="12.5" r="1.6" />
      <line
        x1="12.1"
        y1="12.8"
        x2="23"
        y2="1.9"
        fill="none"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="23.3" cy="1.6" r="1.6" />
      <circle cx="23.3" cy="23.3" r="1.6" />
      <circle cx="1.6" cy="12.5" r="1.6" />
      <line
        x1="10.8"
        y1="12.5"
        x2="2"
        y2="12.5"
        fill="none"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <line
        x1="12.1"
        y1="12.2"
        x2="23.2"
        y2="23.3"
        fill="none"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="12.4" cy="23.3" r="1.6" />
      <line
        x1="1.3"
        y1="12.2"
        x2="12.4"
        y2="23.3"
        fill="none"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
<script setup></script>
<style lang="scss" scoped></style>
