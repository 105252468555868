import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from "vue-router";
import { useAuthStore } from "@/stores/auth.store";
import { useUIGeneralStore } from "@/stores/ui-general.store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    meta: {
      heading: "",
      layout: "auth"
    },
    component: () => import(/* webpackChunkName: "login" */ "./views/Login.vue")
  },
  {
    path: "/",
    name: "home",
    meta: { heading: "Home" },
    component: () => import(/* webpackChunkName: "home" */ "./views/Home.vue")
  },
  {
    path: "/users",
    name: "users",
    meta: { heading: "Users" },
    component: () => import(/* webpackChunkName: "users" */ "./views/Users.vue")
  },
  {
    path: "/programmes",
    name: "programmes",
    meta: { heading: "Programmes" },
    component: () =>
      import(/* webpackChunkName: "programmes" */ "./views/Programmes.vue")
  },
  {
    path: "/all-products",
    name: "all-products",
    meta: { heading: "Products" },
    component: () =>
      import(/* webpackChunkName: "allProducts" */ "./views/AllProducts.vue")
  },
  {
    path: "/attachments",
    name: "files",
    meta: { heading: "Attachments" },
    component: () => import(/* webpackChunkName: "files" */ "./views/Files.vue")
  },
  {
    path: "/tasks",
    name: "tasks",
    meta: { heading: "Tasks" },
    component: () => import(/* webpackChunkName: "tasks" */ "./views/Tasks.vue")
  },
  {
    path: "/programme/:programmeId/products",
    name: "products",
    props: true,
    component: () =>
      import(/* webpackChunkName: "products" */ "./views/Products.vue")
  },
  {
    path: "/create-user",
    name: "createUser",
    meta: { heading: "Create a User" },
    component: () =>
      import(/* webpackChunkName: "createUser" */ "./views/CreateUser.vue")
  },
  {
    path: "/create-programme",
    name: "createProgramme",
    meta: { heading: "Create a Programme" },
    component: () =>
      import(
        /* webpackChunkName: "createProgramme" */ "./views/CreateProgramme.vue"
      )
  },
  {
    path: "/programme/:programmeId/create-product",
    name: "createProductInProgramme",
    meta: { heading: "Create a Product" },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "createProductInProgramme" */ "./views/CreateProduct.vue"
      )
  },
  {
    path: "/create-product",
    name: "createProduct",
    meta: { heading: "Create a Product" },
    component: () =>
      import(
        /* webpackChunkName: "createProduct" */ "./views/CreateProduct.vue"
      )
  },
  {
    path: "/manage-products",
    name: "manageProducts",
    meta: { heading: "Manage Products" },
    component: () =>
      import(
        /* webpackChunkName: "manageProduct" */ "./views/ManageProducts.vue"
      )
  },
  {
    path: "/product/:productId",
    name: "product",
    props: true,
    component: () =>
      import(/* webpackChunkName: "product" */ "./views/product/Product.vue")
  },
  {
    path: "/product/:productId/adaptive-learning-systems",
    name: "adaptive-learning-systems-list",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "adaptiveLearningSystemsList" */ "./views/product/AdaptiveLearningSystemsList.vue"
      )
  },
  {
    path: "/product/:productId/adaptive-learning-systems/:courseId/:loopName",
    name: "adaptive-learning-system",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "adaptiveLearningSystem" */ "./views/product/AdaptiveLearningSystem.vue"
      )
  },
  {
    path: "/product/:productId/content",
    name: "content",
    props: true,
    component: () =>
      import(/* webpackChunkName: "content" */ "./views/product/Content.vue")
  },
  {
    path: "/product/:productId/progress",
    name: "progress",
    props: true,
    component: () =>
      import(/* webpackChunkName: "progress" */ "./views/product/Progress.vue")
  },
  {
    path: "/product/:productId/content-templates",
    name: "content-templates",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "contentTemplates" */ "./views/product/ContentTemplates.vue"
      )
  },
  {
    path: "/product/:productId/content-templates/:contentTemplateId/assets",
    name: "assets",
    props: true,
    component: () =>
      import(/* webpackChunkName: "assets" */ "./views/product/Assets.vue")
  },
  {
    path: "/product/:productId/permissions",
    name: "product-permissions",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "productPermissions" */ "./views/product/Permissions.vue"
      )
  },
  {
    path: "/product/:productId/feature-management",
    name: "feature-management",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "featureManagement" */ "./views/product/FeatureManagement.vue"
      )
  },
  {
    path: "/product/:productId/tasks",
    name: "productTasks",
    props: true,
    component: () =>
      import(/* webpackChunkName: "productTasks" */ "./views/product/Tasks.vue")
  },
  {
    path: "/product/:productId/planning",
    name: "planning",
    props: true,
    component: () =>
      import(/* webpackChunkName: "planning" */ "./views/product/Planning.vue")
  },
  {
    path: "/product/:productId/create-batch",
    name: "create-batch",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "createBatch" */ "./views/product/CreateBatch.vue"
      )
  },
  {
    path: "/product/:productId/mcq/:sectionId",
    name: "mcq",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "mcq" */ "./views/product/section/MCQEditor.vue"
      )
  },
  {
    path: "/product/:productId/qti-preview/:sectionId",
    name: "qti-preview",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "qtiPreview" */ "./views/product/section/QTIPreview.vue"
      )
  },
  {
    path: "/product/:productId/qti-editor/:sectionId",
    name: "qti-editor",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "qtiEditor" */ "./views/product/section/QTIEditor.vue"
      )
  },
  {
    path: "/product/:productId/html-editor/:sectionId",
    name: "html-editor",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "htmlEditor" */ "./views/product/section/HTMLEditor.vue"
      )
  },
  {
    path: "/product/:productId/question-editor/:sectionId",
    name: "question-editor",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "questionEditor" */ "./views/product/section/QuestionEditor.vue"
      )
  },
  {
    path: "/product/:productId/html-preview/:sectionId",
    name: "html-preview",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "htmlPreview" */ "./views/product/section/HTMLPreview.vue"
      )
  },
  {
    path: "/curriculums",
    name: "curriculums",
    meta: { heading: "Curriculums" },
    component: () =>
      import(/* webpackChunkName: "curriculums" */ "./views/Curriculums.vue")
  },
  {
    path: "/create-curriculum",
    name: "createCurriculum",
    meta: { heading: "Create a Curriculum" },
    component: () =>
      import(
        /* webpackChunkName: "createCurriculum" */ "./views/CreateCurriculum.vue"
      )
  },
  {
    path: "/auth-start",
    name: "authStart",
    meta: { heading: "Consent Popup" },
    component: () =>
      import(/* webpackChunkName: "consentPopup" */ "./views/ConsentPopup.vue")
  },
  {
    path: "/auth-end",
    name: "authEnd",
    meta: { heading: "Consent Popup" },
    component: () =>
      import(/* webpackChunkName: "consentPopup" */ "./views/ClosePopup.vue")
  },
  {
    path: "/config",
    name: "tabConfig",
    meta: { heading: "Team tabs config" },
    component: () =>
      import(/* webpackChunkName: "consentPopup" */ "./views/TabsConfig.vue")
  },
  {
    path: "/curriculum/:curriculumId",
    name: "curriculum",
    meta: { heading: "Curriculums" },
    props: true,
    component: () =>
      import(/* webpackChunkName: "curriculum" */ "./views/Curriculum.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const authStore = useAuthStore();
    const uiGeneralStore = useUIGeneralStore();
    if (to.meta.heading) {
      uiGeneralStore.setPageHeading(to.meta.heading as string);
    }
    if (to.name === "files" && to.path !== from.path) {
      uiGeneralStore.closeAllSidebarsExceptFiles();
    } else if (to.path !== from.path) {
      uiGeneralStore.closeAllSidebars();
    }
    if (to.name !== "login" && !authStore.isLoggedIn) {
      next({
        name: "login",
        replace: true
      });
    } else {
      next();
    }
  }
);

export default router;
