import { DAO } from "@/api/DAO";
import {
  FILES_URL,
  FILES_LINK_URL,
  FILES_PRODUCT_LINK_URL
} from "@/api/URL-constants";
import {
  FileToLinkToProduct,
  FileToLinkToProductResponse,
  FileToLinkToSection,
  FileToLinkToSectionResponse,
  FileToUpsertResponse,
  GetAllFilesResponse,
  FileToUpsert
} from "@/models/api/files.model";
import { AxiosResponse } from "axios";

const getAll = (): Promise<AxiosResponse<GetAllFilesResponse>> => {
  return DAO().get(FILES_URL);
};

const upload = (payload: FormData): Promise<AxiosResponse> => {
  // uploading a new file in a product or in a section,
  // uploading a new version of a file
  return DAO().post(FILES_URL, payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const upsert = (
  payload: FileToUpsert
): Promise<AxiosResponse<FileToUpsertResponse>> => {
  // updating a name of a file
  // attaching an online file
  return DAO()
    .post(FILES_URL, payload)
    .then((response: AxiosResponse<FileToUpsertResponse>) => {
      return response;
    });
};

const remove = (fileId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${FILES_URL}/${fileId}`);
};

const linkToSection = (
  payload: FileToLinkToSection
): Promise<AxiosResponse<FileToLinkToSectionResponse>> => {
  return DAO().post(FILES_LINK_URL, payload);
};

const unlinkFromSection = (linkId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${FILES_LINK_URL}/${linkId}`);
};

const linkToProduct = (
  payload: FileToLinkToProduct
): Promise<AxiosResponse<FileToLinkToProductResponse>> => {
  return DAO().post(FILES_PRODUCT_LINK_URL, payload);
};

const unlinkFromProduct = (linkId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${FILES_PRODUCT_LINK_URL}/${linkId}`);
};

export {
  getAll,
  upload,
  upsert,
  remove,
  linkToSection,
  unlinkFromSection,
  linkToProduct,
  unlinkFromProduct
};
