<template>
  <div>
    <table class="simple-table mb-0">
      <thead>
        <tr>
          <th>Name</th>
          <th>Size</th>
          <th>Date Added</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <FilesListItem
          v-for="file in filesToDisplay"
          :key="file.id ? file.id : file.location"
          :file="file"
          :is-attachment-table="isAttachmentsTable"
          :hasExternalContentAuthoring="hasExternalContentAuthoring"
          :is-set-as-media-content="
            isFileSetAsMediaContent(file.location, file.id)
          "
          @launchRenamePopup="launchRenamePopup"
          @openUploadVersionModal="openUploadVersionModal"
          @showFileInLibrary="showFileInLibrary"
          @launchRemoveAttachmentDialog="launchRemoveAttachmentDialog"
          @setAsMediaContent="
            setAsMediaContent(file.location, file.id, section)
          "
          @unsetAsMediaContent="unsetAsMediaContent(section)"
          @previewAttachment="previewAttachment"
        />
      </tbody>
    </table>

    <teleport to="body">
      <UploadFileVersion
        v-if="fileForNewVersion"
        :file="fileForNewVersion"
        :productId="section.product_id"
      />
      <RemoveAttachment
        v-if="fileForRemoveLink"
        :file="fileForRemoveLink"
        :section="section"
        :shouldBeUnsetAsMediaContent="
          fileForRemoveLink &&
          isFileSetAsMediaContent(
            fileForRemoveLink.location,
            fileForRemoveLink.id
          )
        "
      />
      <RenameAttachment v-if="fileForRenaming" :file="fileForRenaming" />
    </teleport>
  </div>
</template>
<script lang="ts" setup>
import { computed, PropType, ref } from "vue";
import FilesListItem from "@/components/right-sidebars/content-sidebar/authoring-tool/FilesListItem.vue";
import UploadFileVersion from "@/components/popups/attachments/UploadFileVersion.vue";
import RemoveAttachment from "@/components/popups/attachments/RemoveAttachment.vue";
import RenameAttachment from "@/components/popups/attachments/RenameAttachment.vue";
import { LibraryFile } from "@/models/files.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import { SectionFile } from "@/models/product-section.model";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFilesStore } from "@/stores/files.store";
import { useRouter } from "vue-router";
import { useFileManager } from "@/composables/files-manager";
import { TOOLS } from "@/constants/template.constants";

interface UnavailableFile {
  name: string;
  location: string;
  is_external: boolean;
}

const router = useRouter();
const uiFilesStore = useUIFilesStore();
const filesStore = useFilesStore();

const { setAsMediaContent, unsetAsMediaContent } = useFileManager();

const props = defineProps({
  section: {
    type: Object as PropType<ContentStructureSection>,
    required: true
  },
  filesToDisplay: {
    required: true,
    type: Array as PropType<SectionFile[] | LibraryFile[] | UnavailableFile[]>
  },
  isAttachmentsTable: {
    required: false,
    type: Boolean,
    default: true
  }
});

const fileForNewVersion = ref<SectionFile | LibraryFile | undefined>(undefined);
const openUploadVersionModal = (file: SectionFile | LibraryFile): void => {
  fileForNewVersion.value = file;
  uiFilesStore.openAttachmentVersionModal();
};

const fileForRemoveLink = ref<SectionFile | undefined>(undefined);
const launchRemoveAttachmentDialog = (file: SectionFile): void => {
  fileForRemoveLink.value = file;
  uiFilesStore.openRemoveAttachmentModal();
};

const fileForRenaming = ref<LibraryFile | undefined>(undefined);
const getFileObject = (fileId: number): LibraryFile | undefined => {
  if (filesStore.files) {
    return filesStore.files.find((f: LibraryFile): boolean => f.id === fileId);
  }
  return undefined;
};
const launchRenamePopup = (file: SectionFile | LibraryFile): void => {
  fileForRenaming.value = getFileObject(file.id);
  if (fileForRenaming.value) {
    uiFilesStore.openRenameAttachmentModal();
  }
};

const showFileInLibrary = (file: SectionFile | LibraryFile): void => {
  const validURLFileName = file.name.split(".")[0];
  const query = {
    filters: `name--${validURLFileName}--default`,
    sidebar: `attachment-${file.id}`
  };

  router.push({
    path: "/attachments",
    query: query
  });
};

const emit = defineEmits(["previewAttachment"]);
const previewAttachment = (fileLocation: string): void => {
  emit("previewAttachment", fileLocation);
};

const hasExternalContentAuthoring = computed<boolean>(() => {
  return TOOLS.isExternalContentTool(
    props.section && props.section.type.tool_id
  );
});
const isFileSetAsMediaContent = (
  fileLocation: string,
  fileId: number
): boolean => {
  return (
    hasExternalContentAuthoring.value &&
    !!props.section.authored_content &&
    (fileId === props.section.authored_content.externalContent.attachmentId ||
      fileLocation === props.section.authored_content.externalContent)
  );
};
</script>
<style scoped lang="scss"></style>
