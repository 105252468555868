import { useCurriculumsStore } from "@/stores/curriculums.store";
import { useOrganizationStore } from "@/stores/organization.store";
import { useUsersStore } from "@/stores/users.store";
import { useProgrammesStore } from "@/stores/programmes.store";
import { useProductsStore } from "@/stores/products.store";
import { useFilesStore } from "@/stores/files.store";
import { getAllowedProducts } from "@/utils/filter-products-helpers";
import { ProductSummaryItem } from "@/models/product.model";
import { useRoute } from "vue-router";

export const useStoreManager = () => {
  const curriculumsStore = useCurriculumsStore();
  const orgStore = useOrganizationStore();
  const usersStore = useUsersStore();
  const programmesStore = useProgrammesStore();
  const productsStore = useProductsStore();
  const filesStore = useFilesStore();

  const route = useRoute();

  const populateProductDetails = async (): Promise<void> => {
    await productsStore.populateProductSummary();
    if (usersStore.currentUser && productsStore.productSummary) {
      let allowedProducts = getAllowedProducts(
        productsStore.productSummary,
        usersStore.currentUser.permissions
      );
      if (route.params.productId) {
        allowedProducts = allowedProducts.filter(
          (product: ProductSummaryItem) => {
            return product.id !== Number(route.params.productId);
          }
        );
        await productsStore.populateProductDetails(
          Number(route.params.productId)
        );
      }
      allowedProducts.forEach((product: ProductSummaryItem): void => {
        productsStore.populateProductDetails(product.id);
      });
    }
  };

  const populateOrganizationData = async (): Promise<void> => {
    await Promise.all([
      orgStore.populateOrganization(),
      usersStore.populateUsers()
    ]);
  };
  const populateGeneralData = (): void => {
    populateProductDetails();
    programmesStore.populateProgrammes();
    curriculumsStore.populateCurriculums();
    filesStore.populateFiles();
  };

  return {
    populateProductDetails,
    populateOrganizationData,
    populateGeneralData
  };
};
