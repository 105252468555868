const DOMParser = require("xmldom").DOMParser;
const parser = new DOMParser();
import {
  ConceptJson,
  ConceptMetadata,
  CurriculumCharacteristic,
  CurriculumJson,
  TopicJson
} from "@/models/curriculum.model";

export const parseXML = (currXML: string): Document => {
  return parser.parseFromString(currXML, "text/xml");
};

/**
 *
 * @param {String} currXML - A curriculum XML
 * @returns {Array} An array of concepts
 */

const extractConceptsFromXML = (currXML: string): ConceptJson[] => {
  const domXML: Document = parseXML(currXML);
  const elements: any = domXML.getElementsByTagName("Element");
  const conceptsJSON: ConceptJson[] = [];

  const curriculumCharacteristics: CurriculumCharacteristic[] =
    extractCurriculumCharacteristicsAsObject(
      extractCharacteristicsFromCurriculumAsHTMLCollection(currXML)
    );

  for (let i = 0; i < elements.length; i++) {
    const elm =
      elements[i].getElementsByTagName("Type")[0].childNodes[0].nodeValue;
    if (elm === "item" && elements[i].getElementsByTagName("Parent")[0]) {
      const node = elements[i];
      const strandGuid = strandGuidByTopic(
        elements,
        node.getElementsByTagName("Parent")[0].childNodes[0].nodeValue
      );

      const metadata: ConceptMetadata[] = extractConceptCharacteristics(
        curriculumCharacteristics,
        node.getElementsByTagName("Characteristic")
      );
      const parentGuid: string =
        node.getElementsByTagName("Parent")[0].childNodes[0].nodeValue;
      const conceptJson: ConceptJson = {
        name: node.getElementsByTagName("Name")[0].childNodes[0].nodeValue,
        guid: node.getElementsByTagName("GUID")[0].childNodes[0].nodeValue,
        strandGuid: strandGuid,
        metadata: metadata,
        parentGuid: parentGuid,
        topics: {
          topics: [
            {
              guid: parentGuid,
              displayOrder: Number(
                node.getElementsByTagName("DisplayOrder")[0].childNodes[0]
                  .nodeValue
              )
            }
          ]
        }
      };
      conceptsJSON.push(conceptJson);
    }
  }
  return conceptsJSON;
};

const strandGuidByTopic = (elements: any, topicGuid: string): string => {
  for (let i = 0; i < elements.length; i++) {
    const elm: string =
      elements[i].getElementsByTagName("Type")[0].childNodes[0].nodeValue;
    if (
      elm === "node" &&
      elements[i].getElementsByTagName("Parent")[0] &&
      elements[i].getElementsByTagName("GUID")[0].childNodes[0].nodeValue ===
        topicGuid
    ) {
      const node: any = elements[i];
      return node.getElementsByTagName("Parent")[0].childNodes[0].nodeValue;
    }
  }
  return "";
};

export const extractCharacteristicsFromCurriculumAsHTMLCollection = (
  curXML: string
): HTMLCollectionOf<Element> => {
  const domXML = parseXML(curXML);
  return domXML
    .getElementsByTagName("Characteristics")[0]
    .getElementsByTagName("Characteristic");
};

const extractCurriculumCharacteristicsAsObject = (
  characteristicNodes: HTMLCollectionOf<Element>
): CurriculumCharacteristic[] => {
  const characteristicsArray: CurriculumCharacteristic[] = [];
  for (let i = 0; i < characteristicNodes.length; i++) {
    if (
      characteristicNodes[i].getElementsByTagName("Level")[0] &&
      characteristicNodes[i].getElementsByTagName("Level")[0].childNodes[0]
        .nodeValue === "item"
    ) {
      const guid: string | null =
        characteristicNodes[i].getElementsByTagName("GUID")[0].childNodes[0]
          .nodeValue;
      characteristicsArray.push({
        guid: guid ?? "",
        name:
          characteristicNodes[i].getElementsByTagName("Name")[0].childNodes[0]
            .nodeValue ?? "",
        code:
          characteristicNodes[i].getElementsByTagName("Code")[0].childNodes[0]
            .nodeValue ?? ""
      });
    }
  }
  return characteristicsArray;
};

const extractConceptCharacteristics = (
  curriculumCharacteristics: CurriculumCharacteristic[],
  conceptNodeCharacteristics: any
): ConceptMetadata[] => {
  const conceptCharacteristics = [];

  for (let i = 0; i < conceptNodeCharacteristics.length; i++) {
    if (
      conceptNodeCharacteristics[i].getElementsByTagName("Characteristic")[0]
    ) {
      const guid =
        conceptNodeCharacteristics[i].getElementsByTagName("Characteristic")[0]
          .childNodes[0].nodeValue;
      const crObject = curriculumCharacteristics.find(
        (c: CurriculumCharacteristic) => {
          return c.guid === guid;
        }
      );
      const obj: ConceptMetadata = {
        guid: guid,
        code: crObject ? crObject.code : "",
        value: undefined,
        values: undefined
      };

      if (conceptNodeCharacteristics[i].getElementsByTagName("Value")[0]) {
        obj.value =
          conceptNodeCharacteristics[i].getElementsByTagName(
            "Value"
          )[0].childNodes[0].nodeValue;
      } else if (
        conceptNodeCharacteristics[i].getElementsByTagName("ValueCode")[0]
      ) {
        const valueCode =
          conceptNodeCharacteristics[i].getElementsByTagName("ValueCode");
        const valArr = [];
        for (let j = 0; j < valueCode.length; j++) {
          valArr.push(valueCode[j].childNodes[0].nodeValue);
        }
        obj.values = valArr;
      } else {
        obj.values = [];
      }
      conceptCharacteristics.push(obj);
    }
  }
  return conceptCharacteristics;
};

const extractTopicsFromXML = (currXML: string): TopicJson[] => {
  const domXML = parseXML(currXML);
  const elements = domXML.getElementsByTagName("Element");
  const topicsJSON: TopicJson[] = [];

  for (let i = 0; i < elements.length; i++) {
    const elm =
      elements[i].getElementsByTagName("Type")[0].childNodes[0].nodeValue;
    if (elm === "node" && elements[i].getElementsByTagName("Parent")[0]) {
      const node = elements[i];
      const topicJson: TopicJson = {
        name: node.getElementsByTagName("Name")[0].childNodes[0].nodeValue,
        guid: node.getElementsByTagName("GUID")[0].childNodes[0].nodeValue,
        strand: node.getElementsByTagName("Parent")[0].childNodes[0].nodeValue,
        displayOrder: Number(
          node.getElementsByTagName("DisplayOrder")[0].childNodes[0].nodeValue
        ),
        metadata: []
      };
      topicsJSON.push(topicJson);
    }
  }
  return topicsJSON;
};

const extractCurriculumGuid = (currXML: string): string | null => {
  const domXML = parseXML(currXML);
  return domXML
    .getElementsByTagName("Curricula")[0]
    .getElementsByTagName("Curriculum")[0]
    .getElementsByTagName("GUID")[0].childNodes[0].nodeValue;
};

export const getCurriculumJsonFromXml = (currXML: string): CurriculumJson => {
  const curriculumGuid = extractCurriculumGuid(currXML);
  return {
    curriculumGuid: curriculumGuid ? curriculumGuid : "",
    topicsJSON: extractTopicsFromXML(currXML),
    conceptsJSON: extractConceptsFromXML(currXML)
  };
};

export const getModuleNameFromCharacteristics = (
  curCharacteristics: HTMLCollectionOf<Element>,
  name: string,
  value: string
): string => {
  const code = Array.from(curCharacteristics).find(item => {
    return (
      item.getElementsByTagName("Code")[0]?.childNodes[0].nodeValue === name
    );
  });
  if (code) {
    const nameFromCharacteristics =
      code.getElementsByTagName("Name")[0]?.childNodes[0].nodeValue;
    const valuesFromCharacteristics = Array.from(
      code
        .getElementsByTagName("Choice")[0]
        .getElementsByTagName("Values")[0]
        .getElementsByTagName("Value")
    );
    const currentValue = valuesFromCharacteristics.find(item => {
      return (
        item.getElementsByTagName("Code")[0]?.childNodes[0].nodeValue === value
      );
    });
    if (currentValue) {
      const valueFromCurriculum =
        currentValue.getElementsByTagName("Name")[0].childNodes[0].nodeValue;
      return `${nameFromCharacteristics}: ${valueFromCurriculum}`;
    }
  }
  return "";
};
