import { DAO } from "@/api/DAO";
import { CONTENT_TEMPLATES_URL, WORKFLOWS_URL } from "@/api/URL-constants";
import {
  ContentTemplateToCreate,
  ContentTemplateToUpdate,
  ContentTemplateToUpdateWorkflows,
  CreateContentTemplateResponse
} from "@/models/api/content-templates.model";
import { AxiosResponse } from "axios";

const create = (
  payload: ContentTemplateToCreate
): Promise<AxiosResponse<CreateContentTemplateResponse>> => {
  return DAO().post(CONTENT_TEMPLATES_URL, payload);
};

const update = (payload: ContentTemplateToUpdate): Promise<AxiosResponse> => {
  return DAO().put(CONTENT_TEMPLATES_URL, payload);
};

const remove = (workflowId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${WORKFLOWS_URL}/${workflowId}`);
};

const updateWorkflow = (
  payload: ContentTemplateToUpdateWorkflows
): Promise<AxiosResponse> => {
  return DAO().put(WORKFLOWS_URL, payload);
};

export { create, update, remove, updateWorkflow };
