<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" :class="customClass">
    <path :d="path" :fill="fillColor" />
  </svg>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  path: {
    type: String,
    required: true
  },
  size: {
    type: [String, Number],
    default: 16,
    validator: (value: string | number) => {
      return !isNaN(Number(value));
    }
  },
  color: {
    type: String,
    default: ""
  },
  customClass: {
    required: false,
    type: String,
    default: ""
  },
  type: {
    type: String,
    default: "",
    required: false,
    validator: (value: string) => {
      return value
        ? [
            "is-dark",
            "is-light",
            "is-success",
            "is-danger",
            "is-warning",
            "is-white"
          ].includes(value)
        : true;
    }
  }
});

const fillColor = computed<string>(() => {
  if (props.color) {
    return props.color;
  }

  const colorMap: { [key: string]: string } = {
    "is-dark": "#1b1b1b",
    "is-white": "#ffffff",
    "is-light": "#bebebe",
    "is-warning": "#ffa500",
    "is-danger": "#e44e4e",
    "is-success": "#48ad93"
  };

  return props.type && colorMap[props.type]
    ? colorMap[props.type]
    : "currentColor";
});
</script>

<style lang="scss" scoped></style>
