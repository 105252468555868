import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { ProductItem } from "@/models/product.model";
import {
  ProductToAttachFile,
  SectionToAttachFile
} from "@/models/store/ui-state/attach-file.model";

export const useUIFilesStore = defineStore("uiFiles", () => {
  const fileForDisplay = ref<number | null>(null);
  const openFileSidebar = (fileId: number) => {
    fileForDisplay.value = fileId;
  };
  const closeFileSidebar = () => {
    fileForDisplay.value = null;
  };

  const shouldBeSetAsMediaContent = ref<boolean>(false);

  const isRenameAttachmentPopupOpen = ref<boolean>(false);
  const openRenameAttachmentModal = () => {
    isRenameAttachmentPopupOpen.value = true;
  };
  const closeRenameAttachmentModal = () => {
    isRenameAttachmentPopupOpen.value = false;
  };

  const isRemoveAttachmentPopupOpen = ref<boolean>(false);
  const openRemoveAttachmentModal = () => {
    isRemoveAttachmentPopupOpen.value = true;
  };
  const closeRemoveAttachmentModal = () => {
    isRemoveAttachmentPopupOpen.value = false;
  };

  const isFileVersionModalOpen = ref<boolean>(false);
  const openAttachmentVersionModal = () => {
    isFileVersionModalOpen.value = true;
  };
  const closeAttachmentVersionModal = () => {
    isFileVersionModalOpen.value = false;
  };

  const fileAttachmentType = ref<number | null>(null);

  const sectionToAttachFile = ref<ContentStructureSection | null>(null);
  const productToAttachFile = ref<ProductItem | null>(null);
  const isAttachFileModalOpen = computed<boolean>(() => {
    return !!sectionToAttachFile.value || !!productToAttachFile.value;
  });

  const openAttachFileModal = (
    params: ProductToAttachFile | SectionToAttachFile
  ) => {
    if ((params as SectionToAttachFile).section) {
      sectionToAttachFile.value = (params as SectionToAttachFile).section;
    }
    if ((params as SectionToAttachFile).shouldBeSetAsMediaContent) {
      shouldBeSetAsMediaContent.value =
        (params as SectionToAttachFile).shouldBeSetAsMediaContent ?? false;
    }
    if (
      (params as ProductToAttachFile).product &&
      (params as ProductToAttachFile).attachmentType
    ) {
      productToAttachFile.value = (params as ProductToAttachFile).product;
      fileAttachmentType.value = (params as ProductToAttachFile).attachmentType;
    }
  };
  const closeAttachFileModal = () => {
    sectionToAttachFile.value = null;
    productToAttachFile.value = null;
    fileAttachmentType.value = null;
    shouldBeSetAsMediaContent.value = false;
  };

  return {
    fileForDisplay,
    openFileSidebar,
    closeFileSidebar,
    isRenameAttachmentPopupOpen,
    openRenameAttachmentModal,
    closeRenameAttachmentModal,
    isRemoveAttachmentPopupOpen,
    openRemoveAttachmentModal,
    closeRemoveAttachmentModal,
    isFileVersionModalOpen,
    openAttachmentVersionModal,
    closeAttachmentVersionModal,
    fileAttachmentType,
    sectionToAttachFile,
    productToAttachFile,
    isAttachFileModalOpen,
    shouldBeSetAsMediaContent,
    openAttachFileModal,
    closeAttachFileModal
  };
});
