import { ParsedAuthoredContent } from "@/models/x-api.model";
import { METADATA } from "@/constants/template.constants";

export const convertToJsonOrNull = (
  str: string | undefined | null | object
): ParsedAuthoredContent => {
  if (!str) {
    return null;
  }
  try {
    if (!!str && typeof str === "object") {
      return <Record<string, string> | null>str;
    }
    const json = JSON.parse(str);
    if (!!str && typeof json === "object") {
      return json;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};
export const sanitizeFriendlyURL = (friendlyURL: string): string => {
  return friendlyURL
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(METADATA.FRIENDLY_URL_REPLACE_PATTERN, "");
};
export default {
  convertToJsonOrNull,
  sanitizeFriendlyURL
};
