export const PRODUCT = {
  TYPES: [
    "Textbook",
    "Workbook",
    "Manual/Guide",
    "Digital-Teacher",
    "Digital-Student"
  ],
  DELIVERIES: ["Print", "Print & Ebook", "Online"],
  STATUSES: ["Draft", "Active", "Archive"],
  PERMISSIONS: {
    editContentStructure: 2,
    approveContentObjects: 3,
    authorContentObjects: 4,
    editPermissions: 5,
    viewPermissions: 8
  },
  FEATURES: {
    assetManagementTier1: {
      id: 3,
      index: 0,
      title: "Asset Management Tier 1"
    },
    assetManagementTier2: {
      id: 4,
      index: 1,
      title: "Asset Management Tier 2"
    },
    workflowManagement: {
      id: 5,
      index: 2,
      title: "Workflow Management"
    },
    contentAuthoringTier1: {
      id: 6,
      index: 3,
      title: "Content Authoring Tier 1"
    },
    contentAuthoringTier2: {
      id: 7,
      index: 4,
      title: "Content Authoring Tier 2"
    },
    curriculumAuthoring: {
      id: 2,
      index: 5,
      title: "Curriculum Authoring"
    }
  },
  ERRORS: {
    notUpdated: "Product not updated",
    notDeleted: "Product not deleted",
    notCreated: "Product not created",
    loading: "Error loading product",
    notFound: "Product not found",
    namespaceInvalid: "Please enter valid URL",
    namespaceRequired: "Product Namespace is required",
    imageFileType: "Please select png, jpg, bmp or gif file type"
  }
};
