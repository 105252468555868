<template>
  <div>
    <strong class="margin-r-half">{{ item.metadata_definition.name }}:</strong>
    <input v-model="metadataValue" type="text" @change="updateMetadataText" />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, PropType, ref, watch } from "vue";
import { MetadataInstance } from "@/models/product-section.model";
import { useMetadataStore } from "@/stores/metadata.store";

const metadataStore = useMetadataStore();

const props = defineProps({
  item: {
    required: true,
    type: Object as PropType<MetadataInstance>
  },
  productId: {
    required: true,
    type: Number
  }
});

const metadataValue = ref<string>("");
onMounted(() => {
  metadataValue.value = props.item.authored_text;
});
watch(
  () => props.item,
  newVal => {
    if (newVal) {
      metadataValue.value = newVal.authored_text;
    }
  }
);
const updateMetadataText = async (): Promise<void> => {
  const payload = {
    metadataInstance: {
      authored_text: metadataValue.value,
      id: props.item.id
    },
    productId: props.productId
  };
  await metadataStore.updateMetadataInstance(payload);
};
</script>

<style lang="scss" scoped></style>
