import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useUIProductsStore = defineStore("uiProducts", () => {
  const newProductImage = ref<File | null>(null);
  const setNewProductImage = (params: File | null) => {
    newProductImage.value = params;
  };

  const productToAddImage = ref<number | null>(null);
  const isProductImageModalVisible = computed<boolean>(() => {
    return !!productToAddImage.value;
  });
  const openProductImageModal = (productId: number) => {
    productToAddImage.value = productId;
  };
  const closeProductImageModal = () => {
    productToAddImage.value = null;
  };

  return {
    newProductImage,
    setNewProductImage,
    productToAddImage,
    isProductImageModalVisible,
    openProductImageModal,
    closeProductImageModal
  };
});
