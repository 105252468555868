import { UserPermission } from "@/models/user.model";
import { CurrentUser } from "@/models/store/user-state.model";
import { GetAllResponseUser } from "@/models/api/users.model";

export const getOrganizationUsers = (allUsers: GetAllResponseUser[]) => {
  return allUsers.map((user: GetAllResponseUser) => {
    return {
      id: user.id,
      firstName: user.firstname,
      lastName: user.lastname,
      displayName: `${user.firstname} ${user.lastname}`,
      isEnabled: user.is_enabled,
      allocatedPermissions: user.allocated_permissions,
      username: user.username,
      type: user.type
    };
  });
};

export const getCurrentUser = (
  currentUserId: number,
  currentUserType: number,
  currentUserPermissions: UserPermission[],
  allUsers: GetAllResponseUser[]
): CurrentUser | null => {
  const curUser = allUsers.find((user: GetAllResponseUser) => {
    return user.id === currentUserId;
  });
  if (curUser) {
    return {
      id: currentUserId,
      permissions: currentUserPermissions,
      displayName: `${curUser.firstname} ${curUser.lastname}`,
      type: currentUserType,
      username: curUser.username,
      lastName: curUser.lastname,
      firstName: curUser.firstname,
      is_enabled: curUser.is_enabled
    };
  }
  return null;
};

export const checkPermission = (
  allUserPermissions: UserPermission[],
  permissionId: number
): boolean => {
  return allUserPermissions.some((permission: UserPermission) => {
    return permission.id === permissionId;
  });
};
